import React, { useState } from 'react';
import Compose from './Compose';
import {
  DisclaimerContext,
  ErrorContext,
  LanguageContext,
  LoadingContext,
  PageScopeContext,
} from '../../globals/Context';
import RootRouter from '../routing/rootRouter';
import { LanguageCodes, getLanguageCodeFromHostname, getLanguageStrings } from '../../util/language';
import LoadingSpinner from '../LoadingSpinner';
import ChatBubble211 from '../211Chat/ChatBubble211';

// Active component count in a loading state
// Has to be out here due to rendering off-by-one type errors
let loadingCount = 0;

const RootContexts = () => {
  const [languageCode, setCurrentLanguage] = useState(getLanguageCodeFromHostname());
  const [otherLanguageCode, setOtherLanguageCode] = useState(languageCode === LanguageCodes.En
    ? LanguageCodes.Fr
    : LanguageCodes.En);
  const [languageStrings, setCurrentLanguageStrings] = useState(getLanguageStrings(languageCode));
  const [pageScope, setPageScope] = useState('s');
  const [isError, setIsError] = useState(false);
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);

  // Loading state
  // The API for this uses a boolean for simplicity, but internally
  // we track how many times the app has signalled it is loading
  // and won't drop the spinner until they have all finished
  const [isLoading, setIsLoading] = useState(false);
  const setIsLoadingWrapped = (bool) => {
    if (bool) {
      loadingCount += 1;
    } else {
      loadingCount -= 1;
    }
    setIsLoading(loadingCount !== 0);
  };

  const setLanguageStateWrapped = (newCode) => {
    setCurrentLanguage(newCode);
    setCurrentLanguageStrings(getLanguageStrings(newCode));
    setOtherLanguageCode(newCode === LanguageCodes.En ? LanguageCodes.Fr : LanguageCodes.En);
  };

  // If we hit an error state, disable loading so we can redirect to error page
  if (isLoading && isError) {
    setIsLoading(false);
  }

  const providers = [
    [LanguageContext.Provider, {
      value: {
        languageStrings,
        languageCode,
        otherLanguageCode,
        setCurrentLanguage: setLanguageStateWrapped,
      },
    }],
    [ErrorContext.Provider, { value: { isError, setIsError } }],
    [LoadingContext.Provider, { value: { isLoading, setIsLoading: setIsLoadingWrapped } }],
    [PageScopeContext.Provider, { value: { pageScope, setPageScope } }],
    [DisclaimerContext.Provider, { value: { disclaimerAccepted, setDisclaimerAccepted } }],
  ];

  return (
    <LoadingSpinner loading={isLoading}>
      <Compose components={providers}>
        <RootRouter />
        <ChatBubble211 />
      </Compose>
    </LoadingSpinner>
  );
};

export default RootContexts;
