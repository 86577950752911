import { fetchAndFormat, makeUrlObject } from '../../util/util';

/**
 * Get all programs, complex programs, and simple pages for the search page
 * @param {string} languageCode - The language code as defined in language.js
 * @returns {Promise<any[]>} - Array of all content pages.
 */
export async function getAllProgramsForSearch(language) {
  const fields = 'id,title,type,path,field_search_keywords,field_program_description,field_about_short_description_,field_add_an_image_large_800_x_4,field_featured,field_program_image';
  const params = new URLSearchParams({
    include: 'field_search_keywords',
    'fields[node--program]': fields,
    'fields[node--simple_page]': fields,
    'fields[node--complex_page]': fields,
    'filter[field_searchable]': 1,
    'filter[status]': 1,
  });

  const urlProgram = makeUrlObject('jsonapi/node/program/', language);
  urlProgram.search = params;
  const urlComplexProgram = makeUrlObject('jsonapi/node/complex_page/', language);
  urlComplexProgram.search = params;
  const urlSimplePage = makeUrlObject('jsonapi/node/simple_page/', language);
  urlSimplePage.search = params;

  const programResponse = fetchAndFormat(urlProgram);
  const complexProgramResponse = fetchAndFormat(urlComplexProgram);
  const simplePageResponse = fetchAndFormat(urlSimplePage);

  const [a, b, c] = await Promise.all(
    [programResponse, complexProgramResponse, simplePageResponse],
  );

  return [...a, ...b, ...c];
}

// Functions that use ID to get data.
/**
 * Given the ID of a category, fetches all associated programs, complex programs,
 * and simple pages and their pagescope.
 * @param {string} languageCode - The language code as defined in language.js
 * @param {any} categoryId - The id property of the programs you want categories for.
 * @returns {Promise<any[]>} - The array of programs in your category
 */
export async function getProgramsByCategoryID(language, categoryId) {
  const params = new URLSearchParams({
    include: 'field_pagescope',
    'filter[field_program_categories.id]': categoryId,
    'filter[field_searchable]': 1,
    'filter[status]': 1,
  });

  const urlProgram = makeUrlObject('jsonapi/node/program/', language);
  urlProgram.search = params;
  const urlComplexProgram = makeUrlObject('jsonapi/node/complex_page/', language);
  urlComplexProgram.search = params;
  const urlSimplePage = makeUrlObject('jsonapi/node/simple_page/', language);
  urlSimplePage.search = params;

  const programResponse = fetchAndFormat(urlProgram);
  const complexProgramResponse = fetchAndFormat(urlComplexProgram);
  const simplePageResponse = fetchAndFormat(urlSimplePage);

  const [a, b, c] = await Promise.all(
    [programResponse, complexProgramResponse, simplePageResponse],
  );

  return [...a, ...b, ...c];
}

export async function getProgramPathById(id, language, contentType) {
  const params = new URLSearchParams({
    [`fields[node--${contentType}]`]: 'path',
  });

  const url = makeUrlObject(`jsonapi/node/${contentType}/${id}`, language);
  url.search = params;

  const payload = await fetchAndFormat(url);
  return payload.path.alias;
}

// We need these functions below because you cant perform lookups in drupal by path
// Fuck me right. Potential solution https://www.drupal.org/project/decoupled_router
// But too long to get done right now.

// Functions that uses path to get data.
export async function getContentUUIDByPath(pathAlias, language, recordType) {
  const params = new URLSearchParams({
    [`fields[node--${recordType}]`]: 'id,path',
  });

  const url = makeUrlObject(`jsonapi/node/${recordType}/`, language);
  url.search = params;

  const payload = await fetchAndFormat(url);
  return payload.filter((i) => i.path?.alias === pathAlias)[0].id;
}

export async function getProgramUUIDByPath(pathAlias, language, pageType) {
  return getContentUUIDByPath(pathAlias, language, pageType);
}

export async function getContentByPath(uuid, language, recordType) {
  const params = new URLSearchParams({
    'filter[status]': 1,
  });

  const url = makeUrlObject(`jsonapi/node/${recordType}/${uuid}`, language);
  url.search = params;

  const payload = await fetchAndFormat(url, false);
  return payload.data;
}

export async function getProgramByPath(pathAlias, language, pageType) {
  const uuid = await getProgramUUIDByPath(pathAlias, language, pageType);
  return getContentByPath(uuid, language, pageType);
}
