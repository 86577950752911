export const LANDING_PATH = "landing";
export const CATEGORY_PATH = "category";
export const PROGRAM_PATH = "program";
export const SEARCH_PATH = "search";
export const COMPLEX_PATH = "complex_page";
export const SIMPLE_PATH = "simple_page";
export const PAGE_PATH = "page";

export const CALCULATOR_PATH = "calculator";
export const SCREENING_PATH = "screening";
export const CONTACT_US_PATH = "contact-us";
export const HOME_FIRST_PATH = "home-first-registration";
export const CHB_REGISTRATION_PATH = "chb-registration";

export const CANT_FIND_PROGRAMS_PATH_EN = "cant-find-program-or-service-you-are-looking";
export const CANT_FIND_PROGRAMS_PATH_FR = "vous-ne-trouvez-pas-le-programme-ou-le-service-que-vous-recherchez";
export const CANT_FIND_PROGRAM_CAT_ID = 'ec11dc31-97bd-424d-9ea4-e5cdd5520e70';

// TODO: Update the ID values from DEV after content freeze
export const LANDING_PAGE_SENIORS_ID = "bd28a96b-0a0f-40b7-aa3c-96730e7a221d";
export const LANDING_PAGE_DISABILITY_ID = "0ce0a828-8555-4680-a8ed-af6e8b253861";
export const LANDING_PAGE_HOUSING_ID = "043f766c-ae76-4bbc-bc2b-50e70bee62b1";
export const LANDING_PAGE_FINANCES_ID = "b1e79d2a-1a0f-4997-b9e3-909aa511b06e";
export const LANDING_PAGE_ABUSE_NEGLECT_ID = "76f44b07-dc80-4c78-8ea1-a2319cfb2109";
export const LANDING_PAGE_FAMILIES_YOUTH_ID = "d2d13ed8-472c-4628-afa6-3f707bea64f8";
export const LANDING_PAGE_HEALTH_ID = "a5ed53d4-815f-4284-902d-2998946c30b4";

export const PAGE_SCOPE_SENIORS = "seniors";
export const PAGE_SCOPE_DISABILITY = "disability";
export const PAGE_SCOPE_HOUSING = "housing";
export const PAGE_SCOPE_FINANCES = "finances";
export const PAGE_SCOPE_ABUSE = "abuse-and-neglect";
export const PAGE_SCOPE_FAMILIES_AND_YOUTH = "families-and-youth";
export const PAGE_SCOPE_YOUR_HEALTH= "your-health";

export const SENIORS_VANITY_URL_EN = "/seniors";
export const SENIORS_VANITY_URL_FR = "/personnes-agees";
export const DISABILITY_VANITY_URL_EN = "/disability";
export const DISABILITY_VANITY_URL_FR = "/handicap";
export const FINANCES_VANITY_URL_EN = "/finances";
export const FINANCES_VANITY_URL_FR = "/les-finances";
export const ABUSE_NEGLECT_VANITY_URL_EN = "/abuse-neglect";
export const ABUSE_NEGLECT_VANITY_URL_FR = "/abus-negligence";
export const FAMILIES_YOUTH_VANITY_URL_EN = "/families-youth";
export const FAMILIES_YOUTH_VANITY_URL_FR = "/familles-jeunes";
export const HEALTH_VANITY_URL_EN = "/health";
export const HEALTH_VANITY_URL_FR = "/sante";
export const CALCULATOR_VANITY_URL_EN = "/Financialhelpcalculator";
export const CALCULATOR_VANITY_URL_FR = "/calculatrice-aide-financiere";

export const VANITY_LTC_SURVEY_EN = "/ltcsurvey"
export const VANITY_LTC_SURVEY_FR = "/ltcquestionnaire"
export const VANITY_LTC_FOSTER_EN = "/foster"
export const VANITY_LTC_FOSTER_FR = "/familledaccueil"
export const VANITY_LTC_COMPLEX_EN = "/complexneeds"
export const VANITY_LTC_COMPLEX_FR = "/besoinscomplexes"

export const CHILD_YOUTH_NEXT_CHAPTER_EN = "/children-youth"
export const CHILD_YOUTH_NEXT_CHAPTER_FR = "/enfants-jeunes"

export const FOSTERING_PAGE_ID = "80da553e-0df5-4c2e-be84-6b0f61504f27";

export const SEARCH_QUERY_STRING="s";

export const SORT_ORDER = {
    EQUAL: 0,
    LESS_THAN: -1,
    GREATHER_THAN: 1
};