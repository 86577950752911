import React from 'react';

// Taken from https://gitlab.com/verosource/myhealth/myhealth-web/-/blob/main/packages/myhealth-shared/src/common/components/Compose.js
// We're lacking the ability to import this package due to the way the infra is set up, so it has to be copied in.
// For anyone in the first world: we have to ssh in and git pull using our own developer creds, making it very hard to use our internal packages

/**
 * A Higher Order Component that combines components passed to it. The first entry will be the
 * outermost component, with subsequent components being mounted inside.
 *
 * The children inside of this HOC will be mounted last as the innermost component.
 * @param components - An array of arrays. Each inner array contains the component to be mounted and
 *  the props being passed to that component
 */
const Compose = ({ components, children }) => components.reduce(
  (accumulator, [component, props]) => React.createElement(component, props, accumulator),
  children,
);

export default Compose;
