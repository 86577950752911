import React from 'react';

const LoadingSpinner = ({loading = true, children}) => {
    return (
        <div className='loading-wrapper'>
            <div className={loading ? '' : 'd-none'} id="loader"/>
            <div className={loading ? 'd-none' : ''}>
                {children}
            </div>
        </div>
    )
}

export default LoadingSpinner;