import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { getProgramPathById } from '../../api/v2/programs';
import LoadingSpinner from '../LoadingSpinner';

const PageRedirect = ({ id, language, contentType }) => {
  const [redirectPath, setRedirectPath] = useState(null);

  useEffect(() => {
    const fetchProgramPath = async () => {
      try {
        const path = await getProgramPathById(id, language, contentType);
        setRedirectPath(path);
      } catch (error) {
        console.error('Error fetching program path: ', error);
      }
    };

    fetchProgramPath();
  }, [id, contentType]);

  if (!redirectPath) {
    return <LoadingSpinner />;
  }

  return <Redirect to={redirectPath} />;
};

export default PageRedirect;
