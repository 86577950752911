import strings from '../globals/strings';
import * as settings from './settings';

export const LANG_EN = 'en';
export const LANG_FR = 'fr';

export const LANGUAGES = [
  { key: LANG_EN, name: strings.LANGUAGE_NAMES.ENGLISH },
  { key: LANG_FR, name: strings.LANGUAGE_NAMES.FRENCH },
];

export const LanguageCodes = {
  En: 'en',
  Fr: 'fr',
};

export const RouterFormattedLanguageList = Object.values(LanguageCodes).join('|');

export function getLanguageStrings(lang) {
  if (lang === LANG_EN) {
    return strings.en;
  } else if (lang === LANG_FR) {
    return strings.fr;
  }
  return null;
}

export function getTargetLanguage(lang) {
  if (lang === LANG_EN) {
    return LANG_FR;
  }
  return LANG_EN;
}

export function stripTrailingSlashes(url) {
  return url.replace(/^\/|\/$/, '');
}

export function stripUrlLanguage(url) {
  const loc2 = url.replace(/^(fr|en)/, '');
  return loc2;
}

export function createLanguagePath(url, language, stub) {
  const tempUrl = `/${language}${stripUrlLanguage(stripTrailingSlashes(url))}`;
  // Now strip whatever is after the final slash and replace with new stub
  return tempUrl.substr(0, tempUrl.lastIndexOf('/') + 1) + stub;
}

export const getLanguageCodeFromHostname = () => (
  window.location.hostname === settings.FrenchDomain ? LanguageCodes.Fr : LanguageCodes.En);
