import { FinancialCalculatorLink } from '../util/settings'

const LANGUAGE_NAMES = {
    ENGLISH: 'English',
    FRENCH: 'Français',
}

const mainPhoneNumber = "1-855-550-0552";

const fr = {
    LANGUAGE_BUTTON_TITLE: LANGUAGE_NAMES.ENGLISH,
    BETA_NOTICE: "Dites-nous ce que vous pensez de ce nouvel outil et comment nous pouvons l’améliorer.",
    BETA_NOTICE_2: "Envoyez-nous vos observations.",
    LANDING_TITLE: "Trouvez des programmes et des services pour les aînés au Nouveau-Brunswick.",
    LANDING_TITLE_1: "Trouver des programmes et des services",
    LANDING_TITLE_2: "qui aident les Néo-Brunswickois",
    SITE_DESCRIPTION_BLURB: "Nous aidons les gens du Nouveau-Brunswick à consulter et mieux connaître les programmes et services gouvernementaux et communautaires.",
    WEBSITE_TITLE: "Soutiens sociaux NB | Gouvernement Nouveau Brunswick",
    HEADER_TITLE: "Soutiens sociaux NB",
    HEADER_TITLE_P1: "Soutiens",
    HEADER_TITLE_P2: "sociaux NB",
    HEADER_PHONE_LINE: "Besoin d’aide? ",
    HEADER_ABOUT_LINK_TITLE: "À propos",
    LANDING_HEADER_ABOUT_US_LINK_TITLE: "À propos",
    HEADER_ABOUT_LINK: "/fr/page/propos",
    HEADER_HOME_LINK_TITLE: "Accueil",
    HEADER_DISCLAIMER_LINK_TITLE: "Avertissement",
    HEADER_PRIVACY_LINK_TITLE: "Confidentialité",
    HEADER_MENU_TITLE: "Menu",
    HEADER_CLOSE_BUTTON_TITLE: "Fermer",
    MAIN_CATEGORY_HEADING: "J’aimerais en apprendre d'avantage sur:",
    MAIN_CATEGORY_ITEM_LINK: "En savoir plus",
    PAGE_NOT_FOUND: "Page non trouve",
    FOOTER_COPYRIGHT_NOTICE: "Tous les droits de contenu " + '\u00A9' + " Gouvernement du Nouveau-Brunswick. Tous droits réservés.",
    NEED_HELP: "Vous avez besoin de savoir les soutiens qui existent dans votre collectivité? Appelez le service 211.",
    LANDING_NEED_HELP: "POUR SIGNALER UN CAS DE VIOLENCE OU DE NÉGLIGENCE OU SI VOUS DEVEZ PRÉSENTER UNE DEMANDE DE SERVICES DE DÉVELOPPEMENT SOCIAL, COMPOSEZ LE ",
    LANDING_NEED_HELP_2: "1-833-733-7835.",
    NEED_HELP_2: "Si vous devez vous inscrire à des services gouvernementaux, composez le ",
    NEED_HELP_3: "Besoin d’aide?",
    SEARCH: "Recherche",
    SEARCH_PLACEHOLDER: "Recherche",
    FEEDBACK: "Réactions",
    HEADER_TELE_CARE: "Télé-Soins 811",
    HEADER_TELE_CARE_LINK: "https://www2.gnb.ca/content/gnb/fr/ministeres/sante/Tele-Soins.html",
    HEADER_LINKS: "FR More",
    PAGINATION_PREVIOUS: "Dernier",
    PAGINATION_NEXT: "Prochaine",
    CATEGORY_FILTER_DESC: "Ce que j'ai le plus besoin, c'est",
    CATEGORY_FILTER_SELECT_PROMPT: "choisissez un sujet...",
    CATEGORY_FILTER_VIEW_ALL: "Voyez toute les options",
    CATEGORY_FILTER_VIEW_ALL_2: "",
    CATEGORY_FILTER_FOUND: "Vous avez trouvé",
    SEARCH_FILTER_FOUND: "Votre recherche a donné",
    CATEGORY_FILTER_FOUND_2: "programmes ou services :",
    CATEGORY_FILTER_FOUND_2_SINGULAR: "programme ou service :",
    SEARCH_RESULTS: "Résultats de la recherche",
    PROGRAM_DATA_TABLE_OF_CONTENTS_TITLE: "Dans cette page",
    PROGRAM_DATA_TABLE_OF_CONTENTS_ABOUT: " À propos",
    PROGRAM_DATA_TABLE_OF_CONTENTS_QUALIFICATION: "Qui est admissible?",
    PROGRAM_DATA_TABLE_OF_CONTENTS_HOW_TO_APPLY: "Comment puis-je m’y inscrire?",
    PROGRAM_DATA_TABLE_OF_CONTENTS_HOW_TO_APPLY_STEPS: "Pas",
    PROGRAM_DATA_TABLE_OF_CONTENTS_WHAT_DO_I_NEED: "Que dois-je fournir?",
    PROGRAM_DATA_TABLE_OF_CONTENTS_DOCUMENTS: "Documents",
    PROGRAM_DATA_TABLE_OF_CONTENTS_FAQS:"FAQ",
    PROGRAM_DATA_TABLE_OF_CONTENTS_CONTACTS: "Contactes",
    PROGRAM_DATA_HEADING_ABOUT: "À propos",
    PROGRAM_DATA_HEADING_QUALIFICATION: "Qui est admissible?",
    PROGRAM_DATA_HEADING_HOW_TO_APPLY: "Comment puis-je m’y inscrire?",
    PROGRAM_DATA_HEADING_WHAT_DO_I_NEED: "Que dois-je fournir?",
    PROGRAM_DOCUMENTS_DOWNLOAD_LINK: "Télécharger PDF",
    PROGRAM_DOCUMENTS_SECTION_TITLE: "Plus d’information",
    PROGRAM_CONTACTS_HEADER: "Contact",
    NO_DESCRIPTION: "Aucune description",
    NO_IMAGE_DESCRIPTION: "Aucune description d'image",
    NO_TITLE: "Aucune titre",
    RELATED_PROGRAMS_HEADER: "Vous ne trouvez pas le programme ou le service que vous recherchez?",
    RELATED_PROGRAMS_DESCRIPTION: "OBTENEZ DE L’AIDE EN TOUT TEMPS",
    TELECAREDESCRIPTION:"Télé-Soins est une ligne gratuite et confidentielle afin d’obtenir des conseils et des renseignements sur la santé. Composez le 811 pour avoir accès à des infirmières immatriculées bilingues, 24 heures sur 24, 7 jours sur 7, 365 jours par année.",
    TELECARETITLE:"811 Télé-Soins",
    TELECAREBUTTON:"Appeler Télé-Soins",
    TELECARELINK:"/fr/page/tele-soins-811",
    PAGINATION_FIRST:"Premier",
    PAGINATION_LAST:"Dernier",
    PAGINATION_NEXT:"Suivant",
    PAGINATION_PREV:"Précédent",
    LASTUPDATED:"Dernière mise à jour :",
    LASTUPDATEDLANGUAGE : "fr-CA",
    PRINT_THIS_PAGE: "Imprimer cette page",
    SEARCHPLACEHOLDER:"Recherche",
    LOAD_MORE:"Voyez plus comme ca",
    SEARCH_LABEL: "Je cherche...",
    TOP_BUTTON: "Haut",
    CHAT_BUTTON_TEXT: "Obtenir de l’aide",
    CHAT_BUTTON_OPEN: "Ouvrir le clavardage",
    CHAT_BUTTON_HIDE: "Cacher le clavardage",
    DEFAULT_LINK_TEXT: "Appuyez Ici",
    WEBSITE: "Site web",
    PROGRAM_DATA_HEADING_FAQ : "FAQ",
    PRIVACY_LINK: "https://www2.gnb.ca/content/gnb/fr/admin/declaration_de_confidentialite.html",
    DISCLAIMER_LINK: "https://www2.gnb.ca/content/gnb/fr/admin/decharge.html" ,
    SURVEY_LINK: "https://ca.surveygizmo.com/s3/50078698/Navigator-UX-Feedback?sglocale=fr-ca",
    COVID_HEADER_INFORMATION_1:"Vous voulez plus de renseignements au sujet de la COVID-19 au Nouveau-Brunswick? ",
    COVID_HEADER_INFORMATION_2:"Veuillez cliquer ici",
    COVID_INFO_LINK:"https://www2.gnb.ca/content/gnb/fr/corporate/promo/covid-19.html",
    CALCULATOR_PAGE_TITLE: "Calculatrice d’aide financière",
    DEV_NOTICE: "Bienvenue au site de development pour soutiens sociaux NB. Pour voire le site de production ",
    DEV_NOTICE_2: "cliquer ici!",
    PROD_LINK: "https://soutienssociauxnb.ca/fr/",
    CALCULATOR_RESULTS_HEADER: "Résultats ",
    CALCULATOR_RESULTS_TEXT1: "Nous estimons que vous devriez payer ",
    CALCULATOR_RESULTS_TEXT2: " par mois pour les aidants à domicile.",
    CALCULATOR_RESULTS_TEXT3: "Ceci est ce que paierez pour les aidants qui vous aideront à demeurer indépendant dans votre propre maison. Chacun a besoin de quelque chose de différent en matière d’aide à domicile. Les types d’aide décrits ", 
    CALCULATOR_RESULTS_TEXT7:"ci-dessus",
    CALCULATOR_RESULTS_TEXT8:" donnent des exemples des types d’aide que vous recevriez chaque mois.",
    CALCULATOR_RESULTS_TEXT4: "Prochaines étapes ",
    CALCULATOR_RESULTS_TEXT5: "Ou",
    CALCULATOR_RESULTS_LIST_ELEMENT1: "Si vous croyez avoir besoin d’aide financière, veuillez lire la page ",
    CALCULATOR_LONG_TERM_CARE: "Soins de longue durée",
    CALCULATOR_LONG_TERM_CARE_LINK: "/fr/program/programme-de-soins-de-longue-duree/",
    CALCULATOR_RESULTS_LIST_ELEMENT2: " pour avoir plus de détails sur le soutien à domicile. Vous pouvez aussi appeler le Ministère du Développement social au 1-833-733-7835. ",
    CALCULATOR_RESULTS_LIST_ELEMENT3: "Si vous ne croyez pas avoir besoin d’aide financière, veuillez visiter la page ",
    CALCULTOR_HOME_SUPPORT: "Soutien à domicile ",
    CALCULATOR_HOME_SUPPORT_LINK: "/fr/program/services-de-soutien-domicile",
    CALCULATOR_RESULTS_LIST_ELEMENT_4: " pour consulter les options qui sont à votre disposition pour trouver un soutien à domicile.",
    CALCULATOR_RESULTS_TEXT6: "N’oubliez pas que ce n’est qu’une estimation. Vous pouvez toujours nous téléphoner pour parler en détail de votre situation. ",
    CALCULATOR_SURVEY_TEXT:"Dites-nous ce que vous pensez de notre nouvelle calculatrice d’aide financière. Remplissez ce court sondage. ",
    CALCULATOR_RESULTS_SURVEY_LINK:"https://ca.surveygizmo.com/s3/50083857/Client-Contribution-Survey&sglocale=fr-ca",
    CALCULATOR_SURVEY_TEXT2:"LIEN",
    CALCULATOR_DISCLAIMER:"Avertissement ",
    CALCULATOR_DISCLAIMER_2:"Les calculs ne sont que des estimations basées sur l’information fournie. Il peut y avoir des exceptions dans certaines situations particulières. Le Ministère du Développement social confirmera le montant exact de la subvention que vous recevrez pendant le processus de demande.",
    CALCULATOR_DISCLAIMER_3:"La seule raison pour laquelle nous vous demandons votre revenu annuel total est afin de savoir combien vous allez payer. Ne t’inquiété pas! D’aucune façon ces information sont stocker ou conservés.  Une fois que vous quittez cette page, ces informations sont automatiquement effacées. ",
    CALCULATOR_OVERVIEW_HEADER:"Aperçu",
    CALCULATOR_OVERVIEW_1:"Bienvenue à la calculatrice d’aide financière. Elle vous aidera à estimer le montant que vous auriez à payer pour l’aide à domicile pour les aînés et les personnes ayant un handicap. Elle utilise votre revenu annuel net pour calculer le coût de votre soutien à domicile.",
    CALCULATOR_OVERVIEW_2:"Exemples de services communs",
    CALCULATOR_OVERVIEW_3: "Aide avec les activités de tous les jours (s’habiller, faire sa toilette, prendre son bain, préparer des repas et faire de petites activités dans la maison) ",
    CALCULATOR_OVERVIEW_4: "Services de repas à domicile : livraison de repas nutritifs et santé, congelés ou frais",
    CALCULATOR_OVERVIEW_5: "Soins des pieds : soins des pieds ou des ongles par un professionnel approuvé",
    CALCULATOR_OVERVIEW_6: "Technologie : accès à deux types de technologie; système personnel d’intervention d’urgence (comme Lifeline) ou système de surveillance à distance (comme CareLink)",
    CALCULATOR_OVERVIEW_7: "Placement de relève dans un foyer de soins spéciaux : période de relève planifiée dont peut profiter une famille ou une personne qui fournit des soins",
    CALCULATOR_OVERVIEW_8: "Soins de relève pour le soutien à domicile : soins de relève ou d’autres moyens de supervision à domicile pour la sécurité d’un aîné. Aide à la famille ou à une personne qui offre des services de soins à un aîné à son domicile ",
    CALCULATOR_OVERVIEW_9: "Centres de jour pour adultes : activités importantes de groupe à l’extérieur du domicile pour personnes avec démence et troubles cognitifs",
    CALCULATOR_OVERVIEW_10: "Services de soutien à l’emploi et à la formation : service de soutien à l’emploi pour les adultes de 19 à 64 ans ayant un handicap",
    CALCULATOR_IS_THIS_FOR_ME_HEADER: "Est-ce que la calculatrice est pour moi?",
    CALCULATOR_IS_THIS_FOR_ME_1:"Pour que l’estimation de la calculatrice soit plus exacte, vous devez :",
    CALCULATOR_IS_THIS_FOR_ME_2:"être un adulte (19 ans et plus);",
    CALCULATOR_IS_THIS_FOR_ME_3:"habiter à la maison seul(e) ou avec votre conjoint(e). (La calculatrice n’est pas pour les personnes qui ont un(e) conjoint(e) et qui habitent en foyer de soins spéciaux, en foyer de soins ou en résidence communautaire. Si c’est votre cas, veuillez téléphoner à le Ministère du Développement social au 1-833-733-7835.)",
    CALCULATOR_IS_THIS_FOR_ME_4:"Sinon, nous travaillons fort pour ajouter des options à la calculatrice très bientôt. En attendant, veuillez appeler le Ministère du Développement social au 1-833-733-7835.",
    CALCULATOR_IS_THIS_FOR_ME_5:"Il est à noter que le montant de la contribution changera avec le temps. ",
    CALCULATOR_INPUT_1_HEADER:"Revenu annuel net",
    CALCULATOR_INPUT_1_TEXT_1:"Il s’agit du revenu total (imposable et non imposable) de toutes les sources et de toute la famille.",
    CALCULATOR_INPUT_1_TEXT_2:"Sources les plus communes de revenus : Régime de pensions du Canada (RPC), Sécurité de la vieillesse (SV), Supplément de revenu garanti (SRG), prestations d’assurance-emploi (AE), aide au revenu, impôt sur le revenu, régimes de pension privés, revenus d’emploi, intérêts d’investissements, etc. ",
    CALCULATOR_INPUT_1_TEXT_3:"Nous ne considérons pas les biens comme les biens immobiliers, les épargnes et les investissements dans votre revenu annuel net. ",
    CALCULATOR_INPUT_1_LABEL:"Demandeur",
    CALCULATOR_INPUT_2_LABEL:"Conjoint",
    CALCULATOR_DROPDOWN_HEADER:"Sélectionnez votre type de famille ",
    CALCULATOR_DROPDOWN_DESCRIPTION_1:"Êtes-vous célibataire ou en couple (marié(e) ou conjoint(e) de fait)? Combien de personnes à charge avez-vous? Les célibataires comprennent les veufs et les divorcés. Une personne à charge est une personne qui habite chez vous et qui est:",
    CALCULATOR_DROPDOWN_DESCRIPTION_2:"un enfant de 19 ans ou moins;",
    CALCULATOR_DROPDOWN_DESCRIPTION_3:"un enfant de 25 ans ou moins qui étudie à temps plein;",
    CALCULATOR_DROPDOWN_INPUT_1:"Célibataire",
    CALCULATOR_DROPDOWN_INPUT_2:"Célibataire avec une personne à charge",
    CALCULATOR_DROPDOWN_INPUT_3:"Célibataire avec plus d’une personne à charge",
    CALCULATOR_DROPDOWN_INPUT_4:"En couple",
    CALCULATOR_DROPDOWN_INPUT_5:"En couple avec une personne à charge",
    CALCULATOR_DROPDOWN_INPUT_6:"En couple avec plus d’une personne à charge ",
    CALCULATOR_NUMBER_OF_DEPENDENTS:"Nombre de personnes à charge",
    CALCULATOR_TABLE_HEADER:"De combien d’aide ai-je besoin?",
    CALCULATOR_TABLE_DESC_1: "Les besoins d’aide à domicile sont différents pour chaque personne, selon la situation. L’aide nécessaire dépend :",
    CALCULATOR_TABLE_DESC_2: "de vos besoins en soins;",
    CALCULATOR_TABLE_DESC_3: "du soutien déjà offert par votre famille et vos amis; ",
    CALCULATOR_TABLE_DESC_4: "de vos préférences personnelles. ",
    CALCULATOR_TABLE_DESC_5: "En général, il y a trois types d’aide à domicile. ",
    CALCULATOR_TABLE_DESC_6: "Un peu d’aide (de 0 à 10 heures par semaine) ",
    CALCULATOR_TABLE_DESC_7: "Aide modérée (de 11 à 20 heures par semaine) ",
    CALCULATOR_TABLE_DESC_8: "Beaucoup d’aide (plus de 21 heures par semaine)",
    CALCULATOR_TABLE_DESC_9: "Pour l’instant, vous pourriez avoir besoin d’aide pour gérer vos médicaments ou vos soins personnels, comme prendre un bain (entrer dans le bain et en sortir). Vous n’avez pas besoin d’aide tous les jours. Vous pourriez bénéficier de services de soutien communautaire et d’appareils d'assistance (comme une cane ou une marchette).",
    CALCULATOR_TABLE_DESC_10: "Vous pourriez avoir besoin d’aide avec la plupart de vos activités quotidiennes (prendre un bain, vous habiller, vous déplacer dans la maison et préparer des repas). Vous pourriez avoir besoin d’aide chaque jour. Vous pourriez bénéficier de services de soutien communautaire, d’appareils d’assistance  et de soutien aux soignants. ",
    CALCULATOR_TABLE_DESC_11: "Vous avez besoin d’aide avec la plupart des activités quotidiennes (prendre un bain, vous habiller, préparer des repas, vous lever du lit, faire votre toilette). Vous pourriez avoir besoin d’aide une ou deux fois par jour. Vous pourriez bénéficier de services de soutien communautaire, d’appareils d’assistance  et de soins de relève. ",
    CALCULATOR_BUTTON_1: "Calculer",
    CALCULATOR_BUTTON_2: "Réinitialiser",
    CALCULATOR_INPUT_VALIDATION_ERROR_1:"Le type de famille choisi ne devrait compter aucune personne à charge.  Choisissez 0 pour le nombre de personnes à charge.",
    CALCULATOR_INPUT_VALIDATION_ERROR_2:"Le type de famille choisi indique qu’il n’y a qu’une seule personne à charge à la maison.  Choisissez 1 pour le nombre de personnes à charge.",
    CALCULATOR_INPUT_VALIDATION_ERROR_3:"Le type de famille choisi indique qu’il y a plus d’une seule personne à charge à la maison.  Choisissez un chiffre plus grand que 1 pour le nombre de personnes à charge.",
    CALCULATOR_INPUT_SPOUSE_WHOLE_NUM_ERROR: "Le revenu du conjoint ne doit contenir que des chiffres.",
    CALCULATOR_INPUT_SPOUSE_NEGATIVE_NUM_ERROR: "Le revenu du conjoint ne peut pas être un nombre négatif.",
    CALCULATOR_INPUT_APPLICANT_WHOLE_NUM_ERROR: "Le revenu du demandeur ne doit contenir que des chiffres. ",
    CALCULATOR_INPUT_APPLICANT_NEGATIVE_NUM_ERROR: "Le revenu du demandeur ne peut pas être un nombre négatif.",
    CALCULATOR_INPUT_APPLICANT_UNDEFINED_NUM_ERROR: "Veuillez indiquer le revenu annuel du demandeur.",
    CALCULATOR_NO_APPLICANT_INCOME_ERROR:"Veuillez indiquer le revenu annuel du demandeur.",
    CALCULATOR_INVALID_APPLICANT_INCOME_ERROR:"Le chiffre que vous avez indiqué n’est pas valide pour le revenu du demandeur.",
    CALCULATOR_INVALID_SPOUSE_INCOME_ERROR:"Le chiffre que vous avez indiqué n’est pas valide pour le revenu du conjoint.",
    CALCULATOR_NO_FAMILY_TYPE_SELECTED_ERROR:"Veuillez choisir une option dans le menu déroulant du type de famille.",
    CALCULATOR_NO_DEPENDENTS_SELECTED_ERROR:"Veuillez faire une sélection dans la liste déroulante nombre de personnes à charge.",
    CALCULATOR_SPOUSE_INPUT_NOT_USED:"Désolé, vous avez entré un revenu de conjoint, mais vous avez indiqué que vous êtes célibataire.",
    CALCULATOR_SUMMARY_STATEMENT_BUTTON_LINK: FinancialCalculatorLink + "?language=fr_CA",
    CALCULATOR_NET_INCOME_PLACEHOLDER: "i.e. 30000",
    CALCULATOR_FAMILY_TYPE_CHOOSE: "Choisir...",
    FINANCIAL_HELP_BANNER_1:"Vous pensez avoir besoin d’aide financière? Essayez la ",
    FINANCIAL_HELP_BANNER_2:"calculatrice d’aide financière!",
    REGISTRATION_HEADER_RESIDENTIAL_INFORMATION: "Renseignements sur le domicile",
    REGISTRATION_FIELD_FIRST_NAME: " Prénom",
    REGISTRATION_FIELD_LAST_NAME: " Nom de famille",
    REGISTRATION_ERROR_NO_FIRST_NAME: "Il faut un prénom.",
    REGISTRATION_ERROR_NO_LAST_NAME: "Il faut un nom de famille.",
    REGISTRATION_ERROR_INVALID_NAME: "Ce champ ne peut compter que des lettres.",
    REGISTRATION_SECTION_SECOND_CONTACT: "Consentement à la communication avec un parent ou un proche",
    REGISTRATION_FIELD_PROVIDE_CONSENT: "Pouvons-nous communiquer avec le membre de la famille/l’ami que vous invitez à l’évaluation?",
    REGISTRATION_FIELD_SECOND_CONTACT_EXPLAINATION: " Vous pouvez demander à un membre de la famille ou à un ami d'être présent pendant l'évaluation. Vous pouvez donc inclure leurs coordonnées ci-dessous aussi.",
    REGISTRATION_FIELD_ERROR_PROVIDE_CONSENT: "FR You must provide consent for a second contact.",
    REGISTRATION_FIELD_SECOND_FIRST_NAME: " Prénom du membre de la famille/de l’ami",
    REGISTRATION_FIELD_SECOND_LAST_NAME: " Nom du membre de la famille/de l’ami",    
    REGISTRATION_FIELD_SECOND_TELEPHONE: " Numéro de téléphone du membre de la famille/de l’ami",
    REGISTRATION_FIELD_APPLICANT_AGE: "  Je suis âgé de 65 ans ou plus? ",
    REGISTRATION_FIELD_AGE_OVER_65_CONSENT_NOT_GIVEN: "Vous devez être âgé de 65 ans ou plus pour vous inscrire à ce programme.",
    REGISTRATION_FIELD_TELEPHONE_FR_SUPERSCRIPT_1: " N",
    REGISTRATION_FIELD_TELEPHONE_FR_SUPERSCRIPT_2: "o",
    REGISTRATION_FIELD_TELEPHONE: " de téléphone",
    REGISTRATION_ERROR_NO_TELEPHONE: "Il faut un numéro de téléphone.",
    REGISTRATION_ERROR_TELEPHONE_LENGTH: "Le numéro de téléphone doit compter 10 chiffres.",
    REGISTRATION_FIELD_LANGUAGE_TYPE: " Quelle langue préférez-vous?",
    REGISTRATION_LANGUAGE_TYPE_CHOOSE: "Choisir...",
    REGISTRATION_LANGUAGE_TYPE_ENGLISH: "Anglais ",
    REGISTRATION_LANGUAGE_TYPE_FRENCH: "Français",
    REGISTRATION_ERROR_NO_LANGUAGE_SELECTED: "Vous devez sélectionner une langue.",
    REGISTRATION_ERROR_INVALID_CITY_NAME: "Le nom de la ville ne peut compter que des lettres, des traits d’union, des points et des apostrophes.",
    REGISTRATION_ERROR_NO_CITY_NAME: "Il faut une ville.",
    REGISTRATION_FIELD_CITY: " Ville ou village de l’aîné",
    REGISTRATION_HEADER_ADDRESS: "FR Residential Address",
    REGISTRATION_FIELD_POSTAL_CODE: " Code postal",
    REGISTRATION_ERROR_NO_POSTAL_CODE: "Il faut un code postal.",
    REGISTRATION_ERROR_POSTAL_CODE_PATTERN: "Le code postal doit respecter le format A1A1A1.",
    REGISTRATION_HEADER_DISCLAIMER: "Avis de non-responsabilité",
    REGISTRATION_DISCLAIMER_TEXT: "Veuillez lire attentivement l’avis de non-responsabilité qui suit et cocher la case avant d’envoyer vos renseignements.",
    REGISTRATION_DISCLAIMER_TEXT_2:"Je comprends que mes renseignements personnels serviront uniquement à m’inscrire à l’évaluation de la santé, du mieux-être et de la sécurité à domicile des aînés.",
    REGISTRATION_DISCLAIMER_TEXT_3:"Je consens par conséquent à ce que les agents de Bayshore communiquent avec moi, ou avec la personne que j’ai désignée, par téléphone pour discuter de mon admissibilité au Programme d’évaluation de la santé, du mieux-être et de la sécurité à domicile des aînés.",
    REGISTRATION_DISCLAIMER_TEXT_4:"Je comprends que mes renseignements personnels sont recueillis et qu’ils seront utilisés sous le régime de la Loi sur le droit à l’information et la protection de la vie privée, chap. R-10.6, et de la Loi sur les services à la famille, chap. F-2.2.",
    REGISTRATION_DISCLAIMER_TEXT_5:"Je comprends également que le Ministère du Développement social a pris les précautions nécessaires pour protéger mes renseignements personnels contre toute utilisation ou communication non autorisées, conformément à la loi sur la protection de la vie privée susmentionnée.",
    REGISTRATION_DISCLAIMER_CONSENT_NOT_GIVEN: "Pour continuer, vous devez accepter l’avis de non-responsabilité.",
    REGISTRATION_SUBMIT_BUTTON: "Envoyer",
    HEADER_HOME_FIRST_REGISTRATION_HEADER: "Inscription au programme D’abord chez soi  ",
    REGISTRATION_OVERVIEW: "Vous pouvez maintenant vous inscrire en ligne à votre évaluation D’abord chez soi. Remplissez le formulaire ci-dessous. Cette visite est gratuite et vous permettra de connaître les programmes et les services communautaires qui peuvent vous aider dans vos activités de tous les jours. Voici quelques-uns des sujets qui seront discutés : ",
    REGISTRATION_OVERVIEW_BULLET1:"Comment éviter les chutes ",
    REGISTRATION_OVERVIEW_BULLET2:"Comment participer à la vie communautaire ",
    REGISTRATION_OVERVIEW_BULLET3:"L’activité physique ",
    REGISTRATION_OVERVIEW_BULLET4:"Le bien-être",
    REGISTRATION_OVERVIEW_2:"On communiquera ensuite avec vous sous peu pour prévoir la date et l’heure de votre évaluation.",
    REGISTRATION_OVERVIEW_HEADER:"Aperçu",
    REGISTRATION_RESET_BUTTON: "Réinitialiser",
    REGISTRATION_ERROR_NO_STREET_ADDRESS: "Ce renseignement est obligatoire.",
    REGISTRATION_FORM_ADDRESS_LINE:"Ligne d’adresse 1",
    REGISTRATION_FORM_ADDRESS_LINE_2:"Ligne d’adresse 2",
    REGISTRATION_FORM_MODAL_SUCCESS_TITLE:"Vous avez réussi!",
    REGISTRATION_FORM_MODAL_SUCCESS_MESSAGE:"Votre information a été envoyée, et on communiquera avec vous sous peu pour fixer la date et l’heure de votre évaluation.",
    REGISTRATION_FORM_MODAL_FAILURE_TITLE:"Ah non!",
    REGISTRATION_FORM_MODAL_FAILURE_MESSAGE_1:"Votre information n’a pas été envoyée.  Assurez-vous d’avoir bien rempli tous les champs du formulaire et essayez de nouveau.",
    REGISTRATION_FORM_MODAL_FAILURE_MESSAGE_2:"Vous pouvez aussi vous inscrire par téléphone en composant le 1-855-550-0552.",
    REGISTRATION_FORM_MODAL_LINK_MESSAGE:"Lien ramenant aux D’abord chez soi",
    HOME_FIRST_PROGRAM_ROUTING: "/program/dabord-chez-soi",

    SCREENING_PAGE_HEADER: "Formulaire L’Allocation Canada - Nouveau-Brunswick pour le logement",
    SCREENING_PARA_HEADER: "À propos",
    SCREENING_PARA_ONE: "L’Allocation Canada–Nouveau-Brunswick pour le logement est une prestation offerte à court terme (jusqu’à trois ans). Elle aide les personnes du Nouveau-Brunswick qui ont un emploi à temps partiel ou peu payé et qui ont du mal à payer leur loyer.",

    SCREENING_FORM_HEADING: "Qui est admissible?",
    SCREENING_FORM_PARAGRAPH_1: "L’ACL-NB n’est pas pour tout le monde. Elle fait partie des programmes qui vous aident à avoir une plus grande indépendance et à répondre à vos besoins essentiels. Elle ne nuit pas aux autres prestations et soutiens de Développement social.",
    SCREENING_FORM_PARAGRAPH_2: "Si vous n’êtes pas admissible à l’ACL-NB, vous pourriez avoir droit à d’autres soutiens, par exemple :",

    SCREENING_OTHER_SUPPORTS_1_LINK: "/fr/program/programme-daide-sociale",
    SCREENING_OTHER_SUPPORTS_2_LINK: "/fr/simple_page/avantages-financiers-pour-les-familles-avec-enfants",
    SCREENING_OTHER_SUPPORTS_3_LINK: "/fr/category/de-laide-pour-les-parents",
    SCREENING_OTHER_SUPPORTS_4_LINK: "/fr/program/programmes-de-logement-public-et-de-supplement-de-loyer",
    SCREENING_OTHER_SUPPORTS_5_LINK: "/fr/disability",
    SCREENING_OTHER_SUPPORTS_6_LINK: "/fr/navigator",

    SCREENING_OTHER_SUPPORTS_1: "Aide sociale",
    SCREENING_OTHER_SUPPORTS_2: "Avantages financiers pour les familles avec enfants",
    SCREENING_OTHER_SUPPORTS_3: "Aide pour les parents",
    SCREENING_OTHER_SUPPORTS_4: "Aide pour le loyer et le logement",
    SCREENING_OTHER_SUPPORTS_5: "Aide aux personnes ayant un handicap",
    SCREENING_OTHER_SUPPORTS_6: "Du soutien pour les personnes âgées",

    SCREENING_FIRST_TABLE_FAMILIES_HEADER: "Famille",
    SCREENING_FIRST_TABLE_INDIVIDUALS_HEADER: "Individus",

    SCREENING_FAMILIES_1: "Vous avez la garde d’au moins un enfant de moins de 19 ans.",
    SCREENING_FAMILIES_2: "OU",
    SCREENING_FAMILIES_3: "Vous avez au moins un enfant de 19 ans ou plus ayant un handicap comme personne à charge.",
    SCREENING_FAMILIES_4: "Vous louez l’endroit où vous habitez.",
    SCREENING_FAMILIES_5: "Vous ne recevez pas de subvention au logement de Ministère du Développement social.",
    SCREENING_FAMILIES_6: "Vous êtes résident du Nouveau-Brunswick.",
    SCREENING_FAMILIES_7: "Vous avez un emploi ou vous êtes temporairement en congé de maternité ou de paternité.",
    SCREENING_FAMILIES_8: "Vous avez rempli votre déclaration de revenus pour l’année précédente.",
    SCREENING_FAMILIES_9: "Vous êtes la seule personne de votre ménage à demander cette prestation.",
    SCREENING_FAMILIES_10: "Le revenu combiné de tous les adultes de plus de 19 ans de votre ménage se situe entre 12 500 $ et 50 000 $ (avant impôt).",

    SCREENING_INDIVIDUALS_1: "Vous avez moins de 65ans, et",
    SCREENING_INDIVIDUALS_2: "Vous vivez seul(e).",
    SCREENING_INDIVIDUALS_3: "Vous louez l’endroit où vous habitez.",
    SCREENING_INDIVIDUALS_4: "Vous ne recevez pas de subvention au logement de Ministère du Développement social.",
    SCREENING_INDIVIDUALS_5: "Vous êtes résident du Nouveau-Brunswick.",
    SCREENING_INDIVIDUALS_6: "Vous avez un emploi.",
    SCREENING_INDIVIDUALS_7: "Vous avez rempli votre déclaration de revenus pour l’année précédente.",
    SCREENING_INDIVIDUALS_8: "Vous êtes la seule personne vivant dans votre ménage et demandant cette prestation.",
    SCREENING_INDIVIDUALS_9: "Votre revenu d’emploi se situe entre 12 500 $ et 50 000 $ (avant impôt).",

    SCREENING_BENEFIT_NOT_YOU_HEADER: "Vous n’êtes pas admissible à cette allocation si vous répondez « oui » à l’une des conditions suivantes:",
    SCREENING_BENEFIT_NOT_YOU_1: "Vous êtes propriétaire de votre maison.",
    SCREENING_BENEFIT_NOT_YOU_2: "Vous recevez une autre allocation de logement (logement subventionné, supplément au loyer).",
    SCREENING_BENEFIT_NOT_YOU_3: "Le revenu combiné de tous les adultes de plus de 19 ans de votre ménage est de 50 001 $ ou plus (avant impôt).",
    SCREENING_BENEFIT_NOT_YOU_4: "Une autre personne de votre ménage demande ou reçoit cette allocation.",
    

    SCREENING_YES_HEADING: "Vous pourriez recevoir l’ACL-NB si vous répondez « oui » à TOUTES les conditions qui s’appliquent aux personnes OU aux familles, ci-dessous :",
    SCREENING_YES_1: "Vous louez l’endroit où vous habitez.",
    SCREENING_YES_2: "Vous ne recevez pas de subvention au logement de Ministère du Développement social.",
    SCREENING_YES_3: "Vous êtes résident du Nouveau-Brunswick.",
    SCREENING_YES_4: "Vous avez la garde principale d’un ou plusieurs enfants mineurs de moins de 19 ans ou de personne(s) à charge handicapée(s) âgée(s) de 19 ans ou plus.",
    SCREENING_YES_5_1: "Vous travaillez, et le revenu d’emploi du ménage varie entre 12 500 $ et 50 000 $ avant impôt. Si vous travaillez 20 heures et plus par semaine au salaire minimum, vous pourriez être admissible.",
    SCREENING_YES_5_3: "Remarque:",
    SCREENING_YES_5_4: " Il s’agit du revenu d’emploi avant impôt combiné de tous les adultes de plus de 19 ans vivant à ce domicile.",
    SCREENING_YES_7_1: "Votre revenu est déclaré à l’Agence du Revenu du Canada (ARC).",
    SCREENING_YES_8: "Vous êtes la seule personne de votre ménage à demander cette prestation.",

    SCREENING_NO_HEADING: "Vous n’êtes pas admissible à cette allocation si vous répondez « oui » à l’une des conditions suivantes:",
    SCREENING_NO_1: "Vous êtes propriétaire de votre maison.",
    SCREENING_NO_2: "Vous recevez une autre allocation de logement (logement subventionné, supplément au loyer).",
    SCREENING_NO_3: "Vous n’avez pas d’enfant ni de personne à charge handicapée âgée de 19 ans ou plus.",
    SCREENING_NO_4: "Votre ménage affiche un revenu annuel avant impôt de 50 001 $ ou plus.",
    SCREENING_NO_5: "Une autre personne de votre ménage demande ou reçoit cette allocation.",

    SCREENING_WHAT_DO_I_NEED_HEADER: "Que dois-je fournir?",
    SCREENING_WHAT_DO_I_NEED_SUBHEADER: "Vous aurez besoin des renseignements suivants pour remplir votre demande :",

    SCREENING_WDIN_CHECK_1: "Information sur la location",
    SCREENING_WDIN_CHECK_2: "Preuve de revenus",
    SCREENING_WDIN_CHECK_3: "Renseignements sur le paiement",

    SCREENING_WDIN_CHECK_1_DESC_1: "Vous devrez fournir ",
    SCREENING_WDIN_CHECK_1_DESC_2: "l’un",
    SCREENING_WDIN_CHECK_1_DESC_3: " des documents suivants pour confirmer votre location :",

    SCREENING_WDIN_CHECK_1_LI_1: "Facture de services publics : le nom et l’adresse doivent correspondre aux renseignements que nous avons dans nos dossiers avec votre demande originale",
    SCREENING_WDIN_CHECK_1_LI_2: "Reçu où figurent votre nom, votre adresse, le montant du loyer, la date et le nom du propriétaire",
    SCREENING_WDIN_CHECK_1_LI_3: "Copie d’un avis d’augmentation de loyer récent",
    SCREENING_WDIN_CHECK_1_LI_4: "Copie d’un bail ou d’un contrat de location",
    SCREENING_WDIN_CHECK_1_LI_5: "Formulaire de confirmation de résidence",
    SCREENING_WDIN_CHECK_1_LI_5_LINK: "https://drupal.socialsupportsnb.ca/sites/default/files/2023-01/residenceconfirmation-confirmationderesidence.pdf",


    SCREENING_WDIN_CHECK_2_LI_1: "Avis de cotisation de l’Agence du revenu du Canada (pour tous les membres du ménage de 19 ans et plus si le revenu d’une personne est de plus de 12 500 $) :",
    SCREENING_WDIN_CHECK_2_LI_2: "Renseignements sur le revenu d’emploi de tous les membres du ménage de 19 ans et plus (si le revenu d’une personne est de plus de 12 500 $ ) :",

    SCREENING_WDIN_CHECK_2_LI_1_1: "Si vous n’avez pas ces renseignements, vous pouvez ouvrir une session dans ",
    SCREENING_WDIN_CHECK_2_LI_1_2: "Mon dossier",
    SCREENING_WDIN_CHECK_2_LI_1_2_LINK: "https://www.canada.ca/fr/agence-revenu/services/services-electroniques/services-ouverture-session-arc.html",
    SCREENING_WDIN_CHECK_2_LI_1_3: " ARC ou appelez le 1-800-267-6999.",

    SCREENING_WDIN_CHECK_2_LI_2_1: "Si vous n’avez pas soumis votre déclaration de revenus, allez à un ",
    SCREENING_WDIN_CHECK_2_LI_2_2: "comptoir d'impôts gratuit",
    SCREENING_WDIN_CHECK_2_LI_2_2_LINK: "https://www.canada.ca/fr/agence-revenu/services/impot/particuliers/programme-communautaire-benevoles-matiere-impot/besoin-coup-main-remplir-votre-declaration-revenus.html",
    SCREENING_WDIN_CHECK_2_LI_2_3: " près de chez vous.",

    SCREENING_WDIN_CHECK_2_LI_2_AND: "ET",
    
    SCREENING_WDIN_CHECK_2_LI_2_FK_1: "Talons de chèque (les quatre derniers ou consécutifs) ; (si vous avez occupé plusieurs emplois, veuillez fournir les talons de paie pour chacun d’eux)",
    SCREENING_WDIN_CHECK_2_LI_2_FK_2: "OU",
    SCREENING_WDIN_CHECK_2_LI_2_FK_3: "Lettre récente d’un employeur (dans les 30 derniers jours) qui comprend :",

    SCREENING_WDIN_CHECK_2_LI_2_LIST_1: "la date de votre embauche ;",
    SCREENING_WDIN_CHECK_2_LI_2_LIST_2: "votre revenu annuel brut ou votre taux de rémunération (y compris la rémunération des heures supplémentaires ou les primes) ;",
    SCREENING_WDIN_CHECK_2_LI_2_LIST_3: "l’horaire de travail hebdomadaire ;",
    SCREENING_WDIN_CHECK_2_LI_2_LIST_4: "les coordonnées de la personne-ressource de l’employeur ; et",
    SCREENING_WDIN_CHECK_2_LI_2_LIST_5: "la signature de l’employeur ;",

    SCREENING_WDIN_MATPAT_SUBHEADER: "Congé de maternité ou de paternité",
    SCREENING_WDIN_MATPAT_DESC: "Si vous êtes en congé de maternité ou de paternité, vous aurez également besoin d’une lettre de votre employeur confirmant votre date de retour au travail.",

    SCREENING_WDIN_CHECK_3_1: "L’ACL-NB sera versée aux demandeurs acceptés par dépôt direct.",
    SCREENING_WDIN_CHECK_3_2_1: "Veuillez remplir et signer le ",
    SCREENING_WDIN_CHECK_3_2_2: "Formulaire de dépôt direct",
    SCREENING_WDIN_CHECK_3_2_LINK: "https://drupal.socialsupportsnb.ca/sites/default/files/2021-06/CHB%20SD%20Payee%20_%20Direct%20Deposit%20Form_Final-r2.pdf",
    SCREENING_WDIN_CHECK_3_2_3: " de l’Allocation Canada–Nouveau-Brunswick pour le logement.",

    SCREENING_WDIN_NOTE: "Remarque : Nous sommes conscients que votre situation (familiale, d’emploi, financière) peut changer en cours d’année. Si c’est le cas, votre prestation pourrait être ajustée l’année suivante. Votre prestation est calculée une fois par an.",

    SCREENING_HDIA_HEADER: "Comment puis-je m’y inscrire?",
    SCREENING_HDIA_DESC: "Vous pouvez présenter une demande pour l’ACL-NB en ligne ou par la poste.",

    SCREENING_HDIA_LI_1: "Pour demander l’ACL-NB, vous devrez fournir tous les renseignements demandés dans la section « Que dois-je fournir? », ci-dessous.",
    SCREENING_HDIA_LI_2_1: "Cliquez sur le bouton « Procéder » en bas de la page pour accéder au portail de SSNB. Cette nouvelle fonctionnalité sécurisée vous permet de faire une demande en ligne, de téléverser des documents en toute sécurité, d’enregistrer vos données et d’accéder à vos informations personnelles.",
    
    SCREENING_HDIA_LI_2_2_1: "Si vous préférez, vous pouvez ",
    SCREENING_HDIA_LI_2_2_2: "télécharger et imprimer",
    SCREENING_HDIA_LI_2_2_2_LINK: "https://drupal.socialsupportsnb.ca/sites/default/files/2023-01/CHB%20Application%20-%20FR.pdf",
    SCREENING_HDIA_LI_2_2_3: " le formulaire de demande et le remplir à la main. Envoyez le formulaire rempli et les copies des documents requis par courrier ou apportez-les en personne à l’adresse suivante :",
    SCREENING_HDIA_LI_2_3: "Prévoyez un délai de traitement supplémentaire si vous envoyez votre demande par la poste.",
    SCREENING_HDIA_LI_2_4_1: "Pour recevoir l’exemplaire papier de la trousse de demande par la poste, ou si vous avez des questions sur le processus de demande, vous pouvez envoyer un courriel à ",
    SCREENING_HDIA_LI_2_4_2: "infochb-acl@gnb.ca",
    SCREENING_HDIA_LI_2_4_2_LINK: "mailto:infochb-acl@gnb.ca",
    SCREENING_HDIA_LI_2_4_3: " ou téléphoner au 211.",
    SCREENING_HDIA_LI_3: "Instructions concernant le portail en ligne de SSNB ",

    SCREENING_HDIA_ADDR_1: "Ministère du Développement social",
    SCREENING_HDIA_ADDR_2: "Objet : Allocation Canada–Nouveau-Brunswick pour le logement",
    SCREENING_HDIA_ADDR_3: "551, rue King",
    SCREENING_HDIA_ADDR_4: "Édifice Sartain MacDonald",
    SCREENING_HDIA_ADDR_5: "C.P. 6000, Fredericton (N.-B.)",
    SCREENING_HDIA_ADDR_6: "E3B 5H1",

    SCREENING_HDIA_LI_3_S1: "Étape 1 : Sélectionnez la demande en ligne d’Allocation Canada–Nouveau-Brunswick pour le logement.",
    SCREENING_HDIA_LI_3_S2: "Étape 2 : Cliquez sur « S’inscrire » (ou « Se connecter » si vous avez déjà un compte). On vous demandera de fournir votre prénom, votre nom de famille et votre adresse courriel.",
    SCREENING_HDIA_LI_3_S3: "Étape 3 : Un courriel de confirmation contenant un nom d’utilisateur vous sera envoyé à l’adresse fournie à l’étape 2.",
    SCREENING_HDIA_LI_3_S4: "Étape 4 : Cliquez sur le lien dans le courriel de confirmation pour définir votre mot de passe et procéder à votre demande.",
    SCREENING_HDIA_LI_3_S5: "Étape 5 : Remplissez et soumettez votre demande en ligne.",
    SCREENING_HDIA_LI_3_SI: "Une fois que vous aurez soumis votre demande avec succès, vous recevrez un courriel de confirmation contenant un numéro de référence. Veuillez conserver l’information pour vos dossiers.",
    
    SCREENING_NH_HEADER: "Avez-vous besoin d’aide pour remplir le formulaire de demande en ligne?",
    SCREENING_NH_SUBHEADER_1: "Guide de l’utilisateur : ",
    SCREENING_NH_SUBHEADER_2: "Formulaire de demande en ligne pour l’ACL-NB",
    SCREENING_NH_SUBHEADER_2_LINK: "https://www2.gnb.ca/content/dam/gnb/Departments/sd-ds/pdf/acl-nb-guide-de-l-utilisateur.pdf",
    SCREENING_NH_DESC: "Remplissez ce formulaire si c’est la première fois que vous demandez l’ACL-NB.",
    SCREENING_NH_EMAIL_BOLD: "Courriel : ",
    SCREENING_NH_EMAIL: "soutienssociauxnb@gnb.ca",
    SCREENING_NH_EMAIL_LINK: "mailto:soutienssociauxnb@gnb.ca",
    
    SCREENING_SUBMISSION_HEADING: "OPTIONS POUR PRÉSENTER UNE DEMANDE",
    SCREENING_SUBMISSION_OPTION_1_HEAD: "En ligne :",
    SCREENING_SUBMISSION_OPTION_1: "Si vous avez accès à l’internet et possédez un compte de courriel, vous pouvez demander cette prestation en ligne. (consulter le ",
    SCREENING_SUBMISSION_OPTION_1_BOLD: "Feuillet d’information sur le programme",
    SCREENING_SUBMISSION_OPTION_1_CLOSE: ")",

    SCREENING_SUBMISSION_OPTION_2_HEAD: "Par la poste :",
    SCREENING_SUBMISSION_OPTION_2_HEAD_LINK: "https://drupal.socialsupportsnb.ca/sites/default/files/2021-06/Formulaire_de_candidature_CHB%20-%20Final%20French%20-%20Update%203-r2.pdf",
    SCREENING_SUBMISSION_OPTION_2: "Si vous n’avez pas accès à l’internet ou ne possédez pas de compte de courriel, vous pouvez envoyer le formulaire rempli et tous les documents à l’appui requis par la poste à l’adresse suivante :",

    SCREENING_SUBMISSION_OPTION_2_MAIL_1: "Ministère du Développement social",
    SCREENING_SUBMISSION_OPTION_2_MAIL_2: "À l’attention d’Allocation Canada - Nouveau-Brunswick pour le logement",
    SCREENING_SUBMISSION_OPTION_2_MAIL_3: "Édifice Sartain MacDonald",
    SCREENING_SUBMISSION_OPTION_2_MAIL_4: "C.P. 6000",
    SCREENING_SUBMISSION_OPTION_2_MAIL_5: "551, rue King",
    SCREENING_SUBMISSION_OPTION_2_MAIL_6: "Fredericton (N.-B.)  E3B 5H1",

    SCREENING_SUBMISSION_OPTION_2_B: "Veuillez prévoir un délai de traitement supplémentaire si vous envoyez votre demande par la poste. Si vous souhaitez recevoir une copie papier du dossier de candidature, veuillez envoyer un courriel à ",
    SCREENING_SUBMISSION_OPTION_2_B_EMAIL: "infochb-acl@gnb.ca",
    SCREENING_SUBMISSION_OPTION_2_B_2: "ou appeler le 211 et indiquer votre adresse postale complète.",

    SCREENING_CHECKLIST_HEADING: "LISTE DE VÉRIFICATION",
    SCREENING_CHECKLIST_HEADING_A: "Vous aurez besoin des documents suivants pour compléter le processus de demande.",
    SCREENING_CHECKLIST_PARA_1: "Veuillez passer en revue les documents à l’appui requis et rassembler des copies de ceux-ci avant de commencer. Veuillez remplir le formulaire de demande et soumettre tous les documents en même temps. Pour l'instant, aucune option ne permet d’enregistrer votre demande. Cette liste de vérification est là pour vous aider à vous assurer que vous avez toute l’information requise pour soumettre votre demande. Les demandes incomplètes ne seront pas traitées. Fournissez des copies des documents suivants : ",

    SCREENING_CHECKLIST_DOC_1_NOTE: "Vous pouvez télécharger une copie de votre avis de cotisation en ligne à Mon Dossier de l’ARC sur le site de l’Agence du Revenu du Canada (ARC) pour chaque adulte (de plus de 19 ans) de votre ménage. Vous pouvez l’obtenir en ouvrant une session dans ",
    SCREENING_CHECKLIST_DOC_1_NOTE_1: "Mon Dossier de l’ARC",
    SCREENING_CHECKLIST_DOC_1_LINK_1: "https://www.canada.ca/fr/agence-revenu/services/services-electroniques/services-electroniques-particuliers/dossier-particuliers.html",
    SCREENING_CHECKLIST_DOC_1_NOTE_2: "ou en communiquant avec l’ARC par téléphone au 1-800-267-6999.",
    SCREENING_CHECKLIST_DOC_1_NOTE_3_1: "Si vous n’avez pas déclaré vos revenus, veuillez suivre le lien ci-dessous pour trouver un ",
    SCREENING_CHECKLIST_DOC_1_NOTE_3_2: "comptoir d’impôts gratuit",
    SCREENING_CHECKLIST_DOC_1_NOTE_3_3: " près de chez vous :",
    SCREENING_CHECKLIST_DOC_1_LINK_2: "https://www.canada.ca/fr/agence-revenu/services/impot/particuliers/programme-communautaire-benevoles-matiere-impot/besoin-coup-main-remplir-votre-declaration-revenus.html",
    SCREENING_CHECKLIST_DOC_1_AND: "ET",

    SCREENING_CHECKLIST_QH_1: "Preuve de revenu actuel",
    SCREENING_CHECKLIST_QH_2: "Information sur la résidence (le demandeur doit seulement fournir UN des documents suivants) ",
    SCREENING_CHECKLIST_QH_3: "Information sur le paiement (cette prestation sera versée aux demandeurs acceptés par dépôt direct)",
    SCREENING_CHECKLIST_QH_4: "Formulaire de demande",

    SCREENING_DOCUMENTS_CHECK_ONE_PRETXT1: "Pour l’admissibilité à l’ACL-NB, le ",
    SCREENING_DOCUMENTS_CHECK_ONE_PRETXT2: "revenu total de votre ménage",
    SCREENING_DOCUMENTS_CHECK_ONE_PRETXT3: " doit être vérifié, ainsi que votre ",
    SCREENING_DOCUMENTS_CHECK_ONE_PRETXT4: "revenu d’emploi",
    SCREENING_DOCUMENTS_CHECK_ONE_PRETXT5: ". Pour vérifier cette information, vous devez fournir une copie de votre dernière avis de cotisation. Ce document confirme le revenu total de votre ménage.",
    SCREENING_DOCUMENTS_CHECK_ONE_NOTE: "Remarque :",

    SCREENING_DOCUMENTS_CHECK_ONE_TXT4: "ET",
    SCREENING_DOCUMENTS_CHECK_ONE_TXT6: "L’UNE des options suivantes pour nous indiquer votre revenu d'emploi actuel :",
    //SCREENING_DOCUMENTS_CHECK_ONE_TXT7: "ils indiquent votre revenu d’emploi actuel.",

    SCREENING_DOCUMENTS_CHECK_ONE_TXT8: "1ière option",
    SCREENING_DOCUMENTS_CHECK_ONE_TXT9A: "2ième option",
    SCREENING_DOCUMENTS_CHECK_ONE_TXT9B: "(Nouvel emploi – vous n’avez pas quatre talons de paie)",
    SCREENING_DOCUMENTS_CHECK_ONE_TXT10: "Talons de paye : Veuillez fournir vos quatre talons de paye les plus récents OU un talon de paye cumulatif depuis le début de l'année. Les talons de pay doivent indiquer votre nom, le nom et l'adresse de votre employeur et la période de paye.",
    SCREENING_DOCUMENTS_CHECK_ONE_TXT_NOTE: "Remarque : ",
    SCREENING_DOCUMENTS_CHECK_ONE_TXT10_OOPS: "Si vous avez occupé plusieurs emplois, veuillez fournir des talons de paie pour chacun.",
    SCREENING_DOCUMENTS_CHECK_ONE_TXT11: "Vous avez un nouvel emploi et vous n'avez pas encore quatre talons de chèque de paye ou vous êtes actuellement en congé de maternité ou de paternité.",
    SCREENING_DOCUMENTS_CHECK_ONE_TXT11_NEW: "Lettre de l'employeur :",
    SCREENING_DOCUMENTS_CHECK_ONE_TXT12: "La date de votre embauche.",
    SCREENING_DOCUMENTS_CHECK_ONE_TXT13: "Votre revenu annuel brut, y compris la rémunération des heures supplémentaires ou les primes.",
    SCREENING_DOCUMENTS_CHECK_ONE_TXT13_1: "Le taux de rémunération.",
    SCREENING_DOCUMENTS_CHECK_ONE_TXT14: "L’horaire de travail hebdomadaire.",
    SCREENING_DOCUMENTS_CHECK_ONE_TXT15: "Le salaire mensuel brut.",
    SCREENING_DOCUMENTS_CHECK_ONE_TXT15_B: "Coordonnées de l'employeur.",
    SCREENING_DOCUMENTS_CHECK_ONE_TXT15_C: "Signature de l'employeur.",
    SCREENING_DOCUMENTS_CHECK_ONE_TXT16: "Cette lettre doit être datée de moins de 30 jours après votre demande de l'Allocation Canada-Nouveau-Brunswick pour le logement.",
    SCREENING_DOCUMENTS_CHECKONE_TXT17: "Pour les lettres concernant un congé de maternité ou de paternité, veuillez demander à l’employeur de préciser votre date de retour au travail.",
    
    SCREENING_DOCUMENTS_CHECK_TWO_A: "Reçu de loyer indiquant l’adresse, le montant du loyer, la date et le nom du propriétaire.",
    SCREENING_DOCUMENTS_CHECK_TWO_B: "Copie d’un avis d’augmentation de loyer récent.",
    SCREENING_DOCUMENTS_CHECK_TWO_LINK: "https://www2.gnb.ca/content/dam/gnb/departments/sdds/pdf/residenceconfirmationconfirmationderesidence.pdf",
    SCREENING_DOCUMENTS_CHECK_TWO_C: "Copie de bail ou de convention de location (s’il a été signé au cours des 12 derniers mois).",
    SCREENING_DOCUMENTS_CHECK_TWO_D: "Formulaire de confirmation de résidence",
    SCREENING_DOCUMENTS_CHECK_TWO_D_1: " (inclus dans la trousse).",
    SCREENING_DOCUMENTS_CHECK_THREE: "Veuillez remplir le Formulaire de ",
    SCREENING_DOCUMENTS_CHECK_THREE_1: "Dépôt Direct pour l’Allocation Canada - Nouveau-Brunswick pour le logement",
    SCREENING_DOCUMENTS_CHECK_THREE_LINK: "https://drupal.socialsupportsnb.ca/sites/default/files/2021-06/CHB%20SD%20Payee%20_%20Direct%20Deposit%20Form_Final-r2.pdf",
    SCREENING_DOCUMENTS_CHECK_FOUR: "Veuillez remplir le formulaire de demande (inclus dans la trousse) et le soumettre avec une copie de l’avis de cotisation et des talons de paie des membres du ménage de plus de 19 ans, l’information sur la résidence ainsi que le Formulaire de Dépôt Direct pour l’Allocation Canada - Nouveau-Brunswick pour le logement.",

    SCREENING_DOCUMENTS_PLEASE_NOTE: "Please note:",
    SCREENING_DOCUMENTS_PLEASE_NOTE_CONT: "Nous comprenons que votre situation (famille, emploi, finances) peut changer au cours de l’année. Si votre situation change au cours de l’année, votre prestation pourrait être rajustée l’année suivante. Votre prestation est calculée une fois par année. Il n’y a pas de trop-payé dans ce programme.",
    SCREENING_DOCUMENTS_PLEASE_NOTE_CONT_2: "L’Allocation Canada - Nouveau-Brunswick pour le logement n’a aucune incidence sur les autres prestations ou soutiens du Ministère du Développement social.",

    CHB_SCREENING_PROCEED: "Procéder",
    
    CHB_REGISTRATION_PAGE_TITLE: "Formulaire L’Allocation Canada - Nouveau-Brunswick pour le logement",
    CHB_REGISTRATION_PAGE_ABOUT_TITLE: "AU SUJET DE L’ALLOCATION CANADA - NOUVEAU-BRUNSWICK POUR LE LOGEMENT",

    CHB_REGISTRATION_ENGLISH: "Anglais",
    CHB_REGISTRATION_FRENCH: "Français",

    CHB_REGISTRATION_GENDER_IDENTITY: "Identité de genre",
    CHB_REGISTRATION_GENDER_IDENTITY_EXP: "Information pour établir la composition du ménage",
    CHB_REGISTRATION_GENDER_MALE: "Homme",
    CHB_REGISTRATION_GENDER_FEMALE: "Femme",
    CHB_REGISTRATION_GENDER_NON_BINARY: "Non binaire",
    CHB_REGISTRATION_GENDER_OTHER: "Je préfère fournir moi-même une description de mon genre",
    CHB_REGISTRATION_GENDER_NOT_SAY: "Je préfère ne pas répondre",

    CHB_REGISTRATION_WRITTEN_LANGUAGE: "Langue préférée (écrite)",
    CHB_REGISTRATION_SPOKEN_LANGUAGE: "Langue préférée (parlée)",

    CHB_REGISTRATION_HOWSHOULDWE: "De quelle manière préférez-vous recevoir nos communications?",

    CHB_REGISTRATION_FIRST_NAME: "Prénom",
    CHB_REGISTRATION_PREFERRED_NAME: "Nom usuel (préféré)",
    CHB_REGISTRATION_LAST_NAME: "Nom de famille",

    CHB_REGISTRATION_EMAIL: "Courriel",
    CHB_REGISTRATION_PHONE: "Téléphone",
    CHB_REGISTRATION_MAIL: "Mail",

    CHB_REGISTRATION_HOME_PHONE: "Numéro de téléphone",
    CHB_REGISTRATION_DATE_OF_BIRTH: "Date de naissance (aaaa/mm/jj)",

    CHB_REGISTRATION_PROVINCE: "Nouveau-Brunswick",

    CHB_REGISTRATION_RELATIONSHIP: "Lien avec le demandeur",
    CHB_REGISTRATION_SPOUSE: "Conjoint/conjointe/partenaire",
    CHB_REGISTRATION_CHILD: "Enfant",
    CHB_REGISTRATION_PARENT: "Parent",
    CHB_REGISTRATION_RELO: "Autre parent",
    CHB_REGISTRATION_NRELO: "Autre personne non apparentée",
    CHB_REGISTRATION_ORP: "Autre partenaire apparenté",
    CHB_REGISTRATION_ONRP: "Autre partenaire non apparenté",
    CHB_MONTHLY_RENT: "Quel est votre loyer mensuel?",
    CHB_MONTHLY_UTILITY_BLURB: "En moyenne, à combien s’élève votre facture mensuelle de services publics? Veuillez répondre « 0 » aux énoncés qui ne s’appliquent pas.",
    CHB_MONTHLY_WATER: "Eau",
    CHB_MONTHLY_ELECT: "Électricité",
    CHB_MONTHLY_HEAT: "Chauffage",

    CHB_TYPE_OF_RENTAL: "Type de logement",
    
    CHB_TYPE_OF_RENTAL_PRIV: "Propriétaire privé",
    CHB_TYPE_OF_RENTAL_NP_H: "Logement social",
    CHB_TYPE_OF_RENTAL_COOP: "Logement coopératif",

    CHB_REGISTRATION_SIN: "Numéro d’assurance sociale (Nécessaire aux fins de l'impôt)",
    CHB_REGISTRATION_SIN_EXP_1: "Le feuillet d’impôt",
    CHB_REGISTRATION_SIN_EXP_2: "T5007",
    CHB_REGISTRATION_SIN_EXP_3: "vous aidera à préparer votre déclaration de revenus, car il présente le montant de l’aide provinciale qui vous a été accordée au cours d’une année d’imposition. Le revenu indiqué sur le feuillet T5007 n’est pas imposable, mais sert aux fins du calcul de votre admissibilité à des crédits d’impôt, comme le crédit de TPS, ainsi qu’à l’Allocation Canada - Nouveau-Brunswick pour enfants.",
    CHB_REGISTRATION_SIN_LINK: "https://www.canada.ca/fr/agence-revenu/services/formulaires-publications/publications/t4115/guide-t5007-declaration-prestations.html",

    CHB_MARGINALIZED_OPTION_TXT_1: "Vous identifiez-vous à un ou plusieurs des groupes ci-contre? Veuillez cocher toutes les cases applicables. Cette section est réservée au demandeur principal.",
    CHB_MARGINALIZED_OPTION_TXT_2: "Le Ministère du Développement social prône la diversité par des initiatives qui favorisent l’intégration des groupes marginalisés. Si vous souhaitez préciser votre statut, veuillez remplir cette section. Notez que cette information servira exclusivement à des fins statistiques et d’élaboration de programme.",
    CHB_MARGINALIZED_OPTION_1: "Femme fuyant la violence domestique",
    CHB_MARGINALIZED_OPTION_2: "Autochtone (membre des Premières Nations, Métis, Inuits et Indien non inscrit)",
    CHB_MARGINALIZED_OPTION_3: "Personne sans domicile fixe",
    CHB_MARGINALIZED_OPTION_4: "Personne handicapée",
    CHB_MARGINALIZED_OPTION_5: "Personne ayant un problème de pauvretésanté mentale",
    CHB_MARGINALIZED_OPTION_6: "Personne toxicomane",
    CHB_MARGINALIZED_OPTION_7: "Ancien(ne) combattant(e)",
    CHB_MARGINALIZED_OPTION_8: "Jeune adulte",
    CHB_MARGINALIZED_OPTION_9: "Personne d’une minorité visible",
    CHB_MARGINALIZED_OPTION_10: "Nouvel arrivant au Canada",
    CHB_MARGINALIZED_OPTION_11: "Personne de minorité de genre ou sexuelle (communauté LGBTIQ2S+)",
    CHB_MARGINALIZED_OPTION_12: "Personne ayant grandi dans la pauvreté",
    CHB_MARGINALIZED_OPTION_13: "Préfère ne pas répondre",

    CHB_CURRENT_HOUSING_OPTION_TXT: "Veuillez établir si les déclarations ci-contre décrivent votre situation actuelle en matière de logement (cochez toutes les cases qui s’appliquent).",
    CHB_CURRENT_HOUSING_OPTION_1: "Je ne dispose pas d’un nombre suffisant de chambres pour ma famille",
    CHB_CURRENT_HOUSING_OPTION_2: "Des réparations importantes à mon logement sont nécessaires",
    CHB_CURRENT_HOUSING_OPTION_3: "Je veux déménager, mais je n’en ai pas les moyens",
    CHB_CURRENT_HOUSING_OPTION_4: "Je vis trop loin du travail, de l’école, de la garderie",
    CHB_CURRENT_HOUSING_OPTION_5: "Je ne veux pas déménager, mais j’ai besoin d’aide pour mon loyer",
    CHB_CURRENT_HOUSING_OPTION_6: "Préfère ne pas répondre",

    CHB_ALREADY_RECEIVING_OPTION_TXT_1: "Recevez-vous déjà une aide du Ministère du Développement social (si oui, veuillez indiquer tous les programmes qui s’appliquent)?",
    CHB_ALREADY_RECEIVING_OPTION_TXT_2: "Si vous consultez déjà un travailleur social ou un gestionnaire de cas, nous vous encourageons à discuter avec cette personne de la prestation de l’ACL-NB pour en tirer profit.",
    CHB_ALREADY_RECEIVING_OPTION_TXT_3: "Cela n’aura aucune incidence sur les autres prestations que vous recevez du Ministère du Développement social.",
    CHB_ALREADY_RECEIVING_OPTION_1: "Services pour les enfants et les jeunes",
    CHB_ALREADY_RECEIVING_OPTION_2: "Services pour les personnes handicapées",
    CHB_ALREADY_RECEIVING_OPTION_3: "Services pour les personnes bénéficiant d’une aide au revenu",
    CHB_ALREADY_RECEIVING_OPTION_4: "Services pour les personnes âgées",
    CHB_ALREADY_RECEIVING_OPTION_5: "Services relatifs au logement",

    CHB_CURRENT_BARRIERS_OPTION_TXT: "Obstacles actuels à l’emploi – augmentation des heures ou amélioration des conditions (cochez toutes les cases qui s’appliquent)",
    CHB_CURRENT_BARRIERS_OPTION_1: "Lacunes sur le plan de l’éducation/des diplômes/de la certification",
    CHB_CURRENT_BARRIERS_OPTION_2: "Options de transport inadéquates",
    CHB_CURRENT_BARRIERS_OPTION_3: "Problèmes de santé (santé physique et mentale, toxicomanie, handicap)",
    CHB_CURRENT_BARRIERS_OPTION_4: "Responsabilités de garde d’enfant ou d’aidant",
    CHB_CURRENT_BARRIERS_OPTION_5: "Peu de possibilités d’emploi (faibles perspectives d’emploi, casier judiciaire)",
    CHB_CURRENT_BARRIERS_OPTION_6: "Désir de travailler plus, mais ne sais pas m’y prendre",
    CHB_CURRENT_BARRIERS_OPTION_7: "Préfère ne pas répondre",
    CHB_CURRENT_BARRIERS_OPTION_8: "Autre (donner une explication) :",

    CHB_ADDRESS_BLURB_H: "Adresse du domicile",
    CHB_ADDRESS_BLURB_M: "Adresse postale",
    CHB_ADDRESS_CIVIC: "Numéro de rue :",
    CHB_ADDRESS_STREET_NAME: "Nom de rue :",
    CHB_ADDRESS_APARTMENT: "No. d’appartement ou d’unité :",
    CHB_ADDRESS_CITY: "Ville :",
    CHB_ADDRESS_PROVINCE: "Province :",
    CHB_ADDRESS_POSTAL: "Code postal :",

    CHB_INCOME_EMP_PRETXT_1: "Quel était le revenu d’emploi total du ménage au cours des ",
    CHB_INCOME_EMP_PRETXT_1_2: "12 derniers mois",
    CHB_INCOME_EMP_PRETXT_2: "Cette information sert au calcul des besoins matière de logement.",
    CHB_INCOME_EMP_PRETXT_3: "L’emploi régulier englobe le revenu d’emploi de tous les membres du ménage âgés de 19 ans et plus, à l’exclusion des personnes à charge de moins de 25 ans qui étudient à temps plein au niveau postsecondaire.",
    CHB_INCOME_EMP_PRETXT_4: "Votre prestation d’aide sociale ne sera pas réduite si vous êtes admissible à l’Allocation Canada - Nouveau-Brunswick pour le logement.",

    CHB_VOLUNTARY_PRETXT_1: "Section facultative. Les questions suivantes n’ont pas d’incidence sur votre admissibilité. Les réponses aideront le Ministère du Développement social à cerner les bienfaits du programme et ses aspects à améliorer.",

    CHB_SECTION_HEADING_1: "Demandeur Principal",
    CHB_SECTION_HEADING_2: "Membre supplémentaire du ménage âgé de 19 ans ou plus",
    CHB_SECTION_HEADING_3: "Membre supplémentaire du ménage âgé de 18 ans ou moins",
    CHB_SECTION_HEADING_3_OOPS: "RENSEIGNEMENTS SUR LA LOCATION",
    CHB_SECTION_HEADING_4: "REVENUS D’EMPLOI",
    CHB_SECTION_HEADING_5: "INFORMATION COMPLÉMENTAIRE",
    CHB_SECTION_HEADING_6: "CONSENTEMENT",
    CHB_SECTION_HEADING_7: "DÉCLARATION",

    CHB_INCOME_TYPE_APPLICANT: "Demandeur",
    CHB_INCOME_TYPE_SPOUSEPART: "Conjoint(e)/partenaire",
    CHB_INCOME_TYPE_OTHERHOUSE: "Autre(s) membre(s) du ménage de 19 ans et plus",
    CHB_INCOME_TYPE_IFAPPLIC: "(le cas échéant)",

    CHB_TABLE_ROW_1: "Emploi régulier (avant impôts)",
    CHB_TABLE_ROW_2: "Assurance-emploi",
    CHB_TABLE_ROW_3: "Travail indépendant",
    CHB_TABLE_ROW_4: "Assistance sociale",
    CHB_TABLE_ROW_5: "Soutien pour enfants (reçu)",
    CHB_TABLE_ROW_6: "Pension alimentaire",
    CHB_TABLE_ROW_7: "Sécurité de la vieillesse",
    CHB_TABLE_ROW_8: "Supplément de revenu garanti",
    CHB_TABLE_ROW_9: "Pension du Canada",
    CHB_TABLE_ROW_10: "Indemnisation des travailleurs",
    CHB_TABLE_ROW_11: "Pension d’invalidité",
    CHB_TABLE_ROW_12: "Pension d’ancien combattant",
    CHB_TABLE_ROW_13: "Autres prestations",
    CHB_TABLE_ROW_14: "Revenu de location",
    CHB_TABLE_ROW_15: "Intérêts et revenus de placements",

    CHB_EMPLOYMENT_HOURSWEEK: "Nombre d’heures travaillées par semaine",

    CHB_EMPLOYMENT_SOURCES_ADDREMOVE_TEXT: "Si vous occupez plus d’un emploi, veuillez appuyer sur Ajouter.",
    CHB_EMPLOYMENT_INCOME_ADDREMOVE_TEXT: "Si vous fournissez d’autres informations sur votre revenu d’emploi, veuillez appuyer sur Ajouter.",

    CHB_EMPLOYMENT_RATEPAY: "Taux de rémunération par heure",
    CHB_EMPLOYMENT_SOURCE1: "Emploi 1 :",
    CHB_EMPLOYMENT_SOURCE2: "Emploi 2 :",
    CHB_EMPLOYMENT_SOURCE3: "Emploi 3 :",

    CHB_BEDROOMS_TXT1: "Certains membres du ménage ont-ils besoin d’une chambre séparée en raison d’un problème médical ou d’une affection particulière? Si oui, combien de chambres à coucher avez-vous besoin?",
    CHB_BEDROOMS_TXT1B: "Combien de chambres à coucher avez-vous besoin?",
    CHB_BEDROOMS_TXT2: "Un rapport médical ou une preuve de handicap peut être exigé.",

    CHB_BRANDNEW_TXT: "Le programme d’Allocation Canada - Nouveau-Brunswick pour le logement est nouveau, et nous souhaitons l’améliorer sur la base de vos expériences. Accepteriez vous que le Ministère du Développement social communique avec vous pour se renseigner sur votre expérience et discuter de la manière dont il peut améliorer ses programmes? Vos commentaires sont importants pour nous.",
    CHB_BRANDNEW_TXT2: "Votre réponse n’aura aucune incidence sur votre admissibilité au programme.",

    CHB_BEST_POSSIBLE_TXT: "Afin de vous fournir le meilleur soutien possible, le Ministère du Développement social met en commun de l’information entre ses programmes (notamment ceux visant le logement, les services à l’enfance et à la jeunesse, le soutien aux personnes handicapées et l’assistance sociale). Consentez-vous à ce qu’il le fasse dans le présent cas?",

    CHB_DECLARATION_TXT1: "Declaration",
    CHB_DECLARATION_TXT2: "Veuillez s’il-vous-plaît appuyer sur la boite à cocher pour confirmer que l’information est correcte.",
    CHB_DECLARATION_TXT3: "J’atteste que l’information qui précède est exacte et complète.",
    CHB_DECLARATION_TXT4: "J’autorise le Ministère du Développement social à vérifier toute information contenue dans le présent formulaire. Je reconnais également que si des éléments d’information s’avèrent faux ou inexacts, je serai inadmissible à la prestation.",

    CHB_DECLARATION_TXT5: "J’accepte que le Ministère du Développement social communique avec moi par courriel au sujet de ma demande de ACL-NB.",
    CHB_DECLARATION_TXT6: "Je comprends que l'envoi de mes informations personnelles par voie électronique depuis mon compte de messagerie à le Ministère du Développement social est volontaire et à mes propres risques car les comptes de courriel come Hotmail, Gmail, etc. ne sont pas securise.",

    CHB_DECLARATION_TXT7: "Le Ministère du Développement social ne peut pas garantir la transmission sécurisée de renseignements personnels lorsqu’un courriel quitte l’infrastructure du GNB, car les comptes de courriel comme Hotmail, Gmail, etc., ne sont pas sécurisés. ",
    CHB_DECLARATION_TXT8: "Le Ministère du Développement social n’est pas responsable de toute perte de données résultant de la transmission électronique de renseignements personnels à partir de votre courriel personnel.",
    

    CHB_DEFAULT_FEEDBACK: "Ce champ est obligatoire.",

    CHB_FIELD_BLANK_APPLICANT_FIRST_NAME: "Veuillez entrer le prénom du demandeur.",
    CHB_FIELD_BLANK_APPLICANT_LAST_NAME: "Veuillez entrer le nom de famille du demandeur.",
    CHB_FIELD_BLANK_APPLICANT_PREFFERED_LANG: "Veuillez choisir la langue préférée (à l’écrit) du demandeur.",
    CHB_FIELD_BLANK_APPLICANT_SPOKEN_LANG: "Veuillez choisir la langue préférée (à l’oral) du demandeur.",
    CHB_FIELD_BLANK_HOW_COMMUNICATE: "Veuillez choisir le moyen de communication préféré du demandeur.",
    CHB_FIELD_BLANK_APPLICANT_TELEPHONE: "Veuillez entrer le numéro de téléphone du demandeur.",
    CHB_FIELD_BLANK_APPLICANT_EMAIL: "Veuillez entrer l’adresse courriel du demandeur.",
    CHB_FIELD_BLANK_APPLICANT_DOB: "Veuillez entrer la date de naissance du demandeur.",
    CHB_FIELD_BLANK_APPLICANT_GENDER: "Veuillez choisir l’identité de genre du demandeur.",
    CHB_FIELD_BLANK_SELF_DESCRIBE: "Veuillez entrer l’autodescription de l’identité de genre du demandeur.",
    CHB_FIELD_BLANK_SOCIAL_INSURANCE: "Veuillez entrer le numéro d’assurance sociale du demandeur.",
    CHB_FIELD_BLANK_STREET_NAME: "Veuillez entrer le nom de la rue du demandeur.",
    CHB_FIELD_BLANK_CITY_NAME: "Veuillez entrer le nom de la ville du demandeur.",
    CHB_FIELD_BLANK_PROVINCE_NAME: "Veuillez entrer la province du demandeur.",
    CHB_FIELD_BLANK_PROVINCE_NOT_NB: "Doit être le Nouveau-Brunswick.",
    CHB_FIELD_BLANK_POSTAL_CODE: "Veuillez entrer le code postal du demandeur.",
    CHB_FIELD_BLANK_MAILING_CUSTOM: "Veuillez entrer le nom de la rue, la ville, la province et le code postal de l’adresse postale du demandeur.",
    CHB_FIELD_BLANK_ADDITIONAL_CUSTOM: "Veuillez entrer le prénom, le nom de famille, l’identité de genre, la date de naissance et la relation pour tout autre membre du ménage.",
    CHB_FIELD_BLANK_MONTHLY_RENT: "Veuillez entrer le montant de votre loyer mensuel.",
    CHB_FIELD_BLANK_MONTHLY_UTILITIES: "Veuillez entrer le montant moyen de votre paiement mensuel de services publics.",
    CHB_FIELD_BLANK_TYPE_RENTAL: "Veuillez choisir votre type de logement locatif.",
    CHB_FIELD_BLANK_DECLARATION: "Veuillez s’il-vous-plaît appuyer sur la boite à cocher pour confirmer que l’information est correcte.",
    CHB_FIELD_BLANK_INCOME_CUSTOM: "Au moins une source de revenu doit être entrée pour le demandeur principal.",

    CHB_FIELD_FILE_UPLOAD: "Télécharger le fichier",
    CHB_FIELD_FILE_UPLOAD_ERROR_LIMIT: "Le fichier doit être de 7MO ou moins.",
    CHB_FIELD_FILE_UPLOAD_ERROR_TYPE: "Le fichier doit être de type PDF, JPG, TIFF ou PNG.",
    CHB_FIELD_FILE_UPLOAD_ERROR_UNKNOWN: "Une erreur inconnue s’est produite. Veuillez réessayer.",

    CHB_UPLOAD_DIRECT_DEPOSIT_FORM_HEADER: "Téléverser le Formulaire de Dépôt Direct",
    CHB_UPLOAD_DIRECT_DEPOSIT_FORM_DESC: "Veuillez remplir et soumettre le ",
    CHB_UPLOAD_DIRECT_DEPOSIT_FORM_DESC_1: "Formulaire de Dépôt Direct",
    CHB_UPLOAD_DIRECT_DEPOSIT_FORM_DESC_1_LINK: "https://drupal.socialsupportsnb.ca/sites/default/files/2021-06/CHB%20SD%20Payee%20_%20Direct%20Deposit%20Form_Final-r2.pdf",
    CHB_UPLOAD_DIRECT_DEPOSIT_FORM_DESC_2: " pour recevoir les virements de l’Allocation Canada - Nouveau-Brunswick pour le logement.",
    CHB_UPLOAD_DIRECT_DEPOSIT_FORM_NOTE: "Un numéro de fournisseur vous sera attribué, vous en aurez besoin pour consulter votre dossier.",

    CHB_HOUSEHOLD_HEADING: "INFORMATION SUR LE MÉNAGE",
    CHB_HEADING_NOTE_1: "Vos renseignements personnels sont collectés par la Société d’habitation du Nouveau-Brunswick en vertu de la Loi sur l’habitation au Nouveau-Brunswick, et à ce titre protégés de toute utilisation ou divulgation non autorisées.",
    CHB_HEADING_NOTE_2: "Cette information est recueillie pour établir la composition du ménage et les autres membres du ménage qui contribuent au revenu d’emploi total du ménage.",

    CHB_HEADER_UPLOAD: "TÉLÉCHARGEMENT DE RENSEIGNEMENTS À L’APPUI",
    CHB_HEADER_UPLOAD_TXT_1: "Veuillez remplir le formulaire de demande et le soumettre avec copies de tous les documents requis (avis de cotisation, talons de paie, renseignements sur la location et formulaire de dépôt direct).",
    CHB_HEADER_UPLOAD_TXT_2: "Le document téléchargé doit être clair et lisible; sinon, vous recevrez un courriel vous demandant de le soumettre de nouveau, ce qui retardera le traitement de votre demande.",
    CHB_HEADER_UPLOAD_TXT_3: "Veuillez télécharger les renseignements à l’appui à l’aide des champs ci-dessous.",
    CHB_HEADER_UPLOAD_TXT_4: "Vous pouvez télécharger les fichiers de type PDF, JPG, TIFF ou PNG. Si vous décidez d’utiliser une photo (JPG, TIFF ou PNG), sachez qu’il y a une dimension limite de 7MO par dossier.",

    CHB_TIP: "Conseil",
    CHB_TIP_FILE_UPLOAD_TXT_1: "Il y a plusieurs méthodes pour redimensionner une photo si elle est supérieure à 7MO. Voici les options :",
    CHB_TIP_FILE_UPLOAD_TXT_2: "Une façon rapide de réduire la taille d’une photo à l’aide d’un téléphone cellulaire consiste à envoyer la photo par courriel à vous-même.",
    CHB_TIP_FILE_UPLOAD_TXT_3: "Sur Windows, ouvrez la photo dans l’application Paint et à partir de la barre d’outils, appuyer sur ",
    CHB_TIP_FILE_UPLOAD_TXT_3_A: "« Redimensionner ».",
    CHB_TIP_FILE_UPLOAD_TXT_4: "Sur un ordinateur Apple, ouvrez la photo dans Aperçu, sélectionnez Outils et appuyer ajuster la taille.",
    CHB_TIP_FILE_UPLOAD_TXT_5: "Cherchez dans l'internet les méthodes propres à votre appareil pour télécharger des photos (iPhone, Android, iPad, PC).",

    CHB_SUBMIT_FORM: "Soumettre le formulaire",

    CHB_TIP_FILE_MODAL_TXT_1: "Envoi en cours...",
    CHB_TIP_FILE_MODAL_TXT_2: "Soumettre la demande d’ACL-NB",
    CHB_TIP_FILE_MODAL_TXT_3: "Votre demande d’Allocation Canada - Nouveau-Brunswick pour le logement a été soumise et votre numéro de demande est :",
    CHB_TIP_FILE_MODAL_TXT_4: "Après avoir traité votre demande, nous communiquerons avec vous pour vous dire si vous y êtes admissible. N’hésitez pas à communiquer avec nous par courriel à ",
    CHB_TIP_FILE_MODAL_TXT_4_A: "chb-acl@gnb.ca",
    CHB_TIP_FILE_MODAL_TXT_4_B: " ou par téléphone au 211.",
    CHB_TIP_FILE_MODAL_TXT_5: "Retour à la page d’accueil",
    CHB_TIP_FILE_MODAL_TXT_6: "Pour un complément d’information, consultez la page Web de Ministère du Développement social sur l’Allocation Canada - Nouveau-Brunswick pour le logement.",
    CHB_TIP_FILE_MODAL_TXT_7: "Veuillez conserver votre numéro de demande pour vos dossiers.",

    CHB_UTILITIES_INCLUDED: "Les services publics sont-ils inclus dans votre loyer?",
    CHB_MAILING_ADDRESS_DIFFERENT: "Votre adresse postale est-elle différente de votre adresse domiciliaire?",

    CHB_MORE_HOUSEHOLD_ADDREMOVE19: "Si plus d’un membre du ménage est âgé de plus de 18 ans, appuyer sur Ajouter.",
    CHB_MORE_HOUSEHOLD_ADDREMOVE18: "Si plus d’un membre du ménage est âgé de moins de 19 ans, appuyer sur Ajouter.",

    CHB_OTHER_INCOME_HEADER: "AUTRES REVENUS",
    CHB_OTHER_INCOME_BLURB: "Identifier les autres revenus du ménage avant impôt pour les 12 derniers mois.",


    CHB_RENTAL_INFORMATION_UPLOAD_HEADER: "Téléverser l’information sur la résidence",
    CHB_RENTAL_INFROMATION_UPLOAD_DESC_1: "Veuillez fournir l`",
    CHB_RENTAL_INFORMATION_UPLOAD_DESC_2: "un",
    CHB_RENTAL_INFORMATION_UPLOAD_DESC_3: " des documents suivants :",
    CHB_RENTAL_INFORMATION_UPLOAD_OPTION_1: "Reçu de loyer indiquant l’adresse, le montant du loyer, la date et le nom du propriétaire.",
    CHB_RENTAL_INFORMATION_UPLOAD_OPTION_2: "Avis d’augmentation de loyer récent.",
    CHB_RENTAL_INFORMATION_UPLOAD_OPTION_3: "Bail ou convention de location (si le document a été signé au cours des 12 derniers mois).",
    CHB_RENTAL_INFORMATION_UPLOAD_OPTION_4: "",
    CHB_RENTAL_INFORMATION_UPLOAD_OPTION_4_1: "Confirmation de résidence",
    CHB_RENTAL_INFORMATION_UPLOAD_OPTION_4_2: " signée.",
    CHB_RENTAL_INFORMATION_UPLOAD_OPTION_4_LINK: "https://www2.gnb.ca/content/dam/gnb/departments/sd-ds/pdf/residenceconfirmation-confirmationderesidence.pdf",
    CHB_RENTAL_INFORMATION_UPLOAD_CAUTION: "Veuillez-vous assurer que le document fourni est clair et lisible. Le traitement de votre demande sera plus long si nous devons obtenir des précisions sur un document.",
    CHB_RENTAL_INFORMATION_UPLOAD_TEXT: "MISSING TEXT",
    CHB_RENTAL_INFORMATION_UPLOAD_PLEASE_ADD: "MISSING TEXT",
    
    CHB_INCOME_EMPLOYMENT_UPLOAD_HEADER: "Téléverser une preuve de revenu d’emploi",
    CHB_INCOME_EMPLOYMENT_UPLOAD_TEXT: "Veuillez fournir des talons de paie OU ",
    CHB_INCOME_EMPLOYMENT_UPLOAD_TEXT_1: "une lettre de l’employeur.",
    CHB_INCOME_EMPLOYMENT_UPLOAD_PLEASE_ADD: "Si vous fournissez d’autres informations sur votre revenu d’emploi, veuillez appuyer sur Ajouter.",
    
    CHB_INCOME_HOUSEHOLD_UPLOAD_HEADER: "Téléverser des preuves du revenu total du ménage",
    CHB_INCOME_HOUSEHOLD_UPLOAD_TEXT: "Veuillez fournir une copie de l’avis de cotisation produit par l’Agence de Revenu du Canada pour chaque membre du ménage. Ces documents confirment le revenu total de votre ménage.  ",
    CHB_INCOME_HOUSEHOLD_UPLOAD_PLEASE_ADD: "MISSING TEXT",

    CHB_INCOME_HOUSEHOLD_UPLOAD_TIP_1: "Vous pouvez télécharger une copie de votre avis de cotisation en ligne à Mon Dossier de l’ARC sur le site de l’Agence du Revenu du Canada (ARC) pour chaque adulte (de plus de 19 ans) de votre ménage. Vous pouvez l’obtenir en ouvrant une session dans",
    CHB_INCOME_HOUSEHOLD_UPLOAD_TIP_2: "Mon Dossier de l’ARC",
    CHB_INCOME_HOUSEHOLD_UPLOAD_TIP_LINK: "https://www.canada.ca/fr/agence-revenu/services/services-electroniques/services-electroniques-particuliers/dossier-particuliers.html",
    CHB_INCOME_HOUSEHOLD_UPLOAD_TIP_3: "ou en communiquant avec l’ARC par téléphone au 1-800-267-6999.",

    CHB_DIRECT_DEPOSIT_UPLOAD_HEADER: "MISSING TEXT",
    CHB_DIRECT_DEPOSIT_UPLOAD_TEXT: "MISSING TEXT",

    CHB_FINAL_REVIEW_HEADER: "Dernière révision",
    CHB_FINAL_REVIEW_DESC_1: "Veuillez passer en revue votre demande avant de la soumettre :",
    CHB_FINAL_REVIEW_OPTION_1: "Vérifier que tous les renseignements ont été fournis et qu’ils sont exacts.",
    CHB_FINAL_REVIEW_OPTION_2: "Vérifier que tous les documents requis ont été fournis et qu’ils sont lisibles.",
    CHB_FINAL_REVIEW_DESC_2: "Les demandes incomplètes ne seront pas traitées. S’il manque un renseignement ou un document, vous recevrez un courriel de ",
    CHB_FINAL_REVIEW_DESC_EMAIL: "CHB-ACL@gnb.ca",
    CHB_FINAL_REVIEW_DESC_3: "vous demandant de le soumettre de nouveau, ce qui ",
    CHB_FINAL_REVIEW_DESC_4: "retardera",
    CHB_FINAL_REVIEW_DESC_4_1: " le traitement de votre demande.",
    CHB_FINAL_REVIEW_NOTE: "Vérifiez votre dossier de courriels indésirables au cas où le courriel s’y retrouverait et répondez-y promptement.",

    CHB_ADD: "Ajouter",
    CHB_REMOVE: "Enlever",
    CHB_YES: "Oui",
    CHB_NO: "Non",

    CHB_NO_FILE_SELECTED: "Aucun fichier sélectionné.",

    TMP: "tmp",

    LANDING_LONG_TERM_CARE_PROGRAM_TITLE: "Programme de soins de longue durée",
    LANDING_LONG_TERM_CARE_PROGRAM_DESC: "Si vous avez 65 ans ou plus, le programme de soins de longue durée peut vous aider à accéder à des services répondant à vos besoins et objectifs spécifiques en matière de soins.",
    LANDING_DISABILITY_SUPPORT_PROGRAM_TITLE: "Programme de soutien aux personnes ayant un handicap",
    LANDING_DISABILITY_SUPPORT_PROGRAM_DESC: "Le programme de soutien aux personnes ayant un handicap offre aux personnes ayant un handicap des soutiens personnalisés et flexibles pour accroître l’autonomie et les liens avec la communauté.",
    LANDING_DISABILITY_SUPPORT_PROGRAM_LINK: "program/programme-de-soutien-aux-personnes-ayant-un-handicap",
    LANDING_HOME_FIRST_TITLE: "Évaluation du programme D’abord chez soi",
    LANDING_HOME_FIRST_DESC: "Il s’agit d’une visite gratuite à domicile afin de présenter les services et programmes communautaires qui peuvent vous aider dans votre vie de tous les jours.",
    LANDING_HOME_SUPPORTS_TITLE: "Services de soutien à domicile",
    LANDING_HOME_SUPPORTS_DESC: "Les services de soutien à domicile peuvent être en mesure de vous aider avec les activités quotidiennes comme la cuisine, le bain et le nettoyage pour vous permettre de vivre de manière aussi autonome que possible dans votre maison.",
    LANDING_HOME_SUPPORTS_LINK: "program/services-de-soutien-domicile",
    LANDING_CHB_TITLE: "L’Allocation canadienne pour le logement (ACL)",
    LANDING_CHB_DESC: "Cette prestation aide les Néo-Brunswickois qui vivent seuls et qui travaillent à temps partiel ou qui ont des emplois moins bien rémunérés et qui ont de la difficulté financière à payer leur loyer.",
    LANDING_CHB_LINK: "program/lallocation-canadienne-pour-le-logement-acl",
    LANDING_PUBLIC_HOUSING_TITLE: "Programme de logement public",
    LANDING_PUBLIC_HOUSING_DESC: "Si vous avez des besoins de logement abordable, sécuritaire et adéquat, il existe des programmes qui peuvent vous aider, notamment le programme de logement public et les suppléments au loyer.",
    LANDING_PUBLIC_HOUSING_LINK: "program/programmes-de-logement-public-et-de-supplement-de-loyer",
    LANDING_REPAIR_TITLE: "Programme de réparations pour propriétaires",
    LANDING_REPAIR_DESC: "Le ministère du Développement social offre des programmes pour vous aider à réparer ou modifier votre maison pour la rendre plus sécuritaire, plus saine ou plus accessible, ou encore pour permettre un agrandissement destiné une personne ayant un handicap.",
    LANDING_REPAIR_LINK: "program/programmes-de-reparations-pour-proprietaires-et-loueurs",

    LANDING_FINANCES_DESC: "Aide avec les finances",
    LANDING_FINANCES_LINK: "finances",
    LANDING_SOCIAL_ASSISTANCE_TITLE: "Aide sociale",
    LANDING_SOCIAL_ASSISTANCE_DESC: "Le programme d’aide sociale offre de l’aide financière aux personnes qui n’ont pas les moyens de payer pour des dépenses de base, comme la nourriture, les vêtements, le logement et d’autres nécessités.",
    LANDING_SOCIAL_ASSISTANCE_LINK: "program/programme-daide-sociale",
    LANDING_CAREER_DEVELOPMENT_TITLE: "Perfectionnement professionnel",
    LANDING_CAREER_DEVELOPMENT_DESC: "Ce programme dirige des personnes bénéficiaires de l’aide sociale vers des programmes et des services qui les aideront à réaliser leur plein potentiel et à réussir leur transition vers le marché du travail.",
    LANDING_CAREER_DEVELOPMENT_LINK: "program/programme-options-de-developpement-de-la-carriere-odc",
    LANDING_HEADER_CAREERS_TITLE: "Carrières",
    LANDING_HEADER_CAREERS_LINK: "commencez-votre-carriere-dans-les-services-la-personne",
    LANDING_HEADER_REPORT_ABUSE_AND_NEGLECT_LINK: "comment-signaler-les-cas-de-mauvais-traitements-dagression-ou-de-negligence",
    LANDING_HEADER_REPORT_ABUSE_AND_NEGLECT_TITLE: "signaler mauvais traitements / négligence",
    LANDING_HEALTH_CARD_TITLE: "Carte d’assistance médicale",
    LANDING_HEALTH_CARD_DESC: "Une carte d’assistance médicale peut être délivrée aux personnes qui ne sont pas admissibles à l’aide sociale, mais qui ont des frais médicaux élevés et ne sont pas protégées par un régime d’assurance maladie privé.",
    LANDING_HEALTH_CARD_LINK: "program/programme-daide-sociale",
    LANDING_ABUSE_AND_NEGLECT_DESC: "Aide pour les cas de mauvais traitement et de négligence",
    LANDING_ABUSE_AND_NEGLECT_LINK: "abuse-and-neglect",
    LANDING_REPORT_TITLE: "Comment signaler un cas de mauvais traitement ou de négligence",
    LANDING_REPORT_DESC: "Que faire si vous soupçonnez qu’un enfant, une personne handicapée ou une personne âgée est en danger ou est victime de mauvais traitements, de négligence ou d’agression? Le ministère du Développement social pourrait être en mesure d’aider.",
    LANDING_REPORT_LINK: "program/comment-signaler-les-cas-de-mauvais-traitements-dagression-ou-de-negligence",
    LANDING_FINANCIAL_ABUSE_TITLE: "Exploitation financière",
    LANDING_FINANCIAL_ABUSE_DESC: "Les personnes âgées et certains adultes sont vulnérables aux fraudes, aux escroqueries et à l’exploitation. Il existe des mesures que vous pouvez prendre pour détecter et prévenir l’exploitation financière contre des personnes âgées.",
    LANDING_FINANCIAL_ABUSE_LINK: "simple_page/exploitation-financiere-des-personnes-agees",
    LANDING_PARTNER_VIOLENCE_TITLE: "Violence entre partenaires intimes",
    LANDING_PARTNER_VIOLENCE_DESC: "De l’aide est à la disposition des personnes victimes de mauvais traitements ou qui sont sous l’emprise de leur partenaire. Découvrez comment reconnaître la violence entre partenaires intimes et quelles sont les mesures à prendre si vous ou une personne que vous connaissez êtes dans une telle situation.",
    LANDING_PARTNER_VIOLENCE_LINK: "simple_page/violence-entre-partenaires-intimes",

    LANDING_FAMILIES_AND_YOUTH_DESC: "Aide offerte aux familles et aux jeunes",
    LANDING_FAMILIES_AND_YOUTH_LINK: "families-and-youth",
    LANDING_FOSTERING_TITLE: "Accueillir un enfant",
    LANDING_FOSTERING_DESC: "Parfois, les enfants et les jeunes ne peuvent pas vivre avec leurs propres parents. Cette situation peut durer quelques semaines ou plusieurs années. Lorsque c’est le cas, nous avons besoin de foyers aimants et attentionnés pour les enfants et les jeunes. En devenant famille d’accueil, vous pourriez avoir un effet énorme sur la vie d’une personne.",
    LANDING_CHILDREN_WITH_DISABILITIES_TITLE: "Programme de soutien aux familles pour les enfants ayant un handicap",
    LANDING_CHILDREN_WITH_DISABILITIES_DESC: "Le Programme de soutien aux familles pour les enfants ayant un handicap offre une aide financière aux parents et aux tuteurs pour les aider à payer les coûts extraordinaires liés aux soins nécessaires à un enfant handicapé.",
    LANDING_CHILDREN_WITH_DISABILITIES_LINK: "program/programme-de-soutien-aux-familles-pour-les-enfants-ayant-un-handicap",
    LANDING_HOW_TO_REPORT_TITLE: "Comment signaler les cas de mauvais traitements, d’agression ou de négligence",
    LANDING_HOW_TO_REPORT_DESC: "Vous craignez qu’un enfant, un adulte ayant un handicap ou un aîné soit victime de maltraitance ou de négligence? Vous avez besoin d’en parler à quelqu'un?",
    LANDING_HOW_TO_REPORT_LINK: "program/comment-signaler-les-cas-de-mauvais-traitements-dagression-ou-de-negligence",
    LANDING_YOUR_HEALTH_DESC: "De l’aide pour votre santé",
    LANDING_YOUR_HEALTH_LINK: "your-health",
    LANDING_PRESCRIPTION_DRUG_TITLE: "Régimes de médicaments sur ordonnance",
    LANDING_PRESCRIPTION_DRUG_DESC: "Si vous êtes un résident du Nouveau-Brunswick, que vous avez une carte d’Assurance maladie valide et que vous n’ avez pas d’assurance médicaments ailleurs, vous pourriez être admissible.",
    LANDING_PRESCRIPTION_DRUG_LINK: "program/regimes-de-medicaments-sur-ordonnance",
    LANDING_RED_CROSS_TITLE: "Programmes de prêt d’équipement médical de la Croix-Rouge",
    LANDING_RED_CROSS_DESC: "Vous avez besoin d’équipement de base comme une marchette ou des béquilles pour être plus en sécurité à la maison? Vous êtes un aîné qui a besoin d’équipement de réadaptation spécialisé pour être plus indépendant à la maison?",
    LANDING_RED_CROSS_LINK: "program/programmes-de-pret-dequipement-medical-de-la-croix-rouge",
    LANDING_CLEAR_VISION_TITLE: "Programme De beaux sourires et une bonne vision",
    LANDING_CLEAR_VISION_DESC: "Le régime De beaux sourires et une bonne vision offre des prestations de base pour les soins dentaires et les soins de la vue aux enfants âgés de 18 ans et moins vivant dans des familles à faible revenu.",
    LANDING_CLEAR_VISION_LINK: "program/programme-de-beaux-sourires-et-une-bonne-vision",
    
    FAMILIES_AND_YOUTH_COMMUNITY_SUPPORTS_DESC: "Renseignez-vous pour savoir comment vous pouvez vous engager dans votre communauté et y trouver du soutien, des programmes et des activités qui enrichiront votre vie.",

    ABOUT_HEADER: "À propos",
    ABOUT_DESCRIPTION: "Le ministère du Développement social a lancé Soutiens sociaux NB pour aider les gens du Nouveau-Brunswick à trouver facilement des renseignements sur les services et programmes gouvernementaux et communautaires. Nous souhaitons qu’il puisse devenir votre site Web de référence pour les renseignements dont vous avez besoin pour le soutien financier et le logement.  Vous y trouverez des renseignements sur les modes de vie sains, les droits et la protection juridiques et d’autres informations utiles.",
    ABOUT_BUTTON_TEXT: "Apprendre encore plus",
    ABOUT_VIDEO_LINK: "https://www.youtube.com/embed/d0SXnhiiFWI",
    LEARN_MORE_PATH: "propos",

    FOSTERING_SIDELINK: "https://www.youtube.com/embed/99gLIARqog4",
    FOSTERING_SIDELINK_TITLE: "Témoignage - découvrez l’expérience de Tracy en tant que parent d’accueil.",

    // FIXME FR Main banner links
    HEADER_CAREERS_LINK_TITLE: "Carrières",
    HEADER_CONTACT_US_LINK_TITLE: "Contactez-nous",
    CONTACT_US_PATH: "contactez-nous",
    CAREERS_PATH: "commencez-votre-carriere-dans-les-services-la-personne",
    HEADER_SERVICES_LINK_TITLE: "Services",

    HOUSING_SUBPAGE_DESCRIPTION: "Avez-vous besoin d'un logement sécuritaire et abordable qui répond à vos besoins? Êtes-vous un propriétaire ou un promoteur? Il existe des programmes et des incitatifs pour veiller à ce qu'un logement convenable soit accessible à tous les gens du Nouveau-Brunswick.",
    DISABILITY_SUBPAGE_DESCRIPTION: "Si vous vivez au Nouveau-Brunswick et vous avez un handicap, il existe des programmes et des services à votre disposition. Ces soutiens sont offerts par le biais de programmes gouvernementaux et d'organisations communautaires pour aider à répondre aux besoins de base et soutenir l’autonomie des personnes.",
    NAVIGATOR_SUBPAGE_DESCRIPTION: "Êtes-vous une personne âgée vivant au Nouveau-Brunswick? Si c'est le cas, il existe des programmes et des services spécialement conçus pour que vous puissiez demeurer aussi autonome et en bonne santé que possible. Les agences gouvernementales et communautaires offrent des services de soutien pour vous aider à bien vieillir.",
    FINANCES_SUBPAGE_DESCRIPTION: "Éprouvez-vous des difficultés à subvenir à des besoins élémentaires, comme la nourriture, l’hébergement, l’habillement, les soins de santé ou d’autres nécessités de la vie? Avez-vous besoin d’aide pour assurer votre réussite dans votre formation et votre emploi? Il existe des ressources gouvernementales et communautaires pour vous aider.",
    ABUSE_AND_NEGLECT_SUBPAGE_DESCRIPTION: "Est-ce que vous ou une personne que vous connaissez êtes victime de mauvais traitement ou de négligence? Obtenez de l’information qui vous permettra de reconnaître et de signaler des comportements nuisibles ou dangereux qui mettent en péril la sécurité d’un enfant, d’un adulte ou d’une personne âgée. Obtenez l’aide dont vous avez besoin.",
    FAMILIES_AND_YOUTH_SUBPAGE_DESCRIPTION: "Vous êtes un parent ou une jeune personne vivant au Nouveau-Brunswick? Il y a des programmes et des services pour aider les jeunes et les familles à avoir une vie saine, dans laquelle ils reçoivent un bon soutien.",
    YOUR_HEALTH_SUBPAGE_DESCRIPTION: "Vous avez des problèmes de santé? Vous avez besoin d’aide pour payer des dépenses de santé, comme des médicaments d’ordonnance, de l’équipement médical ou des fournitures? Vous avez besoin d’un soutien supplémentaire pour votre enfant ou pour vous-même? Des programmes gouvernementaux et communautaires peuvent vous aider.",

    SUBPAGE_LEARN_MORE: "EN SAVOIR PLUS",
    CUSTOM_CATEGORY_CALL_NUMBER: "APPELER SERVICE",

    HELPLINE_TITLE: "Service 211",
    HELPLINE_DESCRIPTION: "Appelez cette ligne d'assistance gratuite (24 heures sur 24, 7 jours sur 7) lorsque vous avez besoin d'aide pour trouver du soutien dans votre communauté.",
    HELPLINE_BUTTON: "EN SAVOIR PLUS",


    ACTSREGS_TITLE: "Stratégies, lois et règlements",
    ACTSREGS_DESCRIPTION: "Trouvez de l’information sur les stratégies, les lois et les règlements provinciaux, y compris des liens vers les lois, les rapports et d’autres documents.",


    HOME_SUPPORTS_MAP_HEADER1: "Vous pouvez maintenant rechercher des agences de soutien à domicile dans votre région à l'aide d'une carte fournie par ",
    HOME_SUPPORTS_MAP_HEADER2: ". Utilisez votre code postal pour trouver les agences les plus près de chez vous. Les agences apparaîtront sur la carte sous forme de points rouges.  Cliquez ensuite sur l'agence individuelle pour voir ses coordonnées.",
    NURSING_HOME_MAP_HEADER1: "Vous pouvez maintenant rechercher les foyers de soins dans votre région à l'aide de la carte fournie par ",
    NURSING_HOME_MAP_HEADER2: ". Utilisez votre code postal pour trouver les foyers proches de chez vous. Les foyers de soins apparaîtront sur la carte sous forme de points rouges. Cliquez sur le foyer pour voir ses coordonnées.",
    SPECIAL_CARE_MAP_HEADER1: "Vous pouvez maintenant rechercher un foyer de soins spéciaux dans votre région à l'aide de la carte fournie par ",
    SPECIAL_CARE_MAP_HEADER2: ". Utilisez votre code postal pour trouver les foyers les plus proches de chez vous. Les foyers de soins spéciaux apparaîtront sur la carte sous forme de points rouges. Cliquez sur le foyer pour voir ses coordonnées.",
    MEALS_WHEELS_MAP_HEADER1: "Vous pouvez maintenant rechercher les Services de repas à domicile dans votre région à l'aide de la carte fournie par ",
    MEALS_WHEELS_MAP_HEADER2: ". Utilisez votre code postal pour trouver les Services de repas à domicile proches de chez vous. Les Services de repas à domicile apparaîtront sur la carte sous forme de points rouges. Cliquez sur le foyer pour voir ses coordonnées.",


    PRIMARY_HEADER_SURVEY_LINK: "https://ca.surveygizmo.com/s3/50078698/Navigator-UX-Feedback?sglocale=fr-ca",
    PRIMARY_HEADER_SURVEY_TEXT: "DITES-NOUS CE QUE VOUS PENSEZ",

    PRIMARY_HEADER_CHAT: "FR: CHAT",
    PRIMARY_HEADER_CHAT_LINK: "",
    PRIMARY_HEADER_ONLINE_TOOL: "FR: ONLINE TOOL",
    PRIMARY_HEADER_ONLINE_TOOL_LINK: "",

    LANDING_SEE_ALL_PROGRAMS: "VOIR TOUS LES PROGRAMMES",
    LANDING_SEE_TOP_PICKS: "CHOIX PRINCIPAUX",
    
    SEARCH_BUTTON_LABEL: "RECHERCHE",
    SEARCH_BAR_PLACEHOLDER: "Je cherche...",

    HOMELESS_DASHBOARD_LINK: "https://app.powerbi.com/view?r=eyJrIjoiMjM4NTlhOWUtOTc1MC00OTZlLTg1NTctNTlkZWZlMmFkNzdhIiwidCI6ImUwOGI3ZWVmLWI1MDEtNGE2Ny05ZWQwLTA3ZTM4YmZjY2VlNyJ9&pageName=ReportSection",
    HOMELESS_DASHBOARD_PAGE_LINK: "/fr/page/tableau-de-bord-de-lutilisation-des-refuges-durgence",
    HOMELESS_DASHBOARD_TITLE: "Tableau de bord de l’utilisation des refuges d’urgence",
    HOMELESS_DASHBOARD_DESC: "", // empty

    PRINT_PAGE_WEBSITE_URL: "SOUTIENSSOCIAUXNB.CA",
    PRINT_PAGE_FOOTER_VISIT: "Visitez le site ",
    PRINT_PAGE_NEED_HELP: "Pour signaler un cas de violence ou de négligence ou si vous devez présenter une demande de services de Dévelopment Social, composez le ",
    PRINT_PAGE_LAST_UPDATED_TEXT: "Pour obtenir les informations les plus récentes, des liens cliquables et des ressources supplémentaires, visitez le site ",

    MODAL_211_TITLE: "Clavardage",
    MODAL_211_TEXT_1: "Le clavardage est offert du lundi au vendredi, de 8 h à 22 h.",
    MODAL_211_TEXT_2: "Pour obtenir de l’aide en dehors de ces heures, composez le 2-1-1.",
    MODAL_211_TEXT_3: "Le service de clavardage 2-1-1 offre:",
    MODAL_211_TEXT_4: "des renseignements au sujet des services gouvernementaux et communautaires,",
    MODAL_211_TEXT_5: "des réponses aux questions de base sur ces services,",
    MODAL_211_TEXT_6: "un aiguillage vers d’autres services.",
    MODAL_211_TEXT_7: "Les agents du service de clavardage 2-1-1 sont des employés du service 211 et non des employés du ministère du Développement social. Les employés du service 211 n’ont accès à aucun de vos renseignements personnels. Cela signifie qu’ils ne connaîtront pas l’état de votre demande si vous en avez présenté dans le cadre d’un programme ou d’un service. ",
    MODAL_211_TEXT_8: "Pour protéger votre vie privée, évitez d’inclure des renseignements personnels ou d’identification dans le clavardage.",
    MODAL_211_TEXT_9: "Nous pourrions conserver vos questions afin d’améliorer nos services de soutien. Nous ne conservons ni n’utilisons vos renseignements, votre historique, ni toute autre interaction.",
    MODAL_211_FOOTER_DIAL: "Appeler le 2-1-1",
    MODAL_211_FOOTER_DECLINE: "Refuser",
    MODAL_211_FOOTER_CHAT_NOW: "Clavarder maintenant",
}

const en = {
    LANGUAGE_BUTTON_TITLE: LANGUAGE_NAMES.FRENCH,
    BETA_NOTICE: "Tell us what you think about this new tool and how we can improve it.",
    BETA_NOTICE_2: "Send us your feedback.",
    LANDING_TITLE: "Find programs and services for New Brunswick seniors.",
    LANDING_TITLE_1: "Find programs & services",
    LANDING_TITLE_2: "To help New Brunswickers",
    SITE_DESCRIPTION_BLURB: "We’re helping New Brunswickers navigate and explore government programs and services.",
    WEBSITE_TITLE: "Social Supports NB | Government of New Brunswick.",
    HEADER_TITLE: "Social Supports NB",
    HEADER_TITLE_P1: "SOCIAL",
    HEADER_TITLE_P2: "SUPPORTS NB",
    HEADER_PHONE_LINE: "Need help? ",
    HEADER_ABOUT_LINK_TITLE: "About",
    LANDING_HEADER_ABOUT_US_LINK_TITLE: "About us",
    HEADER_ABOUT_LINK: "/en/page/about",
    HEADER_HOME_LINK_TITLE: "Home",
    HEADER_MENU_TITLE: "Menu",
    HEADER_PRIVACY_LINK_TITLE: "Privacy",
    HEADER_DISCLAIMER_LINK_TITLE: "Disclaimer",
    HEADER_CLOSE_BUTTON_TITLE: "Close",
    MAIN_CATEGORY_HEADING: "I want to learn about:",
    MAIN_CATEGORY_ITEM_LINK: "Learn More",
    PAGE_NOT_FOUND: "Page not found",
    FOOTER_COPYRIGHT_NOTICE: "All content copyright " + '\u00A9' + " Government of New Brunswick. All rights reserved.",
    NEED_HELP: "Do you need to know what supports exist in your community? Call 211!",
    LANDING_NEED_HELP: "TO REPORT ABUSE OR NEGLECT OR IF YOU NEED TO APPLY FOR SOCIAL DEVELOPMENT SERVICES CALL ",
    LANDING_NEED_HELP_2: "1-833-733-7835.",
    NEED_HELP_2: "If you need to apply for government services, call ",
    NEED_HELP_3: "Need Help?",
    SEARCH: "Search",
    SEARCH_PLACEHOLDER: "Search",
    FEEDBACK: "Feedback",
    HEADER_TELE_CARE: "Tele-Care 811",
    HEADER_TELE_CARE_LINK: "https://www2.gnb.ca/content/gnb/en/departments/health/Tele-Care.html",
    HEADER_LINKS: "More",
    PAGINATION_PREVIOUS: "Previous",
    PAGINATION_NEXT: "Next",
    CATEGORY_FILTER_DESC: "What I need most is",
    CATEGORY_FILTER_SELECT_PROMPT: "choose a topic...",
    CATEGORY_FILTER_VIEW_ALL: "View All",
    CATEGORY_FILTER_VIEW_ALL_2: "Options",
    CATEGORY_FILTER_FOUND: "You've found",
    SEARCH_FILTER_FOUND: "Your search returned",
    CATEGORY_FILTER_FOUND_2: "programs or services:",
    CATEGORY_FILTER_FOUND_2_SINGULAR: "program or service:",
    SEARCH_RESULTS: "Search Results",
    PROGRAM_DATA_TABLE_OF_CONTENTS_TITLE: "On this page",
    PROGRAM_DATA_TABLE_OF_CONTENTS_ABOUT: "About",
    PROGRAM_DATA_TABLE_OF_CONTENTS_QUALIFICATION: "Who qualifies?",
    PROGRAM_DATA_TABLE_OF_CONTENTS_HOW_TO_APPLY: "How do I apply?",
    PROGRAM_DATA_TABLE_OF_CONTENTS_HOW_TO_APPLY_STEPS: "Steps",
    PROGRAM_DATA_TABLE_OF_CONTENTS_WHAT_DO_I_NEED: "What do I need?",
    PROGRAM_DATA_TABLE_OF_CONTENTS_DOCUMENTS: "Documents",
    PROGRAM_DATA_TABLE_OF_CONTENTS_FAQS:"FAQ",
    PROGRAM_DATA_TABLE_OF_CONTENTS_CONTACTS: "Contacts",
    PROGRAM_DATA_HEADING_ABOUT: "About",
    PROGRAM_DATA_HEADING_QUALIFICATION: "Who qualifies?",
    PROGRAM_DATA_HEADING_HOW_TO_APPLY: "How do I apply?",
    PROGRAM_DATA_HEADING_WHAT_DO_I_NEED: "What do I need?",
    PROGRAM_DOCUMENTS_DOWNLOAD_LINK: "Download PDF",
    PROGRAM_DOCUMENTS_SECTION_TITLE: "More Information",
    PROGRAM_CONTACTS_HEADER: "Contact",
    NO_DESCRIPTION: "No description",
    NO_IMAGE_DESCRIPTION: "No image description",
    NO_TITLE: "No title",
    RELATED_PROGRAMS_HEADER: "Can’t find the program or service you are looking for?",
    RELATED_PROGRAMS_DESCRIPTION: "GET HELP 24/7",
    TELECAREDESCRIPTION:"Tele-Care is a free, confidential, health advice and information line. Dial 811 for access to bilingual, registered nurses, 24 hours a day, 7 days a week, 365 days a year.",
    TELECARETITLE:"Tele-Care 811",
    TELECAREBUTTON:"Call Tele-Care",
    TELECARELINK:"/en/page/tele-care-811",
    PAGINATION_FIRST:"First",
    PAGINATION_LAST:"Last",
    PAGINATION_NEXT:"Next",
    PAGINATION_PREV:"Previous",
    LASTUPDATED:"Last updated on:",
    LASTUPDATEDLANGUAGE : "en-CA",
    PRINT_THIS_PAGE: "Print this page",
    SEARCHPLACEHOLDER:"Search",
    LOAD_MORE:"Load More Like This",
    SEARCH_LABEL: "I'm looking for...",
    TOP_BUTTON: "Top",
    CHAT_BUTTON_TEXT: "Get help",
    CHAT_BUTTON_OPEN: "Open chat",
    CHAT_BUTTON_HIDE: "Hide chat",
    DEFAULT_LINK_TEXT: "Click Here",
    WEBSITE: "Website",
    PROGRAM_DATA_HEADING_FAQ : "FAQ",
    PRIVACY_LINK: "https://www2.gnb.ca/content/gnb/en/admin/privacy.html",
    DISCLAIMER_LINK: "https://www2.gnb.ca/content/gnb/en/admin/disclaimer.html" ,
    SURVEY_LINK: "https://ca.surveygizmo.com/s3/50078698/Navigator-UX-Feedback",
    COVID_HEADER_INFORMATION_1:"Are you looking for information about COVID-19 in New Brunswick? ",
    COVID_HEADER_INFORMATION_2:"Click here",
    COVID_INFO_LINK:"https://www2.gnb.ca/content/gnb/en/corporate/promo/covid-19.html",
    CALCULATOR_PAGE_TITLE:"Financial Help Calculator – Home Support Services",
    DEV_NOTICE: "Welcome to the Development site for Social Supports NB. To view the production site ",
    DEV_NOTICE_2: "click here!",
    PROD_LINK: "https://socialsupportsnb.ca/",
    CALCULATOR_RESULTS_HEADER: "Result:",
    CALCULATOR_RESULTS_TEXT1: "We estimate you would pay ",
    CALCULATOR_RESULTS_TEXT2: " a month for in home supports.",
    CALCULATOR_RESULTS_TEXT3: "This is what you’ll pay for supports that will help you stay independent in your own home.  Everyone needs something different when it comes to home supports.  The types of support described ",
    CALCULATOR_RESULTS_TEXT7:"above",
    CALCULATOR_RESULTS_TEXT8:" provide examples of the types of supports you would receive each month.",
    CALCULATOR_RESULTS_TEXT4: "What should I do next?",
    CALCULATOR_RESULTS_TEXT5: "Or",
    CALCULATOR_RESULTS_LIST_ELEMENT1: "If you feel you would benefit from financial assistance, please visit the ",
    CALCULATOR_LONG_TERM_CARE: "Long Term Care",
    CALCULATOR_LONG_TERM_CARE_LINK: "/en/program/long-term-care-program/",
    CALCULATOR_RESULTS_LIST_ELEMENT2: " page to learn more about accessing home supports.  Or call Social Development at 1-833-733-7835.",
    CALCULATOR_RESULTS_LIST_ELEMENT3: "If you feel you don’t need financial assistance, please visit the ",
    CALCULTOR_HOME_SUPPORT: "Home Support",
    CALCULATOR_HOME_SUPPORT_LINK: "/en/program/home-support-services",
    CALCULATOR_RESULTS_LIST_ELEMENT_4: " page to learn more about your options for finding home supports.",
    CALCULATOR_RESULTS_TEXT6: "Please keep in mind that this is an estimate and you can always call us to talk about your situation in more detail.",
    CALCULATOR_SURVEY_TEXT:"Tell us what you think about our new financial help calculator.  Fill out this quick survey. ",
    CALCULATOR_RESULTS_SURVEY_LINK:"https://ca.surveygizmo.com/s3/50083857/Client-Contribution-Survey",
    CALCULATOR_SURVEY_TEXT2:"Click Here",
    CALCULATOR_DISCLAIMER:"DISCLAIMER",
    CALCULATOR_DISCLAIMER_2:"Please note that these calculations are estimates only based on the information provided. There may be some exceptions based on individual situations. The actual eligible subsidy amount must be confirmed by the Department of Social Development through the application process.",
    CALCULATOR_DISCLAIMER_3:"* The only reason we ask about your total income is to find out how much you’ll pay.  Don’t worry!  We don’t hold onto this information or store it in anyway.  Once you leave this page that information is erased automatically.",
    CALCULATOR_OVERVIEW_HEADER:"Overview",
    CALCULATOR_OVERVIEW_1:"Welcome to our Financial Help Calculator.  This calculator will help you determine if you’re likely to receive financial assistance when applying for in home supports through the Long Term Care Program and Disability Support Program.  The calculator uses your net annual income to determine your cost of home supports.",
    CALCULATOR_OVERVIEW_2:"Here are some examples of common services: ",
    CALCULATOR_OVERVIEW_3:"Help with daily activities such as getting dressed, grooming, bathing, meal time assistance and light housekeeping in your home; ",
    CALCULATOR_OVERVIEW_4: "Meals on Wheels: a meal service that delivers prepared frozen or fresh and nutritious meals to your home;",
    CALCULATOR_OVERVIEW_5: "Foot Care: professional foot/nail care services from an approved provider;",
    CALCULATOR_OVERVIEW_6: "Technology: access two different types of technology; Personal Emergency Response System (for example, Lifeline) or Remote Monitoring (for example, Carelink).",
    CALCULATOR_OVERVIEW_7: "Relief special care home placement: a planned period of relief in a special care home to a family or an individual providing care;",
    CALCULATOR_OVERVIEW_8: "Home Support Respite: provides relief care or other supervisory supports in the senior’s home that are required for safety and/or to support a family or individual providing care services to a senior in their home; ",
    CALCULATOR_OVERVIEW_9: "Adult Day Centres: provide meaningful activity in a group setting outside of the home for persons living with dementia and related cognitive impairment;  ",
    CALCULATOR_OVERVIEW_10: "Employment Support Services Program (ESSP): Employment and support services designed to support adults with a disability aged 19 – 64. ",
    CALCULATOR_IS_THIS_FOR_ME_HEADER: "Is this calculator for me?",
    CALCULATOR_IS_THIS_FOR_ME_1:"In order to get the most accurate estimate from this calculator you must be the following:",
    CALCULATOR_IS_THIS_FOR_ME_2:"An adult (19 and up)",
    CALCULATOR_IS_THIS_FOR_ME_3:"Living at home by yourself or with a spouse.  (Please note, this calculator is not for people who have a spouse who lives in a special care home, nursing home or community residence.  If this applies to you, please call Social Development at 1-833-733-7835.)",
    CALCULATOR_IS_THIS_FOR_ME_4:"If you don’t match the description above, we’re working hard to add more options to the calculator soon.  In the meantime, please call Social Development as 1-833-733-7835.",
    CALCULATOR_IS_THIS_FOR_ME_5:"Please note that the contribution amount will change over time.",
    CALCULATOR_INPUT_1_HEADER:"Net Annual Income",
    CALCULATOR_INPUT_1_TEXT_1:"This means the total income from all sources of all family members, including taxable and non-taxable income.",
    CALCULATOR_INPUT_1_TEXT_2:"The most common incomes include: Canadian Pension Plan (CPP), Old Age Security (OAS), Guaranteed Income Supplement (GIS), Employment Insurance payments, Income Assistance, income tax, private pensions, income from employment, interest from investments, etc.",
    CALCULATOR_INPUT_1_TEXT_3:"We do not consider assets like property or savings and investments as part of your net annual income.",
    CALCULATOR_INPUT_1_LABEL:"Applicant",
    CALCULATOR_INPUT_2_LABEL:"Spouse",
    CALCULATOR_DROPDOWN_HEADER:"Select your family type:",
    CALCULATOR_DROPDOWN_DESCRIPTION_1:"Please tell us if you’re single or common-law/ married and how many dependents you have.  Single includes widowed and divorced.  A dependent is anyone who lives with you and is any of the following:",
    CALCULATOR_DROPDOWN_DESCRIPTION_2:"A child who is 19 years or under who lives with you, or",
    CALCULATOR_DROPDOWN_DESCRIPTION_3:"A child who is 25 years or under who goes to school full-time",
    CALCULATOR_DROPDOWN_INPUT_1:"Single",
    CALCULATOR_DROPDOWN_INPUT_2:"Single with one Dependent at Home",
    CALCULATOR_DROPDOWN_INPUT_3:"Single with more than one Dependent at Home",
    CALCULATOR_DROPDOWN_INPUT_4:"Couple",
    CALCULATOR_DROPDOWN_INPUT_5:"Couple with one Dependent at Home",
    CALCULATOR_DROPDOWN_INPUT_6:"Couple with more than one Dependent at Home",
    CALCULATOR_NUMBER_OF_DEPENDENTS:"Number of Dependents",
    CALCULATOR_TABLE_HEADER:"How much support do I need?",
    CALCULATOR_TABLE_DESC_1: "When it comes to home supports, everyone needs something different depending on their situation.  How much support you need depends on:",
    CALCULATOR_TABLE_DESC_2: "Your care needs,",
    CALCULATOR_TABLE_DESC_3: "How much support you already get from your family and friends, and ",
    CALCULATOR_TABLE_DESC_4: "Your personal preferences.",
    CALCULATOR_TABLE_DESC_5: "Generally, there are three types of support that people need for home supports.",
    CALCULATOR_TABLE_DESC_6: "A little support (0-10 hours a week)",
    CALCULATOR_TABLE_DESC_7: "A moderate amount of support (11-20 hours a week)",
    CALCULATOR_TABLE_DESC_8: "A high amount of support (More than 21 hours a week)",
    CALCULATOR_TABLE_DESC_9: "At this stage you may need help with medication management and help or supervision with some personal care activities, such as bathing (e.g., getting in and out of the tub).  You don’t need help every day and you may also benefit from some community support services, and from some assistive devices (e.g., cane, walker).",
    CALCULATOR_TABLE_DESC_10: "At this stage you may need help with most daily living activities like bathing, dressing, moving around your home and preparing food. You may need help every day. You would benefit from community support services, assistive devices, and caregiver supports.",
    CALCULATOR_TABLE_DESC_11: "You need help with most daily living activities like bathing, dressing and cooking as well as transferring out of bed and help with toileting.  You may need help once or twice a day. You would benefit from community support services, assistive devices and caregiver respite.",
    CALCULATOR_BUTTON_1: "Calculate",
    CALCULATOR_BUTTON_2: "Reset",
    CALCULATOR_INPUT_VALIDATION_ERROR_1:"The Family Type have selected indicates that there are no Dependents.  Please select 0 for the Number of Dependents.",
    CALCULATOR_INPUT_VALIDATION_ERROR_2:"The Family Type you have selected indicates that there is only one Dependent in the Household.  Please select 1 for the Number of Dependents.",
    CALCULATOR_INPUT_VALIDATION_ERROR_3:"The Family Type you have selected indicates that there is more than one Dependent in the Household.  Please select a number greater than 1 for the Number of Dependents.",
    CALCULATOR_INPUT_SPOUSE_WHOLE_NUM_ERROR: "Spouse Income Amounts must only contain numbers" ,
    CALCULATOR_INPUT_SPOUSE_NEGATIVE_NUM_ERROR: "Spouse Income Amounts must not be a negative number",
    CALCULATOR_INPUT_APPLICANT_WHOLE_NUM_ERROR: "Applicant Income Amounts must only contain numbers ",
    CALCULATOR_INPUT_APPLICANT_NEGATIVE_NUM_ERROR: "Applicant Income Amounts must not be a negative number",
    CALCULATOR_INPUT_APPLICANT_UNDEFINED_NUM_ERROR: "Please enter the Annual Income Amount for the Applicant.",
    CALCULATOR_NO_APPLICANT_INCOME_ERROR:"Please enter the Annual Income Amount for the Applicant",
    CALCULATOR_INVALID_APPLICANT_INCOME_ERROR:"The number you have input is not valid for Applicant income.",
    CALCULATOR_INVALID_SPOUSE_INCOME_ERROR:"The number you have input is not valid for Spouse income.",
    CALCULATOR_NO_FAMILY_TYPE_SELECTED_ERROR:"Please make a selection from the Family Type dropdown list.",
    CALCULATOR_NO_DEPENDENTS_SELECTED_ERROR:"Please make a selection from the Number of Dependents dropdown list.",
    CALCULATOR_SPOUSE_INPUT_NOT_USED:"Sorry you have input a spouse income when you have indicated that you are single.",
    CALCULATOR_SUMMARY_STATEMENT_BUTTON_LINK: FinancialCalculatorLink + "?language=en_US",
    CALCULATOR_NET_INCOME_PLACEHOLDER: "i.e. 30000",
    CALCULATOR_FAMILY_TYPE_CHOOSE: "Choose...",
    FINANCIAL_HELP_BANNER_1:"Do you think you would benefit from financial help? Try our ",
    FINANCIAL_HELP_BANNER_2:"financial help calculator!",
    REGISTRATION_HEADER_RESIDENTIAL_INFORMATION: "Address Information",
    REGISTRATION_FIELD_FIRST_NAME: " First Name",
    REGISTRATION_FIELD_LAST_NAME: " Last Name",
    REGISTRATION_ERROR_NO_FIRST_NAME: "A first name is required",
    REGISTRATION_ERROR_NO_LAST_NAME: "A last name is required",
    REGISTRATION_ERROR_INVALID_NAME: "This field can only contain letters",
    REGISTRATION_SECTION_SECOND_CONTACT: "Family/ Friend Contact Consent",
    REGISTRATION_FIELD_PROVIDE_CONSENT: "Can we contact the family/ friend you want to invite to the review?   ",
    REGISTRATION_FIELD_SECOND_CONTACT_EXPLAINATION: "You may want to invite someone you know, like a family member or friend, to attend your review. You can provide their contact information below as well.",
    REGISTRATION_FIELD_ERROR_PROVIDE_CONSENT: "You must provide consent for a second contact.",
    REGISTRATION_FIELD_SECOND_FIRST_NAME: " Family/ Friend First Name",
    REGISTRATION_FIELD_SECOND_LAST_NAME: " Family/ Friend Last Name",    
    REGISTRATION_FIELD_SECOND_TELEPHONE: " Family/ Friend Phone Number",
    REGISTRATION_FIELD_TELEPHONE: " Phone Number",
    REGISTRATION_FIELD_APPLICANT_AGE: " I am 65 or older.",
    REGISTRATION_FIELD_AGE_OVER_65_CONSENT_NOT_GIVEN: "To register for this program, you must be 65 or older",
    REGISTRATION_ERROR_NO_TELEPHONE: "Must have a phone number",
    REGISTRATION_ERROR_TELEPHONE_LENGTH: "Telephone number must be 10 digits",
    REGISTRATION_FIELD_LANGUAGE_TYPE: " What language do you prefer?",
    REGISTRATION_LANGUAGE_TYPE_CHOOSE: "Choose...",
    REGISTRATION_LANGUAGE_TYPE_ENGLISH: "English",
    REGISTRATION_LANGUAGE_TYPE_FRENCH: "French",
    REGISTRATION_ERROR_NO_LANGUAGE_SELECTED: "You must select a language type",
    REGISTRATION_ERROR_INVALID_CITY_NAME: "The city name can only include characters, dashes, periods, and apostrophes",
    REGISTRATION_ERROR_NO_CITY_NAME: "A city name is required",
    REGISTRATION_FIELD_CITY: " City, Town or Village",
    REGISTRATION_HEADER_ADDRESS: "Residential Address",
    REGISTRATION_HEADER_DISCLAIMER: "Disclaimer",
    REGISTRATION_DISCLAIMER_TEXT: "Please carefully read the following disclaimer and check this box prior to submitting your information.",
    REGISTRATION_DISCLAIMER_TEXT_2:"I understand that my personal information will be used solely for the purpose of processing my registration with the Seniors Health, Well-being and Home Safety Review.",
    REGISTRATION_DISCLAIMER_TEXT_3:"I therefore provide consent for agents of Bayshore to contact myself, or my alternate contact, by phone, to discuss my eligibility for the Seniors Health, Well-being and Home Safety Review Program.",
    REGISTRATION_DISCLAIMER_TEXT_4:"I understand that my personal information is collected and will be used under the authority of the Right to Information and Protection of Privacy Act, Chapter R-10.6, and the Family Services Act, Chapter F-2.2.",
    REGISTRATION_DISCLAIMER_TEXT_5:"I also understand that the Department of Social Development has taken necessary precautions to protect my personal information from unauthorized use or disclosure in accordance with the privacy legislation referenced above.",
    REGISTRATION_DISCLAIMER_CONSENT_NOT_GIVEN: "In order to continue you must accept the disclaimer",
    REGISTRATION_FIELD_POSTAL_CODE: " Postal Code",
    REGISTRATION_ERROR_NO_POSTAL_CODE: "A postal code is required",
    REGISTRATION_ERROR_POSTAL_CODE_PATTERN: "The postal code must match the pattern: A1A1A1",
    REGISTRATION_SUBMIT_BUTTON: "Submit",
    HEADER_HOME_FIRST_REGISTRATION_HEADER: "Home First Registration",
    REGISTRATION_OVERVIEW: "You can now register for your Home First Review online.  Fill out the form below and someone will get in touch with you soon to schedule your review. The review is a free home visit that helps you learn about community services and programs that can help you in your day-to-day life.  Some of the topics discussed are: ",
    REGISTRATION_OVERVIEW_BULLET1:"how to prevent falls,",
    REGISTRATION_OVERVIEW_BULLET2:"getting involved in your community,",
    REGISTRATION_OVERVIEW_BULLET3:"physical activity and",
    REGISTRATION_OVERVIEW_BULLET4:"Wellness.",
    REGISTRATION_OVERVIEW_HEADER:"Overview",
    REGISTRATION_RESET_BUTTON: "Reset",
    REGISTRATION_ERROR_NO_STREET_ADDRESS: "This information is required.",
    REGISTRATION_FORM_ADDRESS_LINE:"Address Line 1",
    REGISTRATION_FORM_ADDRESS_LINE_2:"Address Line 2",
    REGISTRATION_FORM_MODAL_SUCCESS_TITLE:"Success!",
    REGISTRATION_FORM_MODAL_SUCCESS_MESSAGE:"Your information was submitted, and someone will call you soon to set up your review.",
    REGISTRATION_FORM_MODAL_FAILURE_TITLE:"Uh Oh!",
    REGISTRATION_FORM_MODAL_FAILURE_MESSAGE_1:"Your information was not submitted.  Make sure you’ve filled out the full form and try again.",
    REGISTRATION_FORM_MODAL_FAILURE_MESSAGE_2:"Or you can register on the phone by calling 1-855-550-0552.",
    REGISTRATION_FORM_MODAL_LINK_MESSAGE:"Link to back to Home First",
    HOME_FIRST_PROGRAM_ROUTING: "/program/home-first",

    SCREENING_PAGE_HEADER: "Canada - New Brunswick Housing Benefit Application Form",
    SCREENING_PARA_HEADER: "About",
    SCREENING_PARA_ONE: "The Canada-New Brunswick Housing Benefit (CHB-NB) is a short-term benefit of up to three years. It helps New Brunswickers who work part-time or at lower-paying jobs who are struggling to afford rent.",

    SCREENING_FORM_HEADING: "Who qualifies?",
    SCREENING_FORM_PARAGRAPH_1: "The CHB-NB is not for everyone. It is just one of the programs helping New Brunswickers to achieve greater independence and meet their basic needs. It does not affect any other benefits or supports from Social Development.",
    SCREENING_FORM_PARAGRAPH_2: "If you are not eligible for the CHB-NB, you may qualify for other supports such as:",

    SCREENING_OTHER_SUPPORTS_1_LINK: "/en/program/social-assistance-program",
    SCREENING_OTHER_SUPPORTS_2_LINK: "/en/simple_page/financial-benefits-families-children",
    SCREENING_OTHER_SUPPORTS_3_LINK: "/en/category/help-parents",
    SCREENING_OTHER_SUPPORTS_4_LINK: "/en/program/public-housing-and-rent-supplement-programs",
    SCREENING_OTHER_SUPPORTS_5_LINK: "/en/disability/",
    SCREENING_OTHER_SUPPORTS_6_LINK: "/en/navigator/",

    SCREENING_OTHER_SUPPORTS_1: "Social Assistance",
    SCREENING_OTHER_SUPPORTS_2: "Financial benefits for families with children",
    SCREENING_OTHER_SUPPORTS_3: "Help for parents",
    SCREENING_OTHER_SUPPORTS_4: "Help with rent and housing",
    SCREENING_OTHER_SUPPORTS_5: "Disability support",
    SCREENING_OTHER_SUPPORTS_6: "Supports for seniors",

    SCREENING_FIRST_TABLE_FAMILIES_HEADER: "Families",
    SCREENING_FIRST_TABLE_INDIVIDUALS_HEADER: "Individuals",

    SCREENING_FAMILIES_1: "You have custody of a child(ren) under the age of 19,",
    SCREENING_FAMILIES_2: "OR",
    SCREENING_FAMILIES_3: "a dependent child(ren) with a disability 19 years of age or older",
    SCREENING_FAMILIES_4: "You rent the place where you live.",
    SCREENING_FAMILIES_5: "You do not receive a housing subsidy from Social Development.",
    SCREENING_FAMILIES_6: "You live in New Brunswick.",
    SCREENING_FAMILIES_7: "You are employed or temporarily on maternity/paternity leave.",
    SCREENING_FAMILIES_8: "You have filed your taxes for the previous year.",
    SCREENING_FAMILIES_9: "You are the only person in your household applying for this benefit.",
    SCREENING_FAMILIES_10: "The combined income of all adults over 19 living in the home (before tax) is between $12,500 and $50,000.",

    SCREENING_INDIVIDUALS_1: "You are under the age of 65, and",
    SCREENING_INDIVIDUALS_2: "You live alone.",
    SCREENING_INDIVIDUALS_3: "You rent the place where you live.",
    SCREENING_INDIVIDUALS_4: "You do not receive a housing subsidy from Social Development.",
    SCREENING_INDIVIDUALS_5: "You live in New Brunswick.",
    SCREENING_INDIVIDUALS_6: "You are employed.",
    SCREENING_INDIVIDUALS_7: "You have filed your taxes for the previous year.",
    SCREENING_INDIVIDUALS_8: "You are the only person living in your household and applying for this benefit.",
    SCREENING_INDIVIDUALS_9: "Your employment income (before tax) is between $12,500 and $50,000.",

    SCREENING_BENEFIT_NOT_YOU_HEADER: "This benefit is NOT for you if you answer ‘yes’ to any of the conditions below:",
    SCREENING_BENEFIT_NOT_YOU_1: "You own your own home.",
    SCREENING_BENEFIT_NOT_YOU_2: "You receive another housing benefit (subsidized housing, rent supplement).",
    SCREENING_BENEFIT_NOT_YOU_3: "The combined income of all adults over 19 living in the home (before tax) is $50,001 or more.",
    SCREENING_BENEFIT_NOT_YOU_4: "Someone else in your household is applying for or receiving this benefit.",

    SCREENING_YES_HEADING: "You may qualify for the CHB-NB if you answer ‘yes’ to ALL of the conditions for families OR individuals below:",
    SCREENING_YES_1: "You rent the place where you live.",
    SCREENING_YES_2: "You do not receive a housing subsidy from Social Development.",
    SCREENING_YES_3: "You live in New Brunswick.",
    SCREENING_YES_4: "You have primary custody of minor child(ren) under 19 years of age or dependent(s) with a disability 19 years of age or older.",
    SCREENING_YES_5_1: "You are working and you earn between $12,500 and $50,000 before tax household employment income. If you work 20 hours a week or more at minimum wage, you may be eligible.",
    SCREENING_YES_5_3: "Note:",
    SCREENING_YES_5_4: "This is the combined before tax employment income of all adults over 19 years of age living in the home.",
    SCREENING_YES_7_1: "Your income is reported to Canada Revenue Agency.",
    SCREENING_YES_8: "You are the only person in your household applying for this benefit.",

    SCREENING_NO_HEADING: "This benefit is not for you if you answer ‘yes’ to any of these conditions:",
    SCREENING_NO_1: "You own your own home.",
    SCREENING_NO_2: "You receive another housing benefit (subsidized housing, rent supplement).",
    SCREENING_NO_3: "You do not have children or dependent(s) with a disability 19 years of age or older.",
    SCREENING_NO_4: "You have a before tax household employment income of $50,001 or more.",
    SCREENING_NO_5: "You have someone else in your household applying for or receiving this benefit.",

    SCREENING_WHAT_DO_I_NEED_HEADER: "What do I need?",
    SCREENING_WHAT_DO_I_NEED_SUBHEADER: "You will need the following to complete this application:",

    SCREENING_WDIN_CHECK_1: "Rental Information",
    SCREENING_WDIN_CHECK_2: "Proof of Income",
    SCREENING_WDIN_CHECK_3: "Payment information",

    SCREENING_WDIN_CHECK_1_DESC_1: "You will need to provide ",
    SCREENING_WDIN_CHECK_1_DESC_2: "ONE",
    SCREENING_WDIN_CHECK_1_DESC_3: " of the following to confirm your rent.",

    SCREENING_WDIN_CHECK_1_LI_1: "Utility Bill (address/name must match the information we have on file from your original application).",
    SCREENING_WDIN_CHECK_1_LI_2: "Rent Receipt that includes your name, address, rent amount, date, and landlord’s name.",
    SCREENING_WDIN_CHECK_1_LI_3: "Copy of recent rent increase notice.",
    SCREENING_WDIN_CHECK_1_LI_4: "Copy of lease or tenancy agreement.",
    SCREENING_WDIN_CHECK_1_LI_5: "Signed rental declaration form.",
    SCREENING_WDIN_CHECK_1_LI_5_LINK: "https://drupal.socialsupportsnb.ca/sites/default/files/2023-01/residenceconfirmation-confirmationderesidence.pdf",

    SCREENING_WDIN_CHECK_2_LI_1: "Notice of Assessment from Canada Revenue Agency (for all household members 19 and over if individual income is more than $12,500).",
    SCREENING_WDIN_CHECK_2_LI_2: "Employment income information for all household members 19 and over (if individual income is more than $12,500).",

    SCREENING_WDIN_CHECK_2_LI_1_1: "If you do not have this information, you can log in to your ",
    SCREENING_WDIN_CHECK_2_LI_1_2: "CRA My Account",
    SCREENING_WDIN_CHECK_2_LI_1_2_LINK: "https://www.canada.ca/en/revenue-agency/services/e-services/e-services-individuals/account-individuals.html",
    SCREENING_WDIN_CHECK_2_LI_1_3: " or call 1-800-267-6999.",
    
    SCREENING_WDIN_CHECK_2_LI_2_1: "If you have not filed your taxes, find a ",
    SCREENING_WDIN_CHECK_2_LI_2_2: "free tax clinic",
    SCREENING_WDIN_CHECK_2_LI_2_2_LINK: "https://www.canada.ca/en/revenue-agency/services/tax/individuals/community-volunteer-income-tax-program/need-a-hand-complete-your-tax-return.html",
    SCREENING_WDIN_CHECK_2_LI_2_3: " near you.",
    
    SCREENING_WDIN_CHECK_2_LI_2_AND: "AND",

    SCREENING_WDIN_CHECK_2_LI_2_FK_1: "Pay stubs - 4 most recent/consecutive. (If you worked more than one job, please provide pay stubs for each.)",
    SCREENING_WDIN_CHECK_2_LI_2_FK_2: "OR",
    SCREENING_WDIN_CHECK_2_LI_2_FK_3: "A recent letter from employer (within the last 30 days) that includes:",

    SCREENING_WDIN_CHECK_2_LI_2_LIST_1: "The date you were hired,",
    SCREENING_WDIN_CHECK_2_LI_2_LIST_2: "Your gross annual income or rate of pay (include any overtime pay or bonuses),",
    SCREENING_WDIN_CHECK_2_LI_2_LIST_3: "Hours scheduled to work each week,",
    SCREENING_WDIN_CHECK_2_LI_2_LIST_4: "Employer contact information, and",
    SCREENING_WDIN_CHECK_2_LI_2_LIST_5: "Employer signature.",

    SCREENING_WDIN_MATPAT_SUBHEADER: "Maternity/paternity leave",
    SCREENING_WDIN_MATPAT_DESC: "If you are on maternity/paternity leave, you will also require a letter from your employer confirming your return-to-work date.",

    SCREENING_WDIN_CHECK_3_1: "The CHB-NB will be administered to successful applicants by direct deposit.",
    SCREENING_WDIN_CHECK_3_2_1: "Please complete and sign the Canada-New Brunswick Housing Benefit ",
    SCREENING_WDIN_CHECK_3_2_2: "Direct Deposit Form",
    SCREENING_WDIN_CHECK_3_2_LINK: "https://drupal.socialsupportsnb.ca/sites/default/files/2021-06/CHB%20SD%20Payee%20_%20Direct%20Deposit%20Form_Final-r2.pdf",
    SCREENING_WDIN_CHECK_3_2_3: ".",

    SCREENING_WDIN_NOTE: "Note: We understand your situation (family, employment, financial) may change during the year. If your situation changes, then your benefit might be adjusted the next year. Your benefit is calculated once a year.",

    SCREENING_HDIA_HEADER: "How do I apply?",
    SCREENING_HDIA_DESC: "You can apply for the CHB-NB online or by mail:",

    SCREENING_HDIA_LI_1: "To apply for the CHB-NB, you will need all of the information listed in the “What do I need” section above.",
    SCREENING_HDIA_LI_2_1: "Click the “proceed” button at the bottom of the page to continue to the SSNB Online Portal. This new and secure feature allows you to apply online, safely upload documents, save your progress, and access your personal information.",
    SCREENING_HDIA_LI_2_2_1: "If you prefer, you can ",
    SCREENING_HDIA_LI_2_2_2: "download and print",
    SCREENING_HDIA_LI_2_2_2_LINK: "https://drupal.socialsupportsnb.ca/sites/default/files/2023-01/CHB Application - EN.pdf",
    SCREENING_HDIA_LI_2_2_3: " an application to complete by hand. Send your completed forms and copies of the requested documents by mail or in person to:",
    SCREENING_HDIA_LI_2_3: "Please allow for extra processing time if you mail your application.",
    SCREENING_HDIA_LI_2_4_1: "If you would like to receive a hard copy of the application package by mail, or have any questions about the application process, please contact ",
    SCREENING_HDIA_LI_2_4_2: "infochb-acl@gnb.ca",
    SCREENING_HDIA_LI_2_4_2_LINK: "mailto:infochb-acl@gnb.ca",
    SCREENING_HDIA_LI_2_4_3: " or call 211.",

    SCREENING_HDIA_LI_3: "SSNB Online Portal Instructions",

    SCREENING_HDIA_ADDR_1: "Department of Social Development",
    SCREENING_HDIA_ADDR_2: "Attn: Canada-New Brunswick Housing Benefit",
    SCREENING_HDIA_ADDR_3: "551 King St.",
    SCREENING_HDIA_ADDR_4: "Sartain MacDonald Building",
    SCREENING_HDIA_ADDR_5: "PO Box 6000Fredericton, NB",
    SCREENING_HDIA_ADDR_6: "E3B 5H1",

    SCREENING_HDIA_LI_3_S1: "Step 1 - Select the Canada-New Brunswick Housing Benefit Online Application.",
    SCREENING_HDIA_LI_3_S2: "Step 2 - Click “Register” (or log in if you already have an account). You will be prompted to enter your first name, last name, and email.",
    SCREENING_HDIA_LI_3_S3: "Step 3 - A confirmation email containing a username will be sent to the email you provided in step 2.",
    SCREENING_HDIA_LI_3_S4: "Step 4 - Click the link provided in the confirmation email to setup your password and proceed to the application.",
    SCREENING_HDIA_LI_3_S5: "Step 5 - Complete and submit your application online.",
    SCREENING_HDIA_LI_3_SI: "Once you have successfully submitted your application, you will receive a confirmation email that includes a reference number. Please keep this information for your records.",

    SCREENING_NH_HEADER: "Do you need help completing the online application form?",
    SCREENING_NH_SUBHEADER_1: "User Guide: ",
    SCREENING_NH_SUBHEADER_2: "CHB-NB Online Application Form",
    SCREENING_NH_SUBHEADER_2_LINK: "https://www2.gnb.ca/content/dam/gnb/Departments/sd-ds/pdf/chb-nb-user-guide.pdf",
    SCREENING_NH_DESC: "Complete this form if it is your first time applying for the CHB-NB.",
    SCREENING_NH_EMAIL_BOLD: "Email ",
    SCREENING_NH_EMAIL: "socialsupportsnb@gnb.ca",
    SCREENING_NH_EMAIL_LINK: "mailto:socialsupportsnb@gnb.ca",

    SCREENING_SUBMISSION_HEADING: "SUBMISSION OPTIONS",
    SCREENING_SUBMISSION_OPTION_1_HEAD: "Online:",
    SCREENING_SUBMISSION_OPTION_1: "If you have access to internet and an email account, you can apply for this benefit online.",

    SCREENING_SUBMISSION_OPTION_2_HEAD: "Mail-In Option:",
    SCREENING_SUBMISSION_OPTION_2_HEAD_LINK: "https://drupal.socialsupportsnb.ca/sites/default/files/2021-06/CHB_Application_Form%20-%20June%202021%20Updates%206-r2.pdf",
    SCREENING_SUBMISSION_OPTION_2: "If you do not have access to the Internet or an email account, you can mail the completed application and all required support documentation to:",

    SCREENING_SUBMISSION_OPTION_2_MAIL_1: "Department of Social Development",
    SCREENING_SUBMISSION_OPTION_2_MAIL_2: "Attn: Canada-New Brunswick Housing Benefit",
    SCREENING_SUBMISSION_OPTION_2_MAIL_3: "551 King St.",
    SCREENING_SUBMISSION_OPTION_2_MAIL_4: "Sartain MacDonald Building",
    SCREENING_SUBMISSION_OPTION_2_MAIL_5: "PO Box 6000",
    SCREENING_SUBMISSION_OPTION_2_MAIL_6: "Fredericton, NB",
    SCREENING_SUBMISSION_OPTION_2_MAIL_7: "E3B 5H1",

    SCREENING_SUBMISSION_OPTION_2_B: "Please allow for additional processing time if you mail your application. If you would like a hardcopy of the application package sent, then please email ",
    SCREENING_SUBMISSION_OPTION_2_B_EMAIL: "infochb-acl@gnb.ca",
    SCREENING_SUBMISSION_OPTION_2_B_2: "or call 211 and provide your complete mailing address.",

    SCREENING_CHECKLIST_HEADING: "CHECKLIST",
    SCREENING_CHECKLIST_HEADING_A: "You will need the following to complete this application.",
    SCREENING_CHECKLIST_PARA_1: "Please review and gather copies of all required supporting documentation before you begin. Please complete the application form and submit all of the documentation at the same time. At this time there is no option to save your application. This checklist is here to assist you in making sure you have all the information required to submit your application. Applications cannot be processed until a complete application package is received. Provide copies of the following documents:",
    
    SCREENING_CHECKLIST_DOC_1_NOTE: "You can download a copy of your Notice of Assessment online at CRA My Account from Canada Revenue Agency (CRA) for every adult (over 19 years old) in your household. This can be obtained by either logging into your ",
    SCREENING_CHECKLIST_DOC_1_NOTE_1: "CRA MyAccount",
    SCREENING_CHECKLIST_DOC_1_LINK_1: "https://www.canada.ca/en/revenue-agency/services/e-services/e-services-individuals/account-individuals.html",
    SCREENING_CHECKLIST_DOC_1_NOTE_2: "or calling the CRA Line at 1-800-267-6999.",
    SCREENING_CHECKLIST_DOC_1_NOTE_3_1: "If you have not filed your taxes, then please find a ",
    SCREENING_CHECKLIST_DOC_1_NOTE_3_2: "free tax clinic",
    SCREENING_CHECKLIST_DOC_1_NOTE_3_3: " near you:",
    SCREENING_CHECKLIST_DOC_1_LINK_2: "https://www.canada.ca/en/revenue-agency/services/tax/individuals/community-volunteer-income-tax-program/need-a-hand-complete-your-tax-return.html",
    SCREENING_CHECKLIST_DOC_1_AND: "AND",

    SCREENING_CHECKLIST_QH_1: "Current Proof of Income",
    SCREENING_CHECKLIST_QH_2: "Rental Information (applicant only needs to provide ONE of the following)",
    SCREENING_CHECKLIST_QH_3: "Payment Information (this benefit will be administered to successful applicants by Direct Deposit)",
    SCREENING_CHECKLIST_QH_4: "Application Form",

    SCREENING_DOCUMENTS_CHECK_ONE_PRETXT1: "To qualify for the CHB-NB, your ",
    SCREENING_DOCUMENTS_CHECK_ONE_PRETXT2: "Total Household Income",
    SCREENING_DOCUMENTS_CHECK_ONE_PRETXT3: " needs to be verified as well as your ",
    SCREENING_DOCUMENTS_CHECK_ONE_PRETXT4: "Employment Income",
    SCREENING_DOCUMENTS_CHECK_ONE_PRETXT5: ". To verify this information, you must provide a copy of your most recent Notice of Assessment. This document tells us your Total Household Income.",
    SCREENING_DOCUMENTS_CHECK_ONE_NOTE: "Note:",

    SCREENING_DOCUMENTS_CHECK_ONE_TXT4: "AND",
    SCREENING_DOCUMENTS_CHECK_ONE_TXT6: "One of the following options to tell us your current emplyment income:",
    //SCREENING_DOCUMENTS_CHECK_ONE_TXT7: "These documents tell us your current Employment Income.",
    
    SCREENING_DOCUMENTS_CHECK_ONE_TXT8: "Option 1",
    SCREENING_DOCUMENTS_CHECK_ONE_TXT9A: "Option 2",
    SCREENING_DOCUMENTS_CHECK_ONE_TXT9B: "(New Job – you do not have 4 paystubs yet)",
    SCREENING_DOCUMENTS_CHECK_ONE_TXT10: "Paystubs: Please provide your four most recent paystubs OR a year-to-date paystub. Paystubs must show your name, employers name and adderess, and pay period.",
    SCREENING_DOCUMENTS_CHECK_ONE_TXT_NOTE: "Note: ",
    SCREENING_DOCUMENTS_CHECK_ONE_TXT10_OOPS: "If you worked more than one job, please provide paystubs for each.",
    SCREENING_DOCUMENTS_CHECK_ONE_TXT11: "You have a new job and you do not have 4 paystubs yet or you are currently on Maternity/Paternity Leave.",
    SCREENING_DOCUMENTS_CHECK_ONE_TXT11_NEW: "Letter from Employer:",
    SCREENING_DOCUMENTS_CHECK_ONE_TXT12: "The date you were hired.",
    SCREENING_DOCUMENTS_CHECK_ONE_TXT13: "Your gross annual income including any overtime pay or bonuses.",
    SCREENING_DOCUMENTS_CHECK_ONE_TXT13_1: "Rate of pay.",
    SCREENING_DOCUMENTS_CHECK_ONE_TXT14: "Hours scheduled to work each week.",
    SCREENING_DOCUMENTS_CHECK_ONE_TXT15: "Gross monthly salary.",
    SCREENING_DOCUMENTS_CHECK_ONE_TXT15_B: "Employer's contact information.",
    SCREENING_DOCUMENTS_CHECK_ONE_TXT15_C: "Employer's signature.",
    SCREENING_DOCUMENTS_CHECK_ONE_TXT16: "This letter must be dated within 30 days of your Canada New Brunswick Housing Application.",
    SCREENING_DOCUMENTS_CHECKONE_TXT17: "For Maternity/Paternity Letters, please have the employer provide your return-to-work date.",
    
    SCREENING_DOCUMENTS_CHECK_TWO_A: "Rent Receipt showing address, rent amount, date and landlord name; or",
    SCREENING_DOCUMENTS_CHECK_TWO_B: "Copy of recent Rent Increase Notice; or ",
    SCREENING_DOCUMENTS_CHECK_TWO_C: "Copy of Lease or Tenancy Agreement (if signed within the past 12 months); or",
    SCREENING_DOCUMENTS_CHECK_TWO_D: "Signed Rental Declaration Form",
    SCREENING_DOCUMENTS_CHECK_TWO_D_1: " (included in package)",
    SCREENING_DOCUMENTS_CHECK_TWO_LINK: "https://www2.gnb.ca/content/dam/gnb/departments/sd-ds/pdf/residenceconfirmation-confirmationderesidence.pdf",
    SCREENING_DOCUMENTS_CHECK_THREE: "Please complete the ",
    SCREENING_DOCUMENTS_CHECK_THREE_1: "Canada-New Brunswick Housing Benefit Direct Deposit Form",
    SCREENING_DOCUMENTS_CHECK_THREE_LINK: "https://drupal.socialsupportsnb.ca/sites/default/files/2021-06/CHB%20SD%20Payee%20_%20Direct%20Deposit%20Form_Final-r2.pdf",
    SCREENING_DOCUMENTS_CHECK_FOUR: "Please complete the application form (included in package) and submit it along with copies of notice(s) of assessment & paystubs for all household members over 19 years of age, rental information, and Canadian Housing Benefit Direct Deposit Form. The Canada-New Brunswick Housing Benefit (CHB-NB) does not affect any other benefits or supports from Social Development.",
    
    SCREENING_DOCUMENTS_PLEASE_NOTE: "Please note:",
    SCREENING_DOCUMENTS_PLEASE_NOTE_CONT: "We understand your situation (family, employment, financial) may change during the year. If your situation changes during the year, then your benefit might be adjusted the next year. Your benefit is calculated once a year. This program does not have overpayments.",
    SCREENING_DOCUMENTS_PLEASE_NOTE_CONT_2: "The Canada-New Brunswick Housing Benefit does not affect any other benefits or supports from Social Development.",

    CHB_SCREENING_PROCEED: "Proceed",

    CHB_REGISTRATION_PAGE_TITLE: "Canada - New Brunswick Housing Benefit Form",
    CHB_REGISTRATION_PAGE_TITLE_BREADCRUMB: "Canada-New Brunswick Housing Benefit Application Form",
    CHB_REGISTRATION_PAGE_ABOUT_TITLE: "ABOUT THE CANADA HOUSING BENEFIT",

    CHB_REGISTRATION_ENGLISH: "English",
    CHB_REGISTRATION_FRENCH: "French",

    CHB_REGISTRATION_GENDER_IDENTITY: "Gender Identity",
    CHB_REGISTRATION_GENDER_IDENTITY_EXP: "Information used to determine household composition",
    CHB_REGISTRATION_GENDER_MALE: "Male",
    CHB_REGISTRATION_GENDER_FEMALE: "Female",
    CHB_REGISTRATION_GENDER_NON_BINARY: "Non Binary",
    CHB_REGISTRATION_GENDER_OTHER: "Prefer to self-describe",
    CHB_REGISTRATION_GENDER_NOT_SAY: "Prefer not to say",

    CHB_REGISTRATION_WRITTEN_LANGUAGE: "Preferred Language (Written)",
    CHB_REGISTRATION_SPOKEN_LANGUAGE: "Preferred Language (Spoken)",

    CHB_REGISTRATION_HOWSHOULDWE: "How would you prefer we communicate with you?",
    CHB_REGISTRATION_HOWSHOULDWE_EXP: "Please provide related information below, based on your preferred method of communication",

    CHB_REGISTRATION_FIRST_NAME: "First Name",
    CHB_REGISTRATION_PREFERRED_NAME: "Preferred Name",
    CHB_REGISTRATION_MIDDLE_INITIAL: "Middle Initials",
    CHB_REGISTRATION_LAST_NAME: "Last Name",

    CHB_REGISTRATION_EMAIL: "Email",
    CHB_REGISTRATION_PHONE: "Phone",
    CHB_REGISTRATION_MAIL: "Mail",

    CHB_REGISTRATION_HOME_PHONE: "Telephone Number",
    CHB_REGISTRATION_CELL_PHONE: "Cell Phone",
    CHB_REGISTRATION_DATE_OF_BIRTH: "Date of Birth (YYYY-MM-DD)",

    CHB_REGISTRATION_PROVINCE: "New Brunswick",

    CHB_REGISTRATION_RELATIONSHIP: "Relationship to Applicant",
    CHB_REGISTRATION_SPOUSE: "Spouse/Partner",
    CHB_REGISTRATION_CHILD: "Child",
    CHB_REGISTRATION_PARENT: "Parent",
    CHB_REGISTRATION_RELO: "Other Relative",
    CHB_REGISTRATION_NRELO: "Other Non-Relative",
    CHB_REGISTRATION_ORP: "Other Relative Partner",
    CHB_REGISTRATION_ONRP: "Other Non-Relative Partner",
    CHB_MONTHLY_RENT: "What is your monthly rent?",
    CHB_MONTHLY_UTILITY_BLURB: "What are your average monthly utility payments? Please enter ‘0’ for any that do not apply.",
    CHB_MONTHLY_WATER: "Water",
    CHB_MONTHLY_ELECT: "Electricity",
    CHB_MONTHLY_HEAT: "Heat",

    CHB_TYPE_OF_RENTAL: "Type of Rental",
    CHB_TYPE_OF_RENTAL_PRIV: "Private Landlord",
    CHB_TYPE_OF_RENTAL_NP_H: "Non-Profit Housing",
    CHB_TYPE_OF_RENTAL_COOP: "Co-operative Housing",

    CHB_REGISTRATION_SIN: "Social Insurance Number (required for tax income purposes to produce a T5007)",
    CHB_REGISTRATION_SIN_EXP_1: "The",
    CHB_REGISTRATION_SIN_EXP_2: "T5007",
    CHB_REGISTRATION_SIN_EXP_3: "is a tax slip that will help you prepare your tax return as it includes the amount of provincial assistance issued to you in a tax year. The income shown on the T5007 tax slip is not taxable but is used to calculate entitlement to tax credits like the GST credit and Canada Child Benefit, if applicable.",
    CHB_REGISTRATION_SIN_LINK: "https://www.canada.ca/en/revenue-agency/services/forms-publications/publications/t4115/guide-t5007-return-benefits.html",
 
    CHB_VOLUNTARY_HEADING: "This section is voluntary. The following questions do not determine your eligibility. The answers will help Social Development understand how the program is working and how we should improve it.",
    
    CHB_MARGINALIZED_OPTION_TXT_1: "Do you identify with one or more of the of the following? Please check all applicable boxes. This section is just for the primary applicant.",
    CHB_MARGINALIZED_OPTION_TXT_2: "Social Development is committed to ensuring diversity by supporting initiatives that promote the inclusiveness of marginalized groups. If you wish to identify your status, please complete this section. Note that this information will be used for statistical and program development purposes only.",
    CHB_MARGINALIZED_OPTION_1: "Women fleeing domestic violence",
    CHB_MARGINALIZED_OPTION_2: "Indigenous (includes First Nations, Metis, Inuit, Treaty or Non-Status)",
    CHB_MARGINALIZED_OPTION_3: "Person experiencing homelessness",
    CHB_MARGINALIZED_OPTION_4: "Person living with a disability",
    CHB_MARGINALIZED_OPTION_5: "Person living with a mental illness",
    CHB_MARGINALIZED_OPTION_6: "Person living with a substance dependence",
    CHB_MARGINALIZED_OPTION_7: "Veteran",
    CHB_MARGINALIZED_OPTION_8: "Young Adult",
    CHB_MARGINALIZED_OPTION_9: "Person of visible minority",
    CHB_MARGINALIZED_OPTION_10: "Newcomer to Canada",
    CHB_MARGINALIZED_OPTION_11: "Person of gender or sexual minority (LGBTIQ2S+ community)",
    CHB_MARGINALIZED_OPTION_12: "Person who grew up in poverty",
    CHB_MARGINALIZED_OPTION_13: "Prefer not to say",

    CHB_CURRENT_HOUSING_OPTION_TXT: "Please check the statements that describe your current housing situation (check all that apply).",
    CHB_CURRENT_HOUSING_OPTION_1: "I don’t have enough bedrooms for my family",
    CHB_CURRENT_HOUSING_OPTION_2: "Major repairs are required",
    CHB_CURRENT_HOUSING_OPTION_3: "I want to move but cannot afford to do so",
    CHB_CURRENT_HOUSING_OPTION_4: "I live too far from work, school, child care",
    CHB_CURRENT_HOUSING_OPTION_5: "I don’t want to move but I need help with my rent",
    CHB_CURRENT_HOUSING_OPTION_6: "Prefer not to say",

    CHB_ALREADY_RECEIVING_OPTION_TXT_1: "Are you already receiving supports from Social Development? (If so, please indicate all programs that apply.)",
    CHB_ALREADY_RECEIVING_OPTION_TXT_2: "If you already work with a social worker or case manager, we encourage you to discuss the CHB-NB benefit with them to support your success in the program.",
    CHB_ALREADY_RECEIVING_OPTION_TXT_3: "This will not impact any other benefits you receive from Social Development.",
    CHB_ALREADY_RECEIVING_OPTION_1: "Services for children and youth",
    CHB_ALREADY_RECEIVING_OPTION_2: "Services for people with a disability",
    CHB_ALREADY_RECEIVING_OPTION_3: "Services for people receiving income support",
    CHB_ALREADY_RECEIVING_OPTION_4: "Services for seniors",
    CHB_ALREADY_RECEIVING_OPTION_5: "Services for housing",

    CHB_CURRENT_BARRIERS_OPTION_TXT: "Current barriers to increased or improved employment (check all that apply).",
    CHB_CURRENT_BARRIERS_OPTION_1: "Lack of education/diploma/certification",
    CHB_CURRENT_BARRIERS_OPTION_2: "Lack of transportation options",
    CHB_CURRENT_BARRIERS_OPTION_3: "Health Issues (i.e. physical and mental, addictions, disability)",
    CHB_CURRENT_BARRIERS_OPTION_4: "Childcare or Caregiving responsibilities",
    CHB_CURRENT_BARRIERS_OPTION_5: "Few employment options (i.e. lack of jobs opportunities, criminal record)",
    CHB_CURRENT_BARRIERS_OPTION_6: "I would like to work more, but I don’t know where/how to start",
    CHB_CURRENT_BARRIERS_OPTION_7: "Prefer not to say",
    CHB_CURRENT_BARRIERS_OPTION_8: "Other (please describe):",

    CHB_ADDRESS_BLURB_H: "Home Address",
    CHB_ADDRESS_BLURB_M: "Mailing Address",
    CHB_ADDRESS_CIVIC: "Street #",
    CHB_ADDRESS_STREET_NAME: "Street Name",
    CHB_ADDRESS_APARTMENT: "Apartment / Unit",
    CHB_ADDRESS_CITY: "City",
    CHB_ADDRESS_PROVINCE: "Province",
    CHB_ADDRESS_POSTAL: "Postal Code",

    CHB_INCOME_EMP_PRETXT_1: "What was the total Household employment income for the past",
    CHB_INCOME_EMP_PRETXT_1_2: "12 months",
    CHB_INCOME_EMP_PRETXT_2: "This information is used to calculate housing need.",
    CHB_INCOME_EMP_PRETXT_3: "Regular Employment includes employment income of all household members 19 and older, excluding dependents under 25 who are full-time post-secondary students.",
    CHB_INCOME_EMP_PRETXT_4: "Please note: your social assistance will not be reduced if you are approved to receive the Canada Housing Benefit.",

    CHB_VOLUNTARY_PRETXT_1: "This section is voluntary. The following questions do not determine your eligibility. The answers will help Social Development understand how the program is working and how we should improve it.",

    CHB_SECTION_HEADING_1: "Primary Applicant",
    CHB_SECTION_HEADING_2: "Additional Household Members 19 Years of Age or Older",
    CHB_SECTION_HEADING_3: "Household Members 18 Years of Age or Younger",
    CHB_SECTION_HEADING_3_OOPS: "RENTAL INFORMATION",
    CHB_SECTION_HEADING_4: "EMPLOYMENT INCOME",
    CHB_SECTION_HEADING_5: "ADDITIONAL INFORMATION",
    CHB_SECTION_HEADING_6: "CONSENT",
    CHB_SECTION_HEADING_7: "DECLARATION",

    CHB_INCOME_TYPE_APPLICANT: "Primary Applicant",
    CHB_INCOME_TYPE_SPOUSEPART: "Spouse/Partner",
    CHB_INCOME_TYPE_OTHERHOUSE: "Household Member(s) 19 and over",
    CHB_INCOME_TYPE_IFAPPLIC: "(if applicable)",

    CHB_TABLE_ROW_1: "Regular Employment (before taxes)",
    CHB_TABLE_ROW_2: "Employment Insurance",
    CHB_TABLE_ROW_3: "Self-Employment",
    CHB_TABLE_ROW_4: "Social Assistance",
    CHB_TABLE_ROW_5: "Child Support Received",
    CHB_TABLE_ROW_6: "Alimony/Spousal Support",
    CHB_TABLE_ROW_7: "Old Age Security",
    CHB_TABLE_ROW_8: "Guaranteed Income Supplement",
    CHB_TABLE_ROW_9: "Canada Pension",
    CHB_TABLE_ROW_10: "Workers' Compensation",
    CHB_TABLE_ROW_11: "Disability Pension",
    CHB_TABLE_ROW_12: "Veteran Pension",
    CHB_TABLE_ROW_13: "Other Pension",
    CHB_TABLE_ROW_14: "Rental Income",
    CHB_TABLE_ROW_15: "Interest/Investment Income",

    CHB_EMPLOYMENT_HOURSWEEK: "Hours worked per week",
    CHB_EMPLOYMENT_SOURCES_ADDREMOVE_TEXT: "If you have more Employment Sources, please click ‘Add’.",
    CHB_EMPLOYMENT_INCOME_ADDREMOVE_TEXT: "If you have more Employment Sources, please click ‘Add’.",

    CHB_EMPLOYMENT_RATEPAY: "Rate of Pay per hour",
    CHB_EMPLOYMENT_SOURCE1: "Employment Source 1:",
    CHB_EMPLOYMENT_SOURCE2: "Employment Source 2:",
    CHB_EMPLOYMENT_SOURCE3: "Employment Source 3:",

    CHB_BEDROOMS_TXT1: "Do any household members require a separate bedroom due to a medical/special condition?",
    CHB_BEDROOMS_TXT1B: "If so, how many additional bedrooms are required?",
    CHB_BEDROOMS_TXT2: "A medical report or proof of disability may be requested.",

    CHB_BRANDNEW_TXT: "This is a new program and we want to improve it based on your experiences. Do you agree to have Social Development contact you to learn about your experience and discuss how we may improve upon our programs? Your feedback is important to us.",
    CHB_BRANDNEW_TXT2: "Your choice will have no impact on eligibility decisions.",

    CHB_BEST_POSSIBLE_TXT: "To provide the best possible support to you, do you agree to Social Development sharing information across programs within the department (including, but not limited to housing, child and youth services, disability support, social assistance)?",

    CHB_DECLARATION_TXT1: "Declaration",
    CHB_DECLARATION_TXT2: "Please check box to confirm that the information in this declaration is correct.",
    CHB_DECLARATION_TXT3: "I agree that the above information is accurate and complete.",
    CHB_DECLARATION_TXT4: "I authorize Social Development to verify any information contained within this form.  I also acknowledge that if information is found to be untrue or inaccurate I will not be eligible to receive this benefit.",

    CHB_DECLARATION_TXT5: "I agree to Social Development contacting me using email regarding my CHB-NB Application submission.",
    CHB_DECLARATION_TXT6: "I understand that sending my personal information electronically to Social Development is voluntary and at my own risk as e-mails are not encrypted, and e-mail accounts are not secure (i.e. Hotmail, gmail, etc.). ",

    CHB_DECLARATION_TXT7: "Social Development cannot guarantee secure transmission of personal information once an e-mail leaves the GNB infrastructure, as e-mail accounts such as Hotmail, Gmail, etc., are not secure.",
    CHB_DECLARATION_TXT8: "Social Development is not responsible for any loss of data resulting from the electronic transmission of personal information from your personal e-mail.",
    

    CHB_DEFAULT_FEEDBACK: "This field is required.",

    CHB_FIELD_BLANK_APPLICANT_FIRST_NAME: "Please enter First Name for the Applicant.",
    CHB_FIELD_BLANK_APPLICANT_LAST_NAME: "Please enter Last Name for the Applicant.",
    CHB_FIELD_BLANK_APPLICANT_PREFFERED_LANG: "Please select preferred language (Written) for the Applicant.",
    CHB_FIELD_BLANK_APPLICANT_SPOKEN_LANG: "Please select preferred language (Spoken) for the Applicant.",
    CHB_FIELD_BLANK_HOW_COMMUNICATE: "Please select preferred mode of communication for the Applicant.",
    CHB_FIELD_BLANK_APPLICANT_TELEPHONE: "Please enter Telephone Number for the Applicant.",
    CHB_FIELD_BLANK_APPLICANT_EMAIL: "Please enter Email for the Applicant.",
    CHB_FIELD_BLANK_APPLICANT_DOB: "Please enter Date of Birth for the Applicant.",
    CHB_FIELD_BLANK_APPLICANT_GENDER: "Please select Gender Identity for Applicant.",
    CHB_FIELD_BLANK_SELF_DESCRIBE: "Please enter self-description for Applicant Gender Identity.",
    CHB_FIELD_BLANK_SOCIAL_INSURANCE: "Please enter Social Insurance Number for the Applicant.",
    CHB_FIELD_BLANK_STREET_NAME: "Please enter Street name for the Applicant.",
    CHB_FIELD_BLANK_CITY_NAME: "Please enter City Name for the Applicant.",
    CHB_FIELD_BLANK_PROVINCE_NAME: "Please enter Province Name for the Applicant.",
    CHB_FIELD_BLANK_PROVINCE_NOT_NB: "Must be NB.",
    CHB_FIELD_BLANK_POSTAL_CODE: "Please enter Postal Code for the Applicant.",
    CHB_FIELD_BLANK_MAILING_CUSTOM: "Please enter Street Name, City, Province, Postal Code for Applicant Mailing Address.",
    CHB_FIELD_BLANK_ADDITIONAL_CUSTOM: "Please enter The First Name, Last Name, Gender Identity, DOB, Relationship for Additional Household Member.",
    CHB_FIELD_BLANK_MONTHLY_RENT: "Please enter your monthly rent.",
    CHB_FIELD_BLANK_MONTHLY_UTILITIES: "Please enter your average monthly utility payment amount.",
    CHB_FIELD_BLANK_TYPE_RENTAL: "Please select your type of rental.",
    CHB_FIELD_BLANK_DECLARATION: "Please click the checkbox to confirm information in this form is correct.",
    CHB_FIELD_BLANK_INCOME_CUSTOM: "At least one income source must be entered for the Main Applicant.",

    CHB_FIELD_FILE_UPLOAD: "Upload file",
    CHB_FIELD_FILE_UPLOAD_ERROR_LIMIT: "File must be 7mb or less.",
    CHB_FIELD_FILE_UPLOAD_ERROR_TYPE: "File must be of type PDF, JPG, TIFF, or PNG.",
    CHB_FIELD_FILE_UPLOAD_ERROR_UNKNOWN: "An unknown error has occured. Please try again.",

    CHB_UPLOAD_DIRECT_DEPOSIT_FORM_HEADER: "Upload Direct Deposit Form",
    CHB_UPLOAD_DIRECT_DEPOSIT_FORM_DESC: "Please complete and submit the ",
    CHB_UPLOAD_DIRECT_DEPOSIT_FORM_DESC_1: "Direct Deposit Form",
    CHB_UPLOAD_DIRECT_DEPOSIT_FORM_DESC_1_LINK: "https://drupal.socialsupportsnb.ca/sites/default/files/2021-06/CHB%20SD%20Payee%20_%20Direct%20Deposit%20Form_Final-r2.pdf",
    CHB_UPLOAD_DIRECT_DEPOSIT_FORM_DESC_2: " to receive eligible Canada-New Brunswick Housing Benefit electronic payments.",
    CHB_UPLOAD_DIRECT_DEPOSIT_FORM_NOTE: "A supplier number will be provided that you can use for reference purposes.",

    CHB_HOUSEHOLD_HEADING: "HOUSEHOLD INFORMATION",
    CHB_HEADING_NOTE_1: "Your personal information is collected by the New Brunswick Housing Corporation under the authority of the New Brunswick Housing Act and is protected from unauthorized use or disclosure. ",CHB_HEADING_NOTE_1: "Your personal information is collected by the New Brunswick Housing Corporation under the authority of the New Brunswick Housing Act and is protected from unauthorized use or disclosure. ",
    CHB_HEADING_NOTE_2: "This information is being collected to determine household composition and other household members contributing to total household employment income.",

    CHB_HEADER_UPLOAD: "UPLOAD SUPPORTING INFORMATION",
    CHB_HEADER_UPLOAD_TXT_1: "Please complete the application form and submit it with copies of all required information (i.e., notice(s) of assessment, paystubs, rental information, and direct deposit form).",
    CHB_HEADER_UPLOAD_TXT_2: "Uploaded files must be clear and legible; otherwise you will receive an e-mail advising you to resubmit the document, and the processing of your application will be delayed.",
    CHB_HEADER_UPLOAD_TXT_3: "Please upload any supporting information using the fields below.",
    CHB_HEADER_UPLOAD_TXT_4: "You can upload the following file types: PDF, JPG, TIFF OR PNG. If you choose to use a photo (JPG, TIFF or PNG), please be aware there is a file size limit of 7mb per file.",

    CHB_TIP: "Tip",
    CHB_TIP_FILE_UPLOAD_TXT_1: "There are several methods to resize if a photo is over 7mb. Here are a few options:",
    CHB_TIP_FILE_UPLOAD_TXT_2: "One quick way to reduce a photo file size is to take a photo by using a cell phone and email it to yourself.",
    CHB_TIP_FILE_UPLOAD_TXT_3: "On Windows, open the photo in Paint and from the Paint toolbar click the “Resize” button.",
    CHB_TIP_FILE_UPLOAD_TXT_4: "On a Mac, open the photo in Preview, select Tools and then Adjust Size.",
    CHB_TIP_FILE_UPLOAD_TXT_5: "Search the internet about the specific methods for the device you are using to upload the photos (e.g., iPhone, Android, iPad, PC).",

    CHB_SUBMIT_FORM: "Submit Form",

    CHB_TIP_FILE_MODAL_TXT_1: "Submitting Form...",
    CHB_TIP_FILE_MODAL_TXT_2: "CHB Application Submitted",
    CHB_TIP_FILE_MODAL_TXT_3: "Your Canada-New Brunswick Housing Benefit application has been successfully submitted – your application ID number is ",
    CHB_TIP_FILE_MODAL_TXT_4: "You will be contacted regarding your eligibility once your application has been processed. If you have any questions, then please contact us by email ",
    CHB_TIP_FILE_MODAL_TXT_4_A: "chb-acl@gnb.ca",
    CHB_TIP_FILE_MODAL_TXT_4_B: " or call 211.",
    CHB_TIP_FILE_MODAL_TXT_5: "Return to Homepage",
    CHB_TIP_FILE_MODAL_TXT_6: "For more information return to the Social Development Canada-New Brunswick Housing Benefit Homepage.",
    CHB_TIP_FILE_MODAL_TXT_7: "Please keep the Application ID number for your records and future reference.",

    CHB_UTILITIES_INCLUDED: "Are utilities included in your rent?",
    CHB_MAILING_ADDRESS_DIFFERENT: "Is your mailing address different from your home address?",

    CHB_MORE_HOUSEHOLD_ADDREMOVE19: "If you have more household members over the age of 19, please click 'Add'.",
    CHB_MORE_HOUSEHOLD_ADDREMOVE18: "If you have more household members under the age of 18, please click 'Add'.",

    CHB_OTHER_INCOME_HEADER: "OTHER INCOME",
    CHB_OTHER_INCOME_BLURB: "Identify other household income before taxes for the past 12 months.",

    CHB_RENTAL_INFORMATION_UPLOAD_HEADER: "Upload Your Rental Information",
    CHB_RENTAL_INFROMATION_UPLOAD_DESC_1: "Please upload ",
    CHB_RENTAL_INFORMATION_UPLOAD_DESC_2: "ONE",
    CHB_RENTAL_INFORMATION_UPLOAD_DESC_3: " of the following:",
    CHB_RENTAL_INFORMATION_UPLOAD_OPTION_1: "Rent Receipt showing address, rent amount, date and landlord name.",
    CHB_RENTAL_INFORMATION_UPLOAD_OPTION_2: "Recent Rent Increase Notice.",
    CHB_RENTAL_INFORMATION_UPLOAD_OPTION_3: "Lease or Tenancy Agreement (if signed within the past 12 months).",
    CHB_RENTAL_INFORMATION_UPLOAD_OPTION_4: "Signed",
    CHB_RENTAL_INFORMATION_UPLOAD_OPTION_4_1: "Rental Declaration Form",
    CHB_RENTAL_INFORMATION_UPLOAD_OPTION_4_2: ".",
    CHB_RENTAL_INFORMATION_UPLOAD_OPTION_4_LINK: "https://www2.gnb.ca/content/dam/gnb/departments/sd-ds/pdf/residenceconfirmation-confirmationderesidence.pdf",
    CHB_RENTAL_INFORMATION_UPLOAD_CAUTION: "Please make sure the file you upload is clear and easy to read.  If we need to follow up by email for a file, it will take longer to process your application.",
    CHB_RENTAL_INFORMATION_UPLOAD_TEXT: "Please upload a copy of our lease agreement or [MISSING TEXT].",
    CHB_RENTAL_INFORMATION_UPLOAD_PLEASE_ADD: "If you have more rental information, please click 'Add'.",
    
    CHB_INCOME_EMPLOYMENT_UPLOAD_HEADER: "Upload Proof of Employment Income",
    CHB_INCOME_EMPLOYMENT_UPLOAD_TEXT: "Please upload employment paystubs or ",
    CHB_INCOME_EMPLOYMENT_UPLOAD_TEXT_1: "letter from Employer.",
    CHB_INCOME_EMPLOYMENT_UPLOAD_PLEASE_ADD: "If you have more employment income information, please click 'Add'.",
    
    CHB_INCOME_HOUSEHOLD_UPLOAD_HEADER: "Upload Proof of Total Household Income",
    CHB_INCOME_HOUSEHOLD_UPLOAD_TEXT: "Please upload a copy of each household member’s recent Notice of Assessment from CRA. These documents tell us your Total Household Income.",
    CHB_INCOME_HOUSEHOLD_UPLOAD_PLEASE_ADD: "If you have more rental information, please click 'Add'.",
    CHB_INCOME_HOUSEHOLD_UPLOAD_TIP_1: "You can download a copy of your Notice of Assessment online from CRA My Account from Canada Revenue Agency (CRA) for every adult (over 19 years old) in your household. This can be obtained by either logging into your CRA MyAccount",
    CHB_INCOME_HOUSEHOLD_UPLOAD_TIP_2: "My Account for Individuals - Canada.ca",
    CHB_INCOME_HOUSEHOLD_UPLOAD_TIP_LINK: "https://www.canada.ca/en/revenue-agency/services/e-services/e-services-individuals/account-individuals.html",
    CHB_INCOME_HOUSEHOLD_UPLOAD_TIP_3: "or calling the CRA Line at 1-800-267-6999.",

    CHB_DIRECT_DEPOSIT_UPLOAD_HEADER: "Upload Proof of Total Household Income",
    CHB_DIRECT_DEPOSIT_UPLOAD_TEXT: "Please upload a copy of your most recent Notice of Assessment from CRA. This document tells us your Total HouseholdIncome.",

    CHB_FINAL_REVIEW_HEADER: "Final Review",
    CHB_FINAL_REVIEW_DESC_1: "Before submitting your application, please review your application:",
    CHB_FINAL_REVIEW_OPTION_1: "Please review all of the information is complete and accurate.",
    CHB_FINAL_REVIEW_OPTION_2: "All of the required files have been uploaded and are legible.",
    CHB_FINAL_REVIEW_DESC_2: "Applications cannot be processed until all of the required information and documents have been received. If any required information or files are missing you will receive an e-mail from ",
    CHB_FINAL_REVIEW_DESC_EMAIL: "CHB-ACL@gnb.ca",
    CHB_FINAL_REVIEW_DESC_3: "advising you to resubmit the required document(s). The processing of your application will be",
    CHB_FINAL_REVIEW_DESC_4: "delayed",
    CHB_FINAL_REVIEW_DESC_4_1: ".",
    CHB_FINAL_REVIEW_NOTE: "Please check your junk folder to make sure you see the email and can respond in a timely manner.",


    CHB_ADD: "Add",
    CHB_REMOVE: "Remove",
    CHB_YES: "Yes",
    CHB_NO: "No",

    CHB_NO_FILE_SELECTED: "No file selected.",

    TMP: "tmp",

    LANDING_LONG_TERM_CARE_PROGRAM_TITLE: "Long Term Care Program",
    LANDING_LONG_TERM_CARE_PROGRAM_DESC: "If you’re 65 or older, the Long Term Care Program can help you access services to meet your specific care needs and goals.",
    LANDING_DISABILITY_SUPPORT_PROGRAM_TITLE: "Disability Support Program",
    LANDING_DISABILITY_SUPPORT_PROGRAM_DESC: "The Disability Support Program provides individuals with disabilities with personalized, flexible supports to increase your independence and connection to the community.",
    LANDING_DISABILITY_SUPPORT_PROGRAM_LINK: "program/disability-support-program",
    LANDING_HOME_FIRST_TITLE: "Home First Review",
    LANDING_HOME_FIRST_DESC: "This free home visit lets you learn about community services and programs that can help you in your day-to-day life.",
    LANDING_HOME_SUPPORTS_TITLE: "Home Supports",
    LANDING_HOME_SUPPORTS_DESC: "Home supports may be able to help you with daily activities like cooking, bathing and cleaning to help you live as independently as possible in your home.",
    LANDING_HOME_SUPPORTS_LINK: "program/home-support-services",
    LANDING_CHB_TITLE: "Canada Housing Benefit",
    LANDING_CHB_DESC: "This benefit helps New Brunswickers who live alone and work part-time or at lower-paying jobs who are struggling to afford rent.",
    LANDING_CHB_LINK: "program/canada-housing-benefit-chb",
    LANDING_PUBLIC_HOUSING_TITLE: "Public Housing",
    LANDING_PUBLIC_HOUSING_DESC: "If you need affordable, safe and adequate housing, there are programs that can help, including Public Housing and Rent Supplements.",
    LANDING_PUBLIC_HOUSING_LINK: "program/public-housing-and-rent-supplement-programs",
    LANDING_REPAIR_TITLE: "Repair Program",
    LANDING_REPAIR_DESC: "A number of funding programs can help you repair or modify your home to make it safer, healthier or more accessible, or to add a secondary/garden suite for a person with a disability.",
    LANDING_REPAIR_LINK: "program/homeowner-repair-and-landlord-programs",

    LANDING_FINANCES_DESC: "Help with Finances",
    LANDING_FINANCES_LINK: "finances",
    LANDING_SOCIAL_ASSISTANCE_TITLE: "Social Assistance",
    LANDING_SOCIAL_ASSISTANCE_DESC: "Social Assistance provides financial support to people who cannot afford basic living costs for food, clothing, shelter, and other necessities.",
    LANDING_SOCIAL_ASSISTANCE_LINK: "program/social-assistance-program",
    LANDING_CAREER_DEVELOPMENT_TITLE: "Career Development",
    LANDING_CAREER_DEVELOPMENT_DESC: "This program connects individuals receiving Social Assistance to programs and services to help them realize their potential and to transition to employment.",
    LANDING_CAREER_DEVELOPMENT_LINK: "program/career-development-opportunities-cdo-program",
    LANDING_HEADER_CAREERS_TITLE: "Careers",
    LANDING_HEADER_CAREERS_LINK: "start-your-human-services-career",
    LANDING_HEADER_REPORT_ABUSE_AND_NEGLECT_LINK: "how-report-abuse-assault-or-neglect",
    LANDING_HEADER_REPORT_ABUSE_AND_NEGLECT_TITLE: "Report abuse & neglect",
    LANDING_HEALTH_CARD_TITLE: "Health Card",
    LANDING_HEALTH_CARD_DESC: "A health card may be issued to low-income individuals who are not eligible for Social Assistance but have high medical costs and no private health plan.",
    LANDING_HEALTH_CARD_LINK: "program/social-assistance-program",
    LANDING_ABUSE_AND_NEGLECT_DESC: "Help with Abuse and Neglect",
    LANDING_ABUSE_AND_NEGLECT_LINK: "abuse-and-neglect",
    LANDING_REPORT_TITLE: "How to Report Abuse or Neglect",
    LANDING_REPORT_DESC: "What to do if you suspect a child, person with a disability or senior is at risk or is currently being abused, neglected or assaulted. The Department of Social Development may be able to help.",
    LANDING_REPORT_LINK: "program/how-report-abuse-assault-or-neglect",
    LANDING_FINANCIAL_ABUSE_TITLE: "Financial Abuse",
    LANDING_FINANCIAL_ABUSE_DESC: "Seniors and some adults are vulnerable to frauds, scams and exploitations. There are steps you can take to identify and prevent financial abuse.",
    LANDING_FINANCIAL_ABUSE_LINK: "simple_page/senior-financial-abuse",
    LANDING_PARTNER_VIOLENCE_TITLE: "Intimate Partner Violence",
    LANDING_PARTNER_VIOLENCE_DESC: "Help is available for people who are being abused or controlled by a partner. Find out how to recognize intimate partner violence and what to do if you or someone you know is in this situation.",
    LANDING_PARTNER_VIOLENCE_LINK: "simple_page/intimate-partner-violence",

    LANDING_FAMILIES_AND_YOUTH_DESC: "Help for Families and Youth",
    LANDING_FAMILIES_AND_YOUTH_LINK: "families-and-youth",
    LANDING_FOSTERING_TITLE: "Fostering a Child",
    LANDING_FOSTERING_DESC: "Sometimes children and youth cannot live with their own parents. This could be for a few weeks or many years. When this happens, we need loving and caring homes for children and youth. By becoming a foster family, you could make a lifelong impact on someone’s life.",
    LANDING_CHILDREN_WITH_DISABILITIES_TITLE: "Family Supports for Children with Disabilities Program",
    LANDING_CHILDREN_WITH_DISABILITIES_DESC: "The Family Supports for Children with Disabilities (FSCD) Program provides financial support to parents and guardians to help pay for the extraordinary costs of caring for a child with a disability.",
    LANDING_CHILDREN_WITH_DISABILITIES_LINK: "program/family-supports-children-disabilities-program",
    LANDING_HOW_TO_REPORT_TITLE: "How to report abuse, assault or neglect",
    LANDING_HOW_TO_REPORT_DESC: "Do you have concerns that a child, an adult with a disability and/or a senior is being abused or neglected and feel you need to tell someone?",
    LANDING_HOW_TO_REPORT_LINK: "program/how-report-abuse-assault-or-neglect",
    LANDING_YOUR_HEALTH_DESC: "Help with Your Health",
    LANDING_YOUR_HEALTH_LINK: "your-health",
    LANDING_PRESCRIPTION_DRUG_TITLE: "Prescription Drug Plans",
    LANDING_PRESCRIPTION_DRUG_DESC: "If you are a New Brunswick resident with a valid Medicare card and don’t have coverage through another drug plan, you may be eligible.",
    LANDING_PRESCRIPTION_DRUG_LINK: "program/prescription-drug-plans",
    LANDING_RED_CROSS_TITLE: "Red Cross Equipment Loans Programs",
    LANDING_RED_CROSS_DESC: "Do you need basic equipment like a walker or crutches to be safer at home? Are you a senior who needs specialized rehab equipment to be more independent at home?",
    LANDING_RED_CROSS_LINK: "program/red-cross-equipment-loan-programs",
    LANDING_CLEAR_VISION_TITLE: "Healthy Smiles, Clear Vision Program",
    LANDING_CLEAR_VISION_DESC: "The Healthy Smiles, Clear Vision plan provides basic dental and vision benefits for children aged 18 and under in low-income families.",
    LANDING_CLEAR_VISION_LINK: "program/healthy-smiles-clear-vision-program",
    FAMILIES_AND_YOUTH_COMMUNITY_SUPPORTS_DESC: "Get information on how to become involved in your community and find support, programs and activities to enrich your life.",

    ABOUT_HEADER: "About",
    ABOUT_DESCRIPTION: "The Department of Social Development launched Social Supports NB to help New Brunswickers easily find information about government and community services and programs. It’s your go-to place for details about financial and housing supports, and to get resources about healthy living, legal rights and protections, and other useful information.",
    ABOUT_BUTTON_TEXT: "Learn more",
    ABOUT_VIDEO_LINK: "https://www.youtube.com/embed/Ow964COmo34",
    LEARN_MORE_PATH: "about",

    FOSTERING_SIDELINK: "https://www.youtube.com/embed/thKC9cb2ENg",
    FOSTERING_SIDELINK_TITLE: "Testimonial - Learn about Tracy's experience being a foster parent",
    
    // Main banner links
    HEADER_CAREERS_LINK_TITLE: "Careers",
    HEADER_CONTACT_US_LINK_TITLE: "Contact Us",
    HEADER_SERVICES_LINK_TITLE: "Services",
    CAREERS_PATH: "start-your-human-services-career",
    CONTACT_US_PATH: "contact-us",

    HOUSING_SUBPAGE_DESCRIPTION: "Do you need safe, affordable housing that meets your needs? Are you a landlord or a developer? There are programs and incentives to help ensure that adequate housing is accessible for all New Brunswickers.",
    DISABILITY_SUBPAGE_DESCRIPTION: "If you are living with a disability in New Brunswick, there are programs and services available to you. These supports are offered through government programs and community organizations to remove barriers.",
    NAVIGATOR_SUBPAGE_DESCRIPTION: "Are you a senior living in New Brunswick? If so, there are programs and services geared specifically for you to stay as independent and healthy as possible. Government and community organizations offer these supports to help you age well.",
    FINANCES_SUBPAGE_DESCRIPTION: "Are you struggling to pay for basic needs, like food, housing, clothing, health care and other necessities? Do you need help succeeding in your training and employment? There are government and community supports to help you.",
    ABUSE_AND_NEGLECT_SUBPAGE_DESCRIPTION: "Are you or someone you know experiencing abuse or neglect? Get information on how to identify and report harmful or dangerous behaviours that are impacting a child's, adult's or senior's safety and security. Get the help you need.",
    FAMILIES_AND_YOUTH_SUBPAGE_DESCRIPTION: "Do you have children or are you a youth living in New Brunswick? There are programs and services to help young people and families live well-supported, healthy lives.",
    YOUR_HEALTH_SUBPAGE_DESCRIPTION: "Are you struggling with your health? Do you need help paying for health-related expenses, such as prescription drugs or medical equipment or supplies? Do you or your child need extra support?  There are government and community programs to help.", 

    SUBPAGE_LEARN_MORE: "LEARN MORE",
    CUSTOM_CATEGORY_CALL_NUMBER: "CALL",

    HELPLINE_TITLE: "211",
    HELPLINE_DESCRIPTION: "Call this free helpline when you need help finding supports in your community.",
    HELPLINE_BUTTON: "LEARN MORE",

    ACTSREGS_TITLE: "Strategies, Regulations and Laws",
    ACTSREGS_DESCRIPTION: "Find information on provincial strategies, laws and regulations, including links to relevant legislation, reports, and more.",

    DISABILITY_SCREENING_HEADER: "Disability Support Program",
    DISABILITY_SCREENING_BODY: "Did you know you can apply online to the Disability Support Program?",
    DISABILITY_SCREENING_BUTTON_TEXT: "Apply Now",
    DISABILITY_SCREENING_BUTTON_LINK: "https://healthcloudtrialmaster-15a4d-17aa7e6197e.force.com/applications/s/?language=en_US&origin=disability-support-program",

    HOME_SUPPORTS_MAP_HEADER1: "You can now search for home support agencies in your area using the map provided by ",
    HOME_SUPPORTS_MAP_HEADER2: ".  Use your postal code to find agencies closest to you. Agencies will appear on the map as red dots.  Then click on the individual agency to see their contact information.",
    NURSING_HOME_MAP_HEADER1: "You can now search for nursing homes in your area using the map provided by ",
    NURSING_HOME_MAP_HEADER2: ". Use your postal code to find the nursing homes closest to you. Nursing homes will appear on the map as red dots. Click on individual nursing homes to see their contact information.",
    SPECIAL_CARE_MAP_HEADER1: "You can now search for a Special Care Home in your area using the map provided by ",
    SPECIAL_CARE_MAP_HEADER2: ". Use your postal code to find homes closest to you. The Special Care Homes will appear on the map as red dots. Click on individual homes to see their contact information.",
    MEALS_WHEELS_MAP_HEADER1: "You can now search for Meals on Wheels in your area using the map provided by ",
    MEALS_WHEELS_MAP_HEADER2: ". Use your postal code to find the Meals on Wheels closest to you. Locations will appear on the map as red dots. Click on individual Meals on Wheels to see their contact information.",

    PRIMARY_HEADER_SURVEY_LINK: "https://ca.surveygizmo.com/s3/50078698/Navigator-UX-Feedback",
    PRIMARY_HEADER_SURVEY_TEXT: "TELL US WHAT YOU THINK",

    PRIMARY_HEADER_CHAT: "CHAT",
    PRIMARY_HEADER_CHAT_LINK: "",
    PRIMARY_HEADER_ONLINE_TOOL: "ONLINE TOOL",
    PRIMARY_HEADER_ONLINE_TOOL_LINK: "",

    LANDING_SEE_ALL_PROGRAMS: "SEE ALL PROGRAMS",
    LANDING_SEE_TOP_PICKS: "SEE THE TOP PICKS",
    
    SEARCH_BUTTON_LABEL: "SEARCH",
    SEARCH_BAR_PLACEHOLDER: "I'm looking for...",

    HOMELESS_DASHBOARD_LINK: "https://app.powerbi.com/view?r=eyJrIjoiNDZkYzQ2YzgtZTcwZC00NWUyLTk2YzQtNjZkZGQ0NmYwYTc1IiwidCI6ImUwOGI3ZWVmLWI1MDEtNGE2Ny05ZWQwLTA3ZTM4YmZjY2VlNyJ9&pageName=ReportSection",
    HOMELESS_DASHBOARD_PAGE_LINK: "/en/page/emergency-shelter-usage-dashboard",
    HOMELESS_DASHBOARD_TITLE: "Emergency Shelter Usage Dashboard",
    HOMELESS_DASHBOARD_DESC: "", // empty

    PRINT_PAGE_WEBSITE_URL: "SOCIALSUPPORTSNB.CA",
    PRINT_PAGE_FOOTER_VISIT: "Visit ",
    PRINT_PAGE_NEED_HELP: "To report abuse or neglect or if you need to apply for Social Development Services call ",
    PRINT_PAGE_LAST_UPDATED_TEXT: "For the latest information, clickable links, and more resources, visit ",

    MODAL_211_TITLE: "Online Chat",
    MODAL_211_TEXT_1: "Online Chat is available Monday - Friday, 8 a.m. - 10 p.m.",
    MODAL_211_TEXT_2: "If you need support outside of these hours, please dial 2-1-1.",
    MODAL_211_TEXT_3: "The 2-1-1 online chat can help you:",
    MODAL_211_TEXT_4: "Find information about government and community services,",
    MODAL_211_TEXT_5: "Answer basic questions about these services, and",
    MODAL_211_TEXT_6: "Refer you to other services.",
    MODAL_211_TEXT_7: "The 2-1-1 online chat is answered by staff at 211, not the Department of Social Development. 211 staff members do not have access to any of your personal information. This means they won't know the status of your application if you've applied for a program or service. ",
    MODAL_211_TEXT_8: "To protect your privacy, please avoid including any personal or identifying information in the chat.",
    MODAL_211_TEXT_9: "We may store your questions to improve how we help others. We do not store or use your information, history, or other interactions.",
    MODAL_211_FOOTER_DIAL: "Dial 2-1-1",
    MODAL_211_FOOTER_DECLINE: "Decline",
    MODAL_211_FOOTER_CHAT_NOW: "Chat now",
}

const blacklistFR = [
    'à',
    'a',
    'au',
    'aux',
    'en',
    'des',
    'de',
    'du',
    'le',
    'la',
    'les',
    'un',
    'une',
    'et',
    'avec',
    'pas',
    'ne',
    'ce',
    'se',
    'si',
    'que',
    'je',
    'tu',
    'mon',
    'me',
    'ma',
    'mes',
]

const blacklistEN = [
    'a',
    'an',
    'are',
    'and',
    'all',
    'at',
    'but',
    'be',
    'by',
    'had',
    'for',
    'this',
    'the',
    'that',
    'to',
    'with',
    'what',
    'of',
    'from',
    'or',
    'is',
    'it',
    'if',
    'in',
    'not',
    'no',
    'you',
    'i',
    'in',
    'this',
    'these',
    'my',
]


const exp =  {
    en: en,
    fr: fr,
    LANGUAGE_NAMES: LANGUAGE_NAMES,
    blacklistFR: blacklistFR,
    blacklistEN: blacklistEN,
}

export default exp
