
export const UnderConstruction = process.env.REACT_APP_UNDERCONSTRUCTION;
export const BaseUrl = process.env.REACT_APP_DRUPALAPI ;
export const Analytics = process.env.REACT_APP_ANALYTICS ;
export const Timeout = process.env.REACT_APP_TIMEOUT ;
export const FrenchDomain = process.env.REACT_APP_DOMAIN_FR;
export const SiteEnvironment = process.env.REACT_APP_SITE_ENVIRONMENT;
export const IsProduction = SiteEnvironment === "PROD"
export const User = process.env.REACT_APP_USER;
export const Password = process.env.REACT_APP_PASS;
export const Version = process.env.REACT_APP_VERSION;
export const GoogleMapsAPIKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;
export const FinancialCalculatorLink = process.env.REACT_APP_FINANCIAL_CALC_LINK;
export const CHBLinkEN = process.env.REACT_APP_CHB_LINK_EN;
export const CHBLinkFR = process.env.REACT_APP_CHB_LINK_FR;
export const Chat211LinkEN = process.env.REACT_APP_CHAT_211_LINK_EN;
export const Chat211LinkFR = process.env.REACT_APP_CHAT_211_LINK_FR;