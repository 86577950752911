import Jsona from 'jsona';
import { checkHttps } from '../api/drupalApi';
import { LanguageCodes } from './language';
import { BaseUrl } from './settings';

const dataFormatter = new Jsona();

/**
 * Given the path to an endpoint and target language code, return the a URL object
 * with the French path added, if necessary.
 * @param {String} path
 * @param {import('./language').LANG_EN | import('./language').LANG_FR} languageCode
 *  - The language code as defined in language.js
 * @returns {URL}
 */
export function makeUrlObject(path, language) {
  const frenchAPIPath = `${LanguageCodes.Fr}/`;
  let finalPath = path;
  if (finalPath.indexOf('/') === 0) {
    finalPath = finalPath.slice(1);
  }
  if (language === LanguageCodes.Fr && path.indexOf(frenchAPIPath) !== 0) {
    finalPath = frenchAPIPath + path;
  }
  return new URL(finalPath, BaseUrl);
}

/**
 * Given a URL object, convert it to json and then optionally deserialize it using Jsona.
 * Deserialization is an expensive process, so we only do it after aggregating pages.
 * Some parts of the site have not been made compatible yet, either, so an option is provided
 * to turn it off.
 * @param {URL} url
 * @param {boolean} jsona - Whether or not to apply the jsona formatter to the result
 * @returns {Promise<Object[]>}
 */
export async function fetchAndFormat(url, jsona = true) {
  const resp = await fetch(url);
  const json = await resp.json();
  // Check for pages to collect, and fold them into this data payload recursively
  if (json.links?.next?.href) {
    const nextPage = await fetchAndFormat(checkHttps(json.links.next.href), false);
    json.data?.push(...nextPage.data);
    json.included?.push(...nextPage.included);
  }
  return jsona ? dataFormatter.deserialize(json) : json;
}

export function getPathAliasStub(string) {
  return string.split('/').pop();
}

export function isExternalUrl(urlString) {
  if (urlString.match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/)) {
    return true;
  }
  return false;
}
