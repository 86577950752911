import React, { useEffect } from 'react';
import { Suspense, useContext } from 'react';
import { Switch, Route, Redirect, useParams, useRouteMatch, useLocation } from 'react-router-dom';

import { LANG_EN } from '../../util/language.js';
import { CHBLinkEN, CHBLinkFR } from '../../util/settings';

import {
    LANDING_PATH, PROGRAM_PATH, SEARCH_PATH, CATEGORY_PATH, COMPLEX_PATH, SIMPLE_PATH,
    CALCULATOR_PATH, HOME_FIRST_PATH, SCREENING_PATH, CHB_REGISTRATION_PATH, PAGE_PATH,
    LANDING_PAGE_SENIORS_ID, LANDING_PAGE_DISABILITY_ID, LANDING_PAGE_HOUSING_ID,
    LANDING_PAGE_FINANCES_ID, LANDING_PAGE_ABUSE_NEGLECT_ID, LANDING_PAGE_FAMILIES_YOUTH_ID,
    LANDING_PAGE_HEALTH_ID
} from '../../globals/constants';
import { ErrorContext, LanguageContext } from '../../globals/Context.js';
import { Helmet } from 'react-helmet-async';
import LoadingSpinner from '../LoadingSpinner.js';
import { LanguageCodes } from '../../util/language';
import PageRedirect from './PageRedirect.js';

const HomePage = React.lazy(() => import ('../Homepage.js'))
const LandingPage = React.lazy(() =>  import ('../LandingPage'))
const NoPage = React.lazy((() => import ('../noPage')))
const SearchPage = React.lazy((() => import ('../SearchPage')))
const CategoryPage = React.lazy((() => import ('../categoryPage')))
const ProgramPage = React.lazy((() => import ('../programPage')))
const ComplexProgramPage = React.lazy((() => import ('../complexProgramPage')))
const SimpleProgramPage = React.lazy((() => import ("../simpleProgramPage")))
const RegistrationPage = React.lazy((() => import ("../registrationPage")))
const Calculator = React.lazy((() => import ('../calculator')))

const SubRouter = (props) => {
    const { language } = useParams()
    const { path } = useRouteMatch()
    const { pathname } = useLocation();
    const { languageCode, languageStrings, setCurrentLanguage } = useContext(LanguageContext);
    const { isError } = useContext(ErrorContext);

    useEffect(() => {
        setCurrentLanguage(language)
    }, [language])

    if(language !== languageCode){
      return <LoadingSpinner />
    }

    // Pathname check prevents infinite redirect loops
    if(isError && !pathname?.includes("error")) {
        return <Redirect push to={`/${language}/error`}/>
    }

    return (
        <Suspense fallback={<div><div id="loader"></div></div>}>
            <Helmet>
                <title>{languageStrings.WEBSITE_TITLE}</title>
            </Helmet>
            <Switch>
                {/* Homepage */}
                <Route path={`${path}`}                                     component={HomePage} exact />
                {/* Landing Page) */}
                <Route path={`${path}${LANDING_PATH}/:title`}               component={LandingPage} exact />
                {/* Programs, searches, categories, etc */}
                <Route path={`${path}${CALCULATOR_PATH}`}                   component={Calculator} exact />
                <Route path={`${path}${SEARCH_PATH}/`}                      component={SearchPage}/>
                <Route path={`${path}${CATEGORY_PATH}/:categoryTitle`}      component={CategoryPage} />
                <Route path={`${path}${PROGRAM_PATH}/:title`}               component={ProgramPage} />
                <Route path={`${path}${COMPLEX_PATH}/:title`}               component={ComplexProgramPage} exact />
                <Route path={`${path}${SIMPLE_PATH}/:title`}                component={SimpleProgramPage} />
                <Route path={`${path}${HOME_FIRST_PATH}`}                   component={RegistrationPage} />
                <Route path={`${path}${PAGE_PATH}/:title`}                  component={SimpleProgramPage}/>
                {/* Canada Housing Benefit */}
                <Route path={`${path}${SCREENING_PATH}`} component={() => {
                    const newPath = language === LANG_EN ?
                        `/${language}/simple_page/canada-new-brunswick-housing-benefit-application-form` :
                        `/${language}/simple_page/formulaire-lallocation-canada-nouveau-brunswick-pour-le-logement`
                    return <Redirect to={newPath}/>
                }}/>
                <Route path={`${path}${CHB_REGISTRATION_PATH}`} component={() => {
                    if (language === LANG_EN) {
                        window.location.href = CHBLinkEN;
                    } else {
                        window.location.href = CHBLinkFR;
                    }
                    return null;
                }}/>
                {/* Landing Pages Vanity URL Redirects */}
                <Route
                    path={`/${LanguageCodes.En}/navigator`}
                    render={() => (
                        <PageRedirect id={LANDING_PAGE_SENIORS_ID} language={LanguageCodes.En} contentType="landing_page" />
                    )}
                />
                <Route
                    path={`/${LanguageCodes.Fr}/navigator`}
                    render={() => (
                        <PageRedirect id={LANDING_PAGE_SENIORS_ID} language={LanguageCodes.Fr} contentType="landing_page" />
                    )}
                />
                <Route
                    path={`/${LanguageCodes.En}/disability`}
                    render={ () => (
                        <PageRedirect id={LANDING_PAGE_DISABILITY_ID} language={LanguageCodes.En} contentType="landing_page" />
                    )}
                />
                <Route
                    path={`/${LanguageCodes.Fr}/disability`}
                    render={ () => (
                        <PageRedirect id={LANDING_PAGE_DISABILITY_ID} language={LanguageCodes.Fr} contentType="landing_page" />
                    )}
                />
                <Route
                    path={`/${LanguageCodes.En}/housing`}
                    render={ () => (
                        <PageRedirect id={LANDING_PAGE_HOUSING_ID} language={LanguageCodes.En} contentType="landing_page" />
                    )}
                />
                <Route
                    path={`/${LanguageCodes.Fr}/housing`}
                    render={ () => (
                        <PageRedirect id={LANDING_PAGE_HOUSING_ID} language={LanguageCodes.Fr} contentType="landing_page" />
                    )}
                />
                <Route
                    path={`/${LanguageCodes.En}/finances`}
                    render={ () => (
                        <PageRedirect id={LANDING_PAGE_FINANCES_ID} language={LanguageCodes.En} contentType="landing_page" />
                    )}
                />
                <Route
                    path={`/${LanguageCodes.Fr}/finances`}
                    render={ () => (
                        <PageRedirect id={LANDING_PAGE_FINANCES_ID} language={LanguageCodes.Fr} contentType="landing_page" />
                    )}
                />
                <Route
                    path={`/${LanguageCodes.En}/abuse-and-neglect`}
                    render={ () => (
                        <PageRedirect id={LANDING_PAGE_ABUSE_NEGLECT_ID} language={LanguageCodes.En} contentType="landing_page" />
                    )}
                />
                <Route
                    path={`/${LanguageCodes.Fr}/abuse-and-neglect`}
                    render={ () => (
                        <PageRedirect id={LANDING_PAGE_ABUSE_NEGLECT_ID} language={LanguageCodes.Fr} contentType="landing_page" />
                    )}
                />
                <Route
                    path={`/${LanguageCodes.En}/families-and-youth`}
                    render={ () => (
                        <PageRedirect id={LANDING_PAGE_FAMILIES_YOUTH_ID} language={LanguageCodes.En} contentType="landing_page" />
                    )}
                />
                <Route
                    path={`/${LanguageCodes.Fr}/families-and-youth`}
                    render={ () => (
                        <PageRedirect id={LANDING_PAGE_FAMILIES_YOUTH_ID} language={LanguageCodes.Fr} contentType="landing_page" />
                    )}
                />
                <Route
                    path={`/${LanguageCodes.En}/your-health`}
                    render={ () => (
                        <PageRedirect id={LANDING_PAGE_HEALTH_ID} language={LanguageCodes.En} contentType="landing_page" />
                    )}
                />
                <Route
                    path={`/${LanguageCodes.Fr}/your-health`}
                    render={ () => (
                        <PageRedirect id={LANDING_PAGE_HEALTH_ID} language={LanguageCodes.Fr} contentType="landing_page" />
                    )}
                />

                {/* Error */}
                <Route path={`${path}error`}                                component={NoPage} exact/>
            </Switch>
        </Suspense>
    )
};

export default SubRouter