
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import * as settings from '../../util/settings';
import SubRouter from './subRouter';
import GAListener from './GAListener';
import {
    ABUSE_NEGLECT_VANITY_URL_EN, ABUSE_NEGLECT_VANITY_URL_FR, CALCULATOR_VANITY_URL_EN, CALCULATOR_VANITY_URL_FR,
    DISABILITY_VANITY_URL_EN, DISABILITY_VANITY_URL_FR, FAMILIES_YOUTH_VANITY_URL_EN, FAMILIES_YOUTH_VANITY_URL_FR,
    FINANCES_VANITY_URL_EN, FINANCES_VANITY_URL_FR, HEALTH_VANITY_URL_EN, HEALTH_VANITY_URL_FR,
    SENIORS_VANITY_URL_EN, SENIORS_VANITY_URL_FR,
    SORT_ORDER
} from '../../globals/constants';
import { getVanityURLs } from '../../api/v2/vanity';
import { useState, useEffect, useContext } from 'react';
import { LanguageContext } from '../../globals/Context';
import { LanguageCodes, RouterFormattedLanguageList } from '../../util/language';

const RootRouter = (props) => {
    const { languageCode } = useContext(LanguageContext);
    const [isLoading, setIsLoading] = useState(true);
    const [vanityUrls, setVanityUrls] = useState([]);

    useEffect(() => {
        async function fetchVanityUrls() {
            const vanityUrlsPayload = await getVanityURLs();
            const preparedVanities = vanityUrlsPayload.map(i => {
                return {
                    vanityUrl: i.field_vanity_url,
                    redirectUrl: i.field_page?.path?.alias,
                    language: i.field_page?.path?.langcode
                }
            })
            .sort((a) => a.language === languageCode ? SORT_ORDER.LESS_THAN : SORT_ORDER.GREATHER_THAN)

            setVanityUrls(preparedVanities);
            setIsLoading(false);
        }
        fetchVanityUrls();
    }, [languageCode]);

    if(isLoading){
        return<div><div id="loader"></div></div>
    }

    return (
        <HelmetProvider>
            <Router>
                <GAListener/>
                <Switch>
                    <Route exact path='/'  render={ () => <Redirect to={`/${languageCode}/`}/> }/>
                    <Route path={`/:language(${RouterFormattedLanguageList})/`} component={SubRouter}/>

                    <Route path={SENIORS_VANITY_URL_EN} render={ () => <Redirect to={`/${LanguageCodes.En}/navigator`}/> }/>
                    <Route path={SENIORS_VANITY_URL_FR} render={ () => <Redirect to={`/${LanguageCodes.Fr}/navigator`}/> }/>
                    <Route path={DISABILITY_VANITY_URL_EN} render={ () => <Redirect to={`/${LanguageCodes.En}/disability`}/> }/>
                    <Route path={DISABILITY_VANITY_URL_FR} render={ () => <Redirect to={`/${LanguageCodes.Fr}/disability`}/> }/>
                    <Route path={FINANCES_VANITY_URL_EN} render={ () => <Redirect to={`/${LanguageCodes.En}/finances`}/> }/>
                    <Route path={FINANCES_VANITY_URL_FR} render={ () => <Redirect to={`/${LanguageCodes.Fr}/finances`}/> }/>
                    <Route path={ABUSE_NEGLECT_VANITY_URL_EN} render={ () => <Redirect to={`/${LanguageCodes.En}/abuse-and-neglect`}/> }/>
                    <Route path={ABUSE_NEGLECT_VANITY_URL_FR} render={ () => <Redirect to={`/${LanguageCodes.Fr}/abuse-and-neglect`}/> }/>
                    <Route path={FAMILIES_YOUTH_VANITY_URL_EN} render={ () => <Redirect to={`/${LanguageCodes.En}/families-and-youth`}/> }/>
                    <Route path={FAMILIES_YOUTH_VANITY_URL_FR} render={ () => <Redirect to={`/${LanguageCodes.Fr}/families-and-youth`}/> }/>
                    <Route path={HEALTH_VANITY_URL_EN} render={ () => <Redirect to={`/${LanguageCodes.En}/your-health`}/> }/>
                    <Route path={HEALTH_VANITY_URL_FR} render={ () => <Redirect to={`/${LanguageCodes.Fr}/your-health`}/> }/>
                    <Route path={CALCULATOR_VANITY_URL_EN} render={ () => <Redirect to={`/${LanguageCodes.En}/calculator`}/> }/>
                    <Route path={CALCULATOR_VANITY_URL_FR} render={ () => <Redirect to={`/${LanguageCodes.Fr}/calculator`}/> }/>

                    {vanityUrls.map((vanityUrl) => (
                        <Route path={vanityUrl.vanityUrl} render={() => <Redirect to={vanityUrl.redirectUrl}/>}/>
                    ))}

                    {/* Error redirect */}
                    {/* TODO: Error page is bilingual, can probably refactor this logic in the future */}
                    <Route path={`/:unknownPath`}  render={ () => {
                            if (window.location.hostname === settings.FrenchDomain) {
                                return <Redirect to={`/${LanguageCodes.Fr}/error`} />
                            } else {
                                return <Redirect to={`/${LanguageCodes.En}/error`} />
                            }
                    }} />
                </Switch>
            </Router>
        </HelmetProvider>
    )
}

export default RootRouter