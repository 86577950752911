import React from 'react';
import { getLanguageCodeFromHostname, getLanguageStrings, getTargetLanguage } from '../util/language';

const languageCode = getLanguageCodeFromHostname();
const otherLanguageCode = getTargetLanguage(languageCode);
const languageStrings = getLanguageStrings(languageCode);
const updateHookStub = () => console.error('Invoking uninstantiated setter in a Context.'); // eslint-disable-line

/**
 * LanguageContext is a context that provides the language code to downstream components.
 * @typedef InvokedLanguageContext
 * @property {{ [key: string]: string }} languageStrings
 *  - The key/value dictionary of strings defined in language.js
 * @property { import('../util/language').LANG_EN | import('../util/language').LANG_FR }languageCode
 *  - The language code of the current language as defined in language.js
 * @property { import('../util/language').LANG_EN | import('../util/language').LANG_FR }languageCode
 *  - The language code of the opposing language.
 * @property { Function } setCurrentLanguage
 *  - Function to update the current language given a new languageCode.
 */

/** @type {import('react').Context<InvokedLanguageContext>} */
export const LanguageContext = React.createContext({
  languageStrings,
  languageCode,
  otherLanguageCode,
  setCurrentLanguage: updateHookStub,
});

/**
 * ErrorContext is how you signal an error state to the application.
 * Setting this to true will display the error page.
 * @typedef InvokedErrorContext
 * @property { boolean } isError
 * @property { Function } setIsError
 */

/** @type {import('react').Context<InvokedErrorContext>} */
export const ErrorContext = React.createContext({
  isError: false,
  setIsError: updateHookStub,
});

/**
 * LoadingContext is how you signal a loading state to the application.
 * Setting this to true will display the loading spinner.
 * Any true call MUST be met by a matching false call or you will never escape
 * the spinner.
 * @typedef InvokedLoadingContext
 * @property { boolean } isLoading - Whether the site is loading or not
 * @property { Function } setIsLoading - Toggle the loading state.
 */

/** @type {import('react').Context<InvokedLoadingContext>} */
export const LoadingContext = React.createContext({
  isLoading: false,
  setIsLoading: updateHookStub,
});

/**
 * PageScopeContext
 * @typedef InvokedPageScopeContext
 * @property { boolean } pageScope - The current page scope.
 * @property { Function } setPageScope - Set the current page scope.
 */
/** @type {import('react').Context<InvokedLoadingContext>} */
export const PageScopeContext = React.createContext({
  pageScope: '',
  setPageScope: updateHookStub,
});

/**
 * DisclaimerContext to show the 211 Chat disclaimer.
 * Setting this to true will display the 211 Chat disclaimer.
 * @typedef InvokedDisclaimerContext
 * @property { boolean } disclaimerAccepted - Whether the 211 chat disclaimer is accepted or not
 * @property { Function } setDisclaimerAccepted - Toggle the acceptance state.
 */

/** @type {import('react').Context<InvokedDisclaimerContext>} */
export const DisclaimerContext = React.createContext({
  disclaimerAccepted: false,
  setDisclaimerAccepted: updateHookStub,
});
