
import React from 'react';
import ReactGA from 'react-ga4';
import { withRouter } from 'react-router-dom';

// Subscribes to the history object in the router 
// and publishes changes to Google Analytics

class GAListener extends React.Component {

    componentDidMount() {
        this.sendPageView(this.props.history.location);
        this.props.history.listen(this.sendPageView);
    }

    sendPageView(location) {
        ReactGA.set({ page: location.pathname });
        ReactGA.send({ hitType: "pageview", page: location.pathname });
    }

    render() {
        return this.props.children || <></>;
    }
}

export default withRouter(GAListener)