import React, { useContext } from 'react';
import { LanguageContext } from '../../globals/Context';
import logo211 from '../../images/211_logo.png';

const DisclaimerModal = ({ handleClose, handleAccept }) => {
  const { languageStrings } = useContext(LanguageContext);

  return (
    <div>
      <div className="modal show d-block fade-bg chat211-modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header d-flex align-items-center">
              <img src={logo211} alt="211 Logo" />
              <h5 className="modal-title pl-3">{languageStrings.MODAL_211_TITLE}</h5>
              <button type="button" className="close modal-close" aria-label="Close" onClick={handleClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body text-left pt-3 pb-1">
              <p>
                <b>{languageStrings.MODAL_211_TEXT_1}</b>
                <br />
                {languageStrings.MODAL_211_TEXT_2}
                <br />
                <br />
                {languageStrings.MODAL_211_TEXT_3}
                <ul className="pb-2">
                  <li>{languageStrings.MODAL_211_TEXT_4}</li>
                  <li>{languageStrings.MODAL_211_TEXT_5}</li>
                  <li>{languageStrings.MODAL_211_TEXT_6}</li>
                </ul>
                {languageStrings.MODAL_211_TEXT_7}
                <b>{languageStrings.MODAL_211_TEXT_8}</b>
                <br />
                <br />
                {languageStrings.MODAL_211_TEXT_9}
              </p>
            </div>
            <div className="modal-footer d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <i className="mdi mdi-phone" />
                <span className="ml-2 text-nowrap"><b>{languageStrings.MODAL_211_FOOTER_DIAL}</b></span>
              </div>
              <div className="button-group d-flex justify-content-end flex-wrap">
                <button type="button" className="btn decline-button m-1" onClick={handleClose}>
                  {languageStrings.MODAL_211_FOOTER_DECLINE}
                </button>
                <button type="button" className="btn chatnow-button m-1" onClick={handleAccept}>
                  {languageStrings.MODAL_211_FOOTER_CHAT_NOW}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisclaimerModal;
