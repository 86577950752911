import React, { useContext, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { LanguageContext, DisclaimerContext } from '../../globals/Context';
import DisclaimerModal from './DisclaimerModal';
import { LANG_EN } from '../../util/language';
import { Chat211LinkEN, Chat211LinkFR } from '../../util/settings';

const ChatBubble211 = () => {
  const { languageStrings, languageCode } = useContext(LanguageContext);
  const { disclaimerAccepted, setDisclaimerAccepted } = useContext(DisclaimerContext);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [pageWidth, setPageWidth] = useState(window?.innerWidth);

  const handleAccept = () => {
    setDisclaimerAccepted(true);
    setShowDisclaimer(false);
    setShowChat(true);
    ReactGA.event({
      category: '211 Chat',
      action: '211 Chat Opened',
      label: window.location.pathname,
    });
  };

  // Spawn a listener to make sure if page width changes we can adjust overflow settings
  useEffect(() => {
    function recalculatePageWidth() {
      setPageWidth(window?.innerWidth);
    }
    window.addEventListener('resize', recalculatePageWidth, false);
    return () => document.removeEventListener('resize', recalculatePageWidth);
  }, []);

  useEffect(() => {
    // Always lock scrolling for an open disclaimer
    // Only lock scrolling for chat window for small browsers
    if (showDisclaimer || (showChat && pageWidth <= 768)) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => { document.body.style.overflow = ''; };
  }, [showDisclaimer, showChat, pageWidth]);

  return (
    <>
      {!disclaimerAccepted ? (
        /* 211 Chat Icon */
        <button type="button" className="btn btn-chat btn-get-help d-print-none" aria-label="211 Chat" onClick={() => setShowDisclaimer(true)}>
          {languageStrings.CHAT_BUTTON_TEXT}
          <i className="mdi mdi-chat pl-2" />
        </button>
      ) : (
        /* Open/Hide chat button */
        <button type="button" className="btn btn-chat btn-open-hide-chat d-print-none" aria-label="211 Chat" onClick={() => setShowChat(!showChat)}>
          {!showChat ? (
            <>
              {languageStrings.CHAT_BUTTON_OPEN}
              <i className="mdi mdi-chat pl-2" />
            </>
          ) : (
            <>
              {languageStrings.CHAT_BUTTON_HIDE}
              <i className="mdi mdi-minus-box-outline pl-2" />
            </>
          )}
        </button>
      )}
      {/* Disclaimer modal */}
      { showDisclaimer && (
        <DisclaimerModal
          handleClose={() => setShowDisclaimer(false)}
          handleAccept={handleAccept}
        />
      )}
      {/* 211 Chat */}
      {disclaimerAccepted && (
        <div className={`card embed-responsive chat-modal ${showChat ? 'visible' : 'invisible'}`}>
          <iframe
            className="embed-responsive-item"
            title="211 chat"
            src={languageCode === LANG_EN ? Chat211LinkEN : Chat211LinkFR}
          />
        </div>
      )}
    </>
  );
};

export default ChatBubble211;
