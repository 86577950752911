import { LanguageCodes } from '../../util/language';
import { fetchAndFormat, makeUrlObject } from '../../util/util';

/**
 * Fetches all vanity urls for the site regardless of language.
 * English comes first, then French.
 * @returns {Promise<Object[]>}
 */
export async function getVanityURLs() {
	const params = new URLSearchParams({
		"include": "field_page",
		"fields[node--program]": "path",
		"fields[node--simple_page]": "path",
		"fields[node--complex_page]": "path",
	});

	const urlEN = makeUrlObject('jsonapi/node/vanity_url', LanguageCodes.En);
	urlEN.search = params.toString();
	
	const urlFR = makeUrlObject('jsonapi/node/vanity_url', LanguageCodes.Fr);
	urlFR.search = params.toString();
	
	const allVanities = [...await fetchAndFormat(urlEN), ...await fetchAndFormat(urlFR)];
	// If the joined program page is not published the relationship will still exist
	// but no data will be returned, so we check if the path is null or not
	const filteredVanities = allVanities.filter(i => i.field_page?.path?.alias);

	return filteredVanities;
}

export async function getVanityUrlByProgramId(programId, language) {
	const params = new URLSearchParams({
		'filter[field_page.id]': programId,
	});

	const url = makeUrlObject('jsonapi/node/vanity_url', language);
	url.search = params;

	const vanityResp = await fetchAndFormat(url);

	return vanityResp;
}